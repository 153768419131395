import { stateCode } from '@backend/constants';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toCurrency } from '../../../helpers/converters';

const notCurrency = [
  'num_z4s_in_cluster',
  'number_of_returns_in_cluster',
  'cluster_average_for_returns_with_schedule_c',
  'hh_inc_score',
  'hh_inc_high_low_indicator',
  'hh_inc_range',
  'adult_hh_inc_score',
  'adult_hh_inc_high_low_indicator',
  'adult_hh_inc_range',
  'w2_score',
  'w2_high_low_indicator',
  'w2_range',
  'adult_w2_score',
  'adult_w2_high_low_indicator',
  'adult_w2_range',
];

export const Powerlytics = () => {
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [stateAbbr, setStateAbbr] = useState('');
  const [zip4, setZip4] = useState('');
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  let states: string[] = [];
  Object.values(stateCode).forEach((value) => {
    states.push(value);
  });

  const getData = async () => {
    try {
      setLoading(true);
      setZip4('');
      setResults([]);

      const result = await axios.get(
        `/api/v1/powerlytics/income/PG%20Address/${line1}/${line2 ? line2 : 'null'}/${city}/${stateAbbr}`,
      );

      setZip4(
        result.data.source.zip4.slice(0, 5) +
          '-' +
          result.data.source.zip4.slice(5, 9),
      );

      const res: any[] = [];
      const details =
        result.data.data.query_results.response_records.group[0].group[0]
          .group[0].detail;
      // important values at the top
      res.push({
        item: 'Adult Average Household Income',
        value: toCurrency(details.adult_avg_household_income * 1000),
      });
      res.push({
        item: 'Adult Minimum Household Income',
        value: toCurrency(details.adult_minimum_household_income * 1000),
      });
      res.push({
        item: 'Adult Maximum Household Income',
        value: toCurrency(details.adult_maximum_household_income * 1000),
      });

      for (const key in details) {
        if (
          key === 'adult_avg_household_income' ||
          key === 'adult_minimum_household_income' ||
          key === 'adult_maximum_household_income'
        ) {
          continue;
        }
        let val = details[key];

        if (!notCurrency.includes(key)) val = toCurrency(val * 1000);

        res.push({
          item: key,
          value: val,
        });
      }

      setResults(res);
    } catch (err: any) {
      setZip4('Error');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="content">
      <Typography variant="h5">Powerlytics Income Lookup</Typography>
      <Box
        sx={{
          background: loading ? '#fff' : 'inherit',
          pointerEvents: loading ? 'none' : 'inherit',
        }}
      >
        <Paper className="content" variant="outlined">
          <TextField
            variant="outlined"
            label="Line 1"
            value={line1}
            type="string"
            onChange={(e) => setLine1(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Line 2 (optional)"
            value={line2}
            type="string"
            onChange={(e) => setLine2(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="City"
            value={city}
            type="string"
            onChange={(e) => setCity(e.target.value)}
          />
          <FormControl>
            <InputLabel>State</InputLabel>
            <Select
              label="State"
              value={stateAbbr}
              onChange={(e) => setStateAbbr(e.target.value)}
            >
              {states.map((stat) => (
                <MenuItem key={stat} value={stat}>
                  {stat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography>Zip+4: {zip4}</Typography>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Button variant="contained" onClick={getData} disabled={loading}>
              Lookup
            </Button>
          </Box>
          {results.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Item</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Value</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((item) => (
                    <TableRow key={item.item}>
                      <TableCell>{item.item}</TableCell>
                      <TableCell>{item.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Box>
    </Box>
  );
};
