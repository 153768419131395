import { isFinOpsOnly } from '../helpers/auth';
import React from 'react';
import { Navigate } from 'react-router-dom';

export const Home = () =>
  isFinOpsOnly() ? (
    <Navigate to={'funded-projects'} />
  ) : (
    <Navigate to={'/loans'} />
  );
