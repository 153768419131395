import React from 'react';
import { UserGroup, getGroup } from '../../helpers/auth';
import { ErrorPage } from '../../pages/ErrorPage';

export const GroupGuard = ({ children, groups }) => {
  const userGroup = getGroup();
  if (userGroup === UserGroup.ADMIN || userGroup === UserGroup.SSC_USER)
    return children;
  if (!groups.includes(userGroup)) {
    // user is not authenticated
    return (
      <ErrorPage
        status={401}
        title="Not Authorized"
        message="You are not authorized to view this page"
      />
    );
  }
  return children;
};
