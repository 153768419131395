import SSCError from '@backend/types/SSCError';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { JSONTree } from 'react-json-tree';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { ErrorPage } from './ErrorPage';

export const fetchRailsLoader = async ({ params }) => {
  try {
    const response = await axios(
      `/api/v1/loans/confirm/${params.fundingInfoId}`,
    );
    response.data.fundingInfoId = params.fundingInfoId;
    response.data.appId = params.appId;
    return response.data;
  } catch (err: any) {
    return {
      error: {
        title: 'Error Fetching Rails',
        message:
          err.response.data.message ??
          'An error occurred while trying to fetch rails for this funding info. Please try again.',
        status: err.response.status,
      },
    };
  }
};

export const Rails = () => {
  const [error, setError] = useState({} as SSCError);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [loading, setLoading] = useState(true);
  const [mdOpen, setMdOpen] = useState(false);
  const [sentDialogOpen, setSentDialogOpen] = useState(false);
  const [openJsonDialog, setOpenJsonDialog] = useState(false);
  const [jsonContent, setJsonContent] = useState({});
  const [dialogList, setDialogList] = useState([] as any[]);
  const params = useParams();
  const railData = useLoaderData() as any;
  const navigate = useNavigate();

  useEffect(() => {
    if (railData) {
      setLoading(false);
    }
  });

  const sendRails = async () => {
    try {
      const railsResult = await axios.put(
        `/api/v1/loans/fund/${railData.fundingInfoId}/${params.appId}`,
      );
      setDialogTitle('Rails Sent');
      setDialogList(
        railsResult.data.Rails.map((rail) => ({
          id: rail.Id,
          description: rail.ACHFields.Description,
        })),
      );
      setSentDialogOpen(true);
    } catch (error: any) {
      setDialogTitle('Failed to send rails');
      setDialogText(
        error.response.data.message ??
          'Failed to send the rails. Please try again',
      );
      setMdOpen(true);
    }
  };

  const handleClose = () => {
    setMdOpen(false);
  };
  const handleSentClose = () => {
    setSentDialogOpen(false);
    navigate(`/loans/application/${params.appId}`);
  };

  // While the loader is loading the data
  if (loading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );

  if (!params.fundingInfoId || !params.appId) {
    setError({
      title: 'Funding info Id and application ID must be provided',
      message: 'Wrong route ID provided',
      status: 403,
    });
  }

  // If loader fails, railData will contain an error object
  if (railData.error) {
    return (
      <ErrorPage
        title={railData.error.title}
        message={railData.error.message}
        status={railData.error.status}
      />
    );
  }
  if (railData.rails.length === 0) {
    return (
      <ErrorPage
        title="No Rails Found"
        message="No rails found for this funding info or all rails have been already processed."
        status={404}
      />
    );
  }

  if (error.title) {
    return (
      <ErrorPage
        title={error.title}
        message={error.message}
        status={error.status}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography sx={{ marginBottom: '1rem' }} variant="h5">
        <p>
          The following rails will be sent to CRB for loan Id:{' '}
          {railData.rails[0].Loan_Id__c}
        </p>
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple-table">
          <TableHead>
            <TableRow>
              <TableCell>Account name</TableCell>
              <TableCell>Account number</TableCell>
              <TableCell>Routing number</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {railData.rails.map((rail) => {
              return (
                <TableRow
                  key={rail.Id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{rail.Account_name__c}</TableCell>
                  <TableCell>{rail.Account_number__c}</TableCell>
                  <TableCell>{rail.Routing_number__c}</TableCell>
                  <TableCell>
                    $
                    {rail.Amount__c.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>{rail.Description__c}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <Box className="flex-row-space-between">
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            setJsonContent(railData);
            setDialogTitle('Rails JSON Data');
            setOpenJsonDialog(true);
          }}
        >
          Show Rails JSON
        </Button>
        <Button
          variant="contained"
          onClick={async (event) => await sendRails()}
        >
          Send Rails
        </Button>
      </Box>

      {/* Message Dialog box */}
      <Dialog
        open={mdOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>

      {/* Rails sent dialog box */}
      <Dialog
        open={sentDialogOpen}
        onClose={handleSentClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <List>
            {dialogList.map((rail) => (
              <ListItem key={rail.id}>
                <ListItemText>- {rail.description}</ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSentClose}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={'xl'}
        fullWidth={true}
        open={openJsonDialog}
        onClose={() => setOpenJsonDialog(false)}
      >
        <DialogTitle>
          <Box className="flex-row-space-between">
            <Typography variant="h6">{dialogTitle}</Typography>
            <IconButton onClick={() => setOpenJsonDialog(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box p={'1rem'}>
            <JSONTree data={jsonContent} theme={'monokai'} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
