import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React from 'react';

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  title,
  content,
  size = 'sm',
  ready = true,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={size === 'md' ? 'md' : size === 'lg' ? 'lg' : 'sm'}
      sx={{
        '.MuiPaper-root': {
          padding: 2,
        },
      }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {content}
          </Grid>
        </Grid>
      </DialogContent>
      {ready && (
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {cancelText}
          </Button>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationModal;
