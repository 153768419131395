import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LIVE_ANNOUNCEMENTS_LIMIT } from '../../../helpers/constants';
import { niceDate } from '../../../helpers/converters';
import { Announcement } from '../../../types/Announcement';

export const ViewAnnouncements = ({ setAnnouncement, setValue }) => {
  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [activeAnnouncements, setActiveAnnouncements] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    setMessage('Loading...');
    axios
      .get('/api/v1/internal/get-announcements')
      .then((response) => {
        setAnnouncements(response.data);

        const active: any[] = [];
        const now = new Date();
        for (const item of response.data) {
          if (
            item.isActive &&
            new Date(item.startDate) <= now &&
            new Date(item.endDate) >= now
          ) {
            active.push(item);
          }
        }
        setActiveAnnouncements(active);
      })
      .catch((error) => setError('Error fetching announcements'))
      .finally(() => {
        setMessage('');
      });
  }, []);

  const editAnnouncement = (announcement: Announcement) => {
    setAnnouncement(announcement);
    setValue(2);
  };

  return (
    <Box className="content" sx={{ padding: 0 }}>
      {message && <Typography sx={{ color: 'green' }}>{message}</Typography>}
      {error && <Typography sx={{ color: 'red' }}>{error}</Typography>}
      <Typography variant="h5">
        Live Announcements ({activeAnnouncements.length}/
        {LIVE_ANNOUNCEMENTS_LIMIT})
      </Typography>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Id</strong>
                </TableCell>
                <TableCell>
                  <strong>User Group</strong>
                </TableCell>
                <TableCell>
                  <strong>Title</strong>
                </TableCell>
                <TableCell>
                  <strong>Body</strong>
                </TableCell>
                <TableCell>
                  <strong>Start Date</strong>
                </TableCell>
                <TableCell>
                  <strong>End Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Created</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeAnnouncements.map((item) => (
                <TableRow
                  key={item.id}
                  hover
                  onClick={() => editAnnouncement(item)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.targetUserGroup}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell
                    sx={{
                      maxWidth: '200px',
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    {item.body}
                  </TableCell>
                  <TableCell>{niceDate(item.startDate)}</TableCell>
                  <TableCell>{niceDate(item.endDate)}</TableCell>
                  <TableCell>{niceDate(item.createdDate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Typography variant="h5">
        All Announcements ({announcements.length})
      </Typography>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Id</strong>
                </TableCell>
                <TableCell>
                  <strong>User Group</strong>
                </TableCell>
                <TableCell>
                  <strong>Title</strong>
                </TableCell>
                <TableCell>
                  <strong>Body</strong>
                </TableCell>
                <TableCell>
                  <strong>Start Date</strong>
                </TableCell>
                <TableCell>
                  <strong>End Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Active</strong>
                </TableCell>
                <TableCell>
                  <strong>Created</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {announcements.map((item) => (
                <>
                  <TableRow
                    key={item.id}
                    hover
                    onClick={() => editAnnouncement(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.targetUserGroup}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell
                      sx={{
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {item.body}
                    </TableCell>
                    <TableCell>{niceDate(item.startDate)}</TableCell>
                    <TableCell>{niceDate(item.endDate)}</TableCell>
                    <TableCell>{item.isActive ? 'True' : 'False'}</TableCell>
                    <TableCell>{niceDate(item.createdDate)}</TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
