import { Navigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import React from 'react';

export const AuthGuard = ({ children }) => {
  const user = Cookie.get('user');
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
