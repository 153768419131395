import { Check, Clear } from '@mui/icons-material';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

export const EditBool = (props) => {
  const [inputValue, setInputValue] = useState(props.inputValue);
  const [error, setError] = useState('');

  const item = props.item;
  const applicationId = props.applicationId;
  const setLoading = props.setLoading;
  const setIsEditing = props.setIsEditing;
  const getLoanAnalysis = props.getLoanAnalysis;

  const selectedValue = () => {
    switch (inputValue) {
      case true:
        return 'verified';
      case false:
        return 'unable-to-verify';
      default:
        return '';
    }
  };

  const handleInputChange = (event) => {
    if (event.target.value == 'verified') {
      setInputValue(true);
    } else {
      setInputValue(false);
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const handleSave = async (e) => {
    if (inputValue == null) {
      setError('Please enter a value...');
      return;
    }

    setLoading(true);

    await axios.post('/api/v1/uw/value-overrides/' + applicationId, {
      type: item.type,
      boolValue: inputValue,
    });

    await getLoanAnalysis();
    setLoading(false);
    setIsEditing(false);
  };

  return (
    <Box display="flex" justifyContent="space-between" gap="10px">
      <Box>
        <Select value={selectedValue()} onChange={handleInputChange}>
          <MenuItem value={''}>Select</MenuItem>
          <MenuItem value={'verified'}>Verified</MenuItem>
          <MenuItem value={'unable-to-verify'}>Not Verified</MenuItem>
        </Select>
      </Box>
      <Box
        onClick={handleSave}
        style={
          {
            backgroundColor: '#F4F6F9',
            radius: '7px',
            padding: '6px',
          } as React.CSSProperties
        }
      >
        <Check />
      </Box>
      <Box
        onClick={cancelEdit}
        style={
          {
            backgroundColor: '#DCE1ED',
            radius: '7px',
            padding: '6px',
          } as React.CSSProperties
        }
      >
        <Clear />
      </Box>
      <Typography variant="body1">{error}</Typography>
    </Box>
  );
};
