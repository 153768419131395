import { DownloadForOfflineRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slider,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { stateCodes } from '../../assets/const';

export const DnBSearch = ({ app, onSaveReport }) => {
  const searchDnB = async () => {
    if (name === '' || !name || postalCode === '' || !addressRegion) {
      setSnackMessage('Company name and state codes are required');
      setSnackOpen(true);
      return;
    }
    const query = {
      name,
      streetAddressLine1,
      postalCode,
      countryISOAlpha2Code: 'US',
      addressLocality, // city
      addressRegion, // state
      candidateMaximumQuantity: 20,
      confidenceLowerLevelThreshold: threshold,
      isCleanseAndStandardizeInformationRequired: true,
    };
    try {
      const result = await axios.post('/api/v1/uw/search/dnb', query);
      return result.data;
    } catch (err: any) {
      setSnackMessage(err.message ?? 'Failed to search the company');
      setSnackOpen(true);
    }
  };

  const [name, setName] = useState(app.Borrower_Business_Name__c);
  const [streetAddressLine1, setAddress] = useState(
    app.Business_Address__c ?? '',
  );
  const [addressLocality, setCity] = useState(
    app.Business_Address_City__c ?? '',
  );
  const [addressRegion, setStateCode] = useState(
    stateCodes[app.Business_Address_State__c?.toUpperCase()] ?? '',
  );
  const [postalCode, setZipCode] = useState(
    app.Business_Address_Zip_Code__c ?? '',
  );
  const [threshold, setThreshold] = useState(7);
  const [searchResult, setSearchResult] = useState([] as any[]);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('State code is required');
  const [searching, setSearching] = useState(false);

  const handleSearch = async () => {
    setSearching(true);
    const result = await searchDnB();
    if (!result) {
      setSnackMessage('No search results');
      setSnackOpen(true);
      setSearching(false);
      return;
    }
    setSearchResult(result.matchCandidates);
    setSearching(false);
  };

  const saveReport = async (duns, callback) => {
    try {
      await axios.post(`/api/v1/uw/report/${app.Id}/dnb/${duns}`);
      await callback();
    } catch (err) {
      console.error(err);
      setSnackMessage('Failed to pull the report');
      setSnackOpen(true);
    }
  };

  return (
    <>
      <Paper variant="outlined" className="content">
        <Typography variant="h5">
          {app.Name} : {name}
        </Typography>
        <Typography variant="h6">Business Detail</Typography>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business Address"
              value={streetAddressLine1}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business City"
              value={addressLocality}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Select
              value={addressRegion}
              onChange={(e) => setStateCode(e.target.value)}
            >
              {Object.keys(stateCodes).map((state) => {
                return (
                  <MenuItem key={stateCodes[state]} value={stateCodes[state]}>
                    {state}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid item xs={4}>
            <Typography>Confidence Level Threshold : {threshold}</Typography>
            <Slider
              value={threshold}
              max={10}
              step={1}
              onChange={(e, v) => setThreshold(v as number)}
            />
          </Grid>
        </Grid>

        <Box className="flex-row-end">
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
        {searching && <LinearProgress />}
        {searchResult?.length > 0 && (
          <>
            <Typography variant="h6">Matched Businesses</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Phone(s)</TableCell>
                  <TableCell>Confidence Code</TableCell>
                  <TableCell>Name Match Score</TableCell>
                  <TableCell>Pull Report</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResult.map((candidate) => {
                  const org = candidate.organization;
                  const address = org.primaryAddress;
                  const matchQuality = candidate.matchQualityInformation;
                  return (
                    <TableRow key={candidate.displaySequence}>
                      <TableCell>{org.primaryName}</TableCell>
                      <TableCell>
                        <Typography>
                          {address.streetAddress.line1}
                          <br />
                          {address.streetAddress.line2}
                          <br />
                          {address.addressLocality.name},{' '}
                          {address.addressRegion.abbreviatedName}{' '}
                          {address.postalCode}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {org.telephone.map((p) => p.telephoneNumber).join(',')}
                      </TableCell>
                      <TableCell>{matchQuality.confidenceCode}</TableCell>
                      <TableCell>{matchQuality.nameMatchScore}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          startIcon={<DownloadForOfflineRounded />}
                          color="info"
                          onClick={() => saveReport(org.duns, onSaveReport)}
                        >
                          Pull
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </Paper>

      <Snackbar
        open={snackOpen}
        message={snackMessage}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
      />
    </>
  );
};
