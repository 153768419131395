import { SFLoanApplication } from '@backend/types/Loan';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { isAdmin, isUnderwriter } from '../../../helpers/auth';
import {
  UNITED_STATES_FOR_DROPDOWN,
  getRecInfoChoicesByState,
} from '../../../helpers/constants';
import { OracleInsights } from './OracleInsights';

export const AppDetails = () => {
  const app = useOutletContext() as SFLoanApplication;
  const appId = app.Id;

  const [yr1Savings, setYr1Savings] = useState(
    app.Year_1_Total_Solar_Savings__c,
  );
  const [yr1Production, setYr1Production] = useState(
    app.Year_1_System_Production_kWhs__c,
  );
  const [systemSize, setSystemSize] = useState(app.System_Size_in_kWs__c);
  const [systemState, setSystemState] = useState(app.Sys_Prop_Address_State__c);
  const [recInfo, setRecInfo] = useState(app.rec_info__c);
  const [isDirty, setIsDirty] = useState(false);
  const [triggerDSCR, setTriggerDSCR] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [saveMessageColor, setSaveMessageColor] = useState('#080');

  let recInfoItems = getRecInfoChoicesByState(systemState);

  const handleYr1SavingsChange = (event) => {
    setYr1Savings(event.target.value);
    setDirtyAndDSCR();
  };
  const handleYr1ProductionChange = (event) => {
    setYr1Production(event.target.value);
    setDirtyAndDSCR();
  };
  const handleSystemSizeChange = (event) => {
    setSystemSize(event.target.value);
    setDirtyAndDSCR();
  };
  const handleSystemStateChange = (event) => {
    setSystemState(event.target.value);
    if (!['Colorado', 'Illinois', 'Massachusetts'].includes(systemState)) {
      setRecInfo('');
    }
    setDirtyAndDSCR();
  };
  const handleRecInfoChange = (event) => {
    setRecInfo(event.target.value);
    setDirtyAndDSCR();
  };
  const setDirtyAndDSCR = () => {
    setIsDirty(true);
    setTriggerDSCR(true);
  };

  const saveChanges = async () => {
    setSaving(true);

    // set to success initially
    let msg = `Loan ${app.Name} updated!`;
    let color = '#080';

    const body = {
      year1Savings: yr1Savings,
      year1Production: yr1Production,
      systemSize: systemSize,
      systemState: systemState,
      recInfo: recInfo,
    };
    try {
      await axios.put(`/api/v1/loans/update/dscr/${appId}`, body);
    } catch (err) {
      msg = `🚨 Error updating loan ${app.Name}`;
      color = '#C00';
      setTriggerDSCR(false);
    }

    if (triggerDSCR) {
      try {
        await axios.put(`/api/v1/loans/update/solar-economics/${appId}/1`);
      } catch (err) {
        msg = `🚨 Error recalculating DSCR for loan ${app.Name}`;
        color = '#C00';
      }
    }

    setSaveMessage(msg);
    setSaveMessageColor(color);
    setTimeout(() => setSaveMessage(''), 3000);

    setIsDirty(false);
    setTriggerDSCR(false);
    setSaving(false);
  };

  const componentWidth = 270;

  return (
    <Box className="content">
      <Accordion
        sx={{
          borderRadius: 2,
          '&.Mui-expanded': {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          aria-controls={`app-detail-content`}
          id={`app-detail-header`}
          expandIcon={<ExpandMoreIcon />}
          className="flex-gap-0_5 flex-row-start-reverse"
        >
          <Typography variant="h5">{`Application Data`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex">
            <Typography variant="caption">
              🚨 Note: This is beta only. These values should be set correctly
              in the initial application.
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="caption">
              Saving changes will cause Solar Economics (DSCR) to be
              recalulated.
            </Typography>
          </Box>
          <Paper variant="outlined" className="content">
            <div>
              <TextField
                id="editable-text"
                label="Total Year 1 Utility Bill Savings"
                variant="outlined"
                value={yr1Savings}
                type="number"
                onChange={handleYr1SavingsChange}
                inputProps={{
                  style: {
                    textAlign: 'right',
                    width: componentWidth - 30,
                  },
                }}
              />
            </div>
            <div>
              <TextField
                id="editable-text"
                label="Total Year 1 Solar System Production (kWh)"
                variant="outlined"
                value={yr1Production}
                type="number"
                onChange={handleYr1ProductionChange}
                inputProps={{
                  style: {
                    textAlign: 'right',
                    width: componentWidth - 30,
                  },
                }}
              />
            </div>
            <div>
              <TextField
                id="editable-text"
                label="System Size in kWs"
                variant="outlined"
                value={systemSize}
                type="number"
                onChange={handleSystemSizeChange}
                inputProps={{
                  style: {
                    textAlign: 'right',
                    width: componentWidth - 30,
                  },
                }}
              />
            </div>
            <div>
              <FormControl style={{ minWidth: componentWidth }}>
                <Select
                  name="sys_prop_address_state"
                  label="System Property State"
                  value={systemState}
                  required
                  onChange={handleSystemStateChange}
                  input={<OutlinedInput sx={{ fontSize: 12 }} />}
                >
                  {UNITED_STATES_FOR_DROPDOWN.map((item, index) => (
                    <MenuItem value={item.value} key={item.abbreviation}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {['Colorado', 'Illinois', 'Massachusetts'].includes(
              systemState,
            ) && (
              <div>
                <FormControl style={{ minWidth: componentWidth }}>
                  <InputLabel>
                    {systemState == 'Massachusetts'
                      ? 'Utility Territory'
                      : 'Utility Company'}
                  </InputLabel>
                  <Select
                    id="rec-info"
                    value={recInfo}
                    label="------------------------------"
                    onChange={handleRecInfoChange}
                  >
                    {recInfoItems.map((item, index) => (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <Button
              variant="contained"
              disabled={!isDirty || saving}
              sx={{ width: componentWidth }}
              onClick={saveChanges}
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
            {saveMessage !== '' && (
              <Typography
                variant="caption"
                align="center"
                sx={{ width: componentWidth, color: saveMessageColor }}
              >
                {saveMessage}
              </Typography>
            )}
          </Paper>
        </AccordionDetails>
      </Accordion>
      {isAdmin() || isUnderwriter() ? (
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls={`app-detail-content`}
            id={`app-detail-header`}
            expandIcon={<ExpandMoreIcon />}
            className="flex-gap-0_5 flex-row-start-reverse"
          >
            <Typography variant="h5">{`Oracle Insights`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OracleInsights />
          </AccordionDetails>
        </Accordion>
      ) : null}
    </Box>
  );
};
