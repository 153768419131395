import { Cancel, CheckCircle, DoNotDisturb, Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

export const DataChip = ({ value, low, okay }) => {
  let classNames = [
    'data-chip-no-data',
    'data-chip-low',
    'data-chip-okay',
    'data-chip-high',
  ];
  // Type index selects data types above
  let type = 0;
  const factor = 100;
  const roundedValue = Math.round(value * factor) / factor;
  if (roundedValue > Number.NEGATIVE_INFINITY && roundedValue <= low) type = 1;
  else if (roundedValue > low && roundedValue <= okay) type = 2;
  else if (roundedValue > okay) type = 3;

  return (
    <Box maxWidth={200} className={`data-chip ${classNames[type]}`}>
      {type === 0 ? (
        <DoNotDisturb />
      ) : type === 1 ? (
        <Box className="flex-row-end flex-gap-0_5">
          <Cancel />
          <Typography>
            {roundedValue <= -100 ? '-99.00' : roundedValue.toFixed(2)}
          </Typography>
        </Box>
      ) : type === 2 ? (
        <Box className="flex-row-end flex-gap-0_5">
          <Error />
          <Typography>{roundedValue.toFixed(2)}</Typography>
        </Box>
      ) : (
        <Box className="flex-row-end flex-gap-0_5">
          <CheckCircle />
          <Typography>
            {roundedValue >= 100 ? '99.00+' : roundedValue.toFixed(2)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
