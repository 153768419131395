import { Error } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useState } from 'react';
import {
  getItems,
  getSectionScoreString,
} from '../../../helpers/scorecardHelper';
import { getFormatter } from './Formatters';
import { LoanAnalysisItem } from './LoanAnalysisItem';
import { PGCreditMemo } from './PgCreditMemo';
import { ScoringLegend } from './ScoringLegend';

export const LoanAnalysisItems = ({
  loanAnalysis,
  getLoanAnalysis,
  showScores,
  app,
  guarantor,
  annualDebtObligation,
  automationErrors,
  scores,
  lowQualityItems,
  allGuarantors,
}) => {
  const [showCreditMemo, setShowCreditMemo] = useState(false);
  const [hoverAnchorElement, setHoverAnchorElement] = useState(null);

  const isPopoverOpen = Boolean(hoverAnchorElement);
  const shouldBreakToSingleColumn = useMediaQuery('(min-width:1100px)');

  const handlePopoverOpen = (event) => {
    setHoverAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHoverAnchorElement(null);
  };

  const getTotalScore = () => {
    let total = 0;
    for (const key in scores) {
      total += scores[key].points;
    }

    return total;
  };

  const getTotalScoreFormatted = () => {
    return getTotalScore().toFixed(1);
  };

  const getMaxScore = () => {
    let max = 0;
    for (const key in scores) {
      max += scores[key].max;
    }

    return max.toFixed(1);
  };

  const getLoanQualityComponent = () => {
    let message: string | null = null;
    let severity = 'info';

    const total = getTotalScore();

    if (total < 55) {
      severity = 'error';
      message = 'This loan is unsatisfactory';
    } else if (total < 75) {
      severity = 'warning';
      message = 'This loan should be manually reviewed';
    } else if (total < 95) {
      severity = 'success';
      message = 'This loan is recommended for approval';
    } else if (total < 115) {
      severity = 'success';
      message = 'This loan is recommended for approval';
    } else {
      severity = 'success';
      message = 'This loan is recommended for approval';
    }

    return (
      <>
        <Alert
          severity={severity as AlertColor}
          sx={{ p: '1em', mb: '2em' }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          action={
            <Button
              variant="dark"
              color="inherit"
              size="small"
              onClick={() => setShowCreditMemo(true)}
            >
              Generate Credit Memo
            </Button>
          }
        >
          Decision: {message}
        </Alert>
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={isPopoverOpen}
          anchorEl={hoverAnchorElement}
          onClose={handlePopoverClose}
        >
          <ScoringLegend />
        </Popover>
      </>
    );
  };

  const getItemsComponents = (
    grouping: string,
    overrideShowScores: boolean | null = null,
  ) => {
    const items = getItems(loanAnalysis, grouping);
    const itemShowScores =
      overrideShowScores !== null ? overrideShowScores : showScores;

    return items.map((item, k: number) => {
      return (
        <LoanAnalysisItem
          item={item}
          appId={app.Id}
          getLoanAnalysis={getLoanAnalysis}
          showScores={itemShowScores}
          scores={scores}
          automationError={automationErrors.find(
            (error) => error.itemType === item.type,
          )}
        ></LoanAnalysisItem>
      );
    });
  };

  const renderLowQualityItems = () => {
    if (lowQualityItems.length === 0) {
      return <></>;
    }

    const rendered = lowQualityItems.map((vh, i) => {
      const item = loanAnalysis.find((item) => item.type === vh.type);
      const displayValue = getFormatter(item.itemConfig.formatter)(vh);
      const displayType = item.itemConfig['label'];
      return (
        <Typography
          key={i}
          variant="body1"
          sx={{
            border: '1px solid #DCE1ED',
            width: '100%',
            borderRadius: '5px',
            padding: '9px 13px',
            mt: '1rem',
          }}
        >
          {`${displayType}: ${displayValue}`}
        </Typography>
      );
    });

    return (
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Box className="flex-row-end flex-gap-0_5">
              <Error color="error" />
              <Typography variant="subtitle1">Issues Identified</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <Box>{rendered}</Box>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const getCustomerVerificationPanel = () => {
    const section = (
      <Grid item key="customer-verification" xs={12}>
        {getItemsComponents('customer-verification', false)}
      </Grid>
    );
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Box className="flex-row-end flex-gap-0_5">
            <Typography variant="subtitle1">Customer Verification</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <Box>{section}</Box>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const getPanel = (grouping: string, title: string) => {
    return (
      <Grid item key={grouping} xs={12} sm={shouldBreakToSingleColumn ? 6 : 12}>
        <Typography variant="h5">{title}</Typography>
        {showScores && (
          <Paper
            elevation={3}
            style={{
              backgroundColor: '#EEF5FF',
              padding: '.3em',
            }}
          >
            <Typography variant="body1">
              Total Score{' '}
              <b>{getSectionScoreString(loanAnalysis, scores, grouping)}</b>
            </Typography>
          </Paper>
        )}
        {getItemsComponents(grouping)}
      </Grid>
    );
  };

  const getTitle = () => {
    if (showScores) {
      return `PG Scorecard: Rating ${getTotalScoreFormatted()}/${getMaxScore()}`;
    }

    return 'Scorecard';
  };

  if (showCreditMemo) {
    return (
      <PGCreditMemo
        loanAnalysis={loanAnalysis}
        scores={scores}
        guarantor={guarantor}
        annualDebtObligation={annualDebtObligation}
        pgScore={{
          totalScore: getTotalScoreFormatted(),
          maxScore: getMaxScore(),
        }}
        setBackButton={() => setShowCreditMemo(false)}
        allGuarantors={allGuarantors}
      />
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        {app.Is_Personal_Guarantor__c === 'Yes' && (
          <Grid item xs={12}>
            {getLoanQualityComponent()}
          </Grid>
        )}
        {lowQualityItems.length > 0 && (
          <Grid item xs={12}>
            {renderLowQualityItems()}
          </Grid>
        )}
        <Grid item xs={12}>
          {getCustomerVerificationPanel()}
        </Grid>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="subtitle1">{getTitle()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {getPanel('debt-capacity-cash-flow', 'Debt Capacity/Cash Flow')}
                {getPanel(
                  'debt-capacity-asset-value',
                  'Debt Capacity/Asset Value',
                )}
                {getPanel('solar-economics', 'Solar Economics')}
                {getPanel('other-metrics', 'Other Metrics')}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};
