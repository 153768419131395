import { CancelRounded, CheckCircle, MoreHoriz } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React from 'react';

const getChipProps = (status) => {
  const chipPropsByStatus = {
    VERIFIED: {
      label: 'Approved',
      color: 'success',
      icon: <CheckCircle />,
    },
    NOT_VERIFIED: {
      label: 'In Progress',
      icon: (
        <MoreHoriz
          sx={{
            bgcolor: '#868795',
            borderRadius: '50%',
            '&.MuiChip-icon': {
              color: 'white',
            },
          }}
        />
      ),
    },
    ISSUES_IDENTIFIED: {
      label: 'Issues Identified',
      color: 'error',
      icon: <CancelRounded />,
    },
  };
  return chipPropsByStatus[status] || chipPropsByStatus.NOT_VERIFIED;
};

export const StatusChip = ({ status }) => {
  const chipProps = getChipProps(status);
  return (
    <Chip
      label={chipProps.label}
      color={chipProps.color}
      icon={chipProps.icon}
      size="small"
    />
  );
};
