import { CancelRounded, CheckCircle, MoreHoriz } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React from 'react';

export const StatusIcon = ({ status, statusType }) => {
  const verifiedIcon = <CheckCircle color="success" sx={{ fontSize: 16 }} />;
  const notVerifiedIcon = (
    <MoreHoriz
      sx={{
        bgcolor: '#868795',
        fontSize: 16,
        color: 'white',
        borderRadius: '50%',
        '&.MuiChip-icon': {
          color: 'white',
        },
      }}
    />
  );
  const issuesIdentifiedIcon = (
    <CancelRounded color="error" sx={{ fontSize: 16 }} />
  );

  const statusIconSets = {
    verification: {
      VERIFIED: verifiedIcon,
      NOT_VERIFIED: notVerifiedIcon,
      ISSUES_IDENTIFIED: issuesIdentifiedIcon,
    },
    document_approval: {
      Approved: <CheckCircle color="primary" sx={{ fontSize: 20 }} />,
      'Issues Identified': issuesIdentifiedIcon,
      'Sent via Docusign': notVerifiedIcon,
      'Awaiting Upload': notVerifiedIcon,
      'Signature Needed': notVerifiedIcon,
      'Waiting Review': notVerifiedIcon,
      'Pending Call': notVerifiedIcon,
    },
  };

  const getIcon = (status, statusType) => {
    const iconByStatus =
      statusIconSets[statusType] || statusIconSets.verification;
    return iconByStatus[status] || notVerifiedIcon;
  };

  const capitalize = (word) => {
    if (!word) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const friendlyFormat = (str) => {
    try {
      if (!str) return str;
      return str.toLowerCase().split('_').map(capitalize).join(' ');
    } catch (error) {
      console.error('An error occurred in friendlyFormat:', error);
      return str;
    }
  };

  const icon = getIcon(status, statusType);
  return (
    <Chip
      icon={icon}
      label={friendlyFormat(statusType) + ': ' + friendlyFormat(status)}
      variant="outlined"
      sx={{ m: 0.5 }}
    />
  );
};
