import { Box } from '@mui/material';
import logoWithName from '../assets/logo.png';
import React from 'react';

const LogoWithName = (props) => (
  <Box
    version="1.1"
    display="block"
    {...props}
    src={logoWithName}
    alt="LogoWithName"
    component="img"
  />
);
export default LogoWithName;
