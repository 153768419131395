import axios from 'axios';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Lock from '@mui/icons-material/Lock';
import {
  Paper,
  Box,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import LogoWithName from '../components/LogoWithName';
import React from 'react';

export const LoginPage = () => {
  const ssoLogin = async () => {
    const result = await axios.get('/api/auth/sso');
    window.location = result.data;
  };
  const sendLoginRequest = () => {
    if (username === '' || password === '') {
      return;
    }
    axios
      .post('/api/auth/login', {
        username,
        password,
      })
      .then((result) => {
        if (result.status === 200) window.location.href = '/';
        else alert(result ?? 'Failed to log in');
      })
      .catch((err) => {
        alert(err.message ?? 'failed to log in');
      });
  };

  if (document.cookie.indexOf('user=') >= 0) {
    return <Navigate to={'/'} />;
  }
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#efefef',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '95vh',
          justifyContent: 'center',
          alignItems: 'center',
          pb: '5vh',
        }}
      >
        <Paper
          sx={{
            pt: { xs: 2, sm: 3, md: 7.75 },
            pb: { xs: 2, sm: 4, md: 8.5 },
            px: { xs: 3, sm: 7, md: 14 },
            width: { xs: 300, sm: 450 },
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <Box display="flex" flexDirection={'column'} gap={'25px'}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <LogoWithName height={41.5} />
            </Box>
            <FormControl variant="outlined">
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                label="Email"
                onChange={(event) => setUsername(event.target.value)}
                type="email"
                id="email"
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                onChange={(event) => setPassword(event.target.value)}
                id="password"
                type="password"
                label="Password"
                startAdornment={
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box display={'flex'} justifyContent={'end'}>
              <Button
                onClick={sendLoginRequest}
                variant="contained"
                sx={{ width: '2rem' }}
              >
                Login
              </Button>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Button onClick={ssoLogin} variant="outlined" color="success">
              Login with SSO
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
