import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  ANNOUNCEMENT_MAX_BODY_LENGTH,
  LIVE_ANNOUNCEMENTS_LIMIT,
  UserGroups,
} from '../../../helpers/constants';
import { isValidURL } from '../../../helpers/helpers';
import { AnnoucementPreview } from './AnnouncementPreview';
import dayjs, { Dayjs } from 'dayjs';

export const initialErrors = () => {
  return {
    isError: false,
    general: '',
    title: '',
    body: '',
    startDate: '',
    endDate: '',
    linkText: '',
    link: '',
  };
};

// this is also used in EditAnnouncement
export const validateAnnouncement = async (
  id: number | null,
  body: string,
  startDateInitial: Dayjs | null,
  endDateInitial: Dayjs | null,
  linkText: string | undefined,
  link: string | undefined,
  isActive: boolean,
): Promise<any> => {
  // startDate and endDate come in from the DatePicker as objects that are not actually Dates

  const errors = initialErrors();

  if (!body) {
    return { ...errors, body: 'Body is required', isError: true };
  } else if (body.length > ANNOUNCEMENT_MAX_BODY_LENGTH) {
    return {
      ...errors,
      body: `Body must be ${ANNOUNCEMENT_MAX_BODY_LENGTH} characters or less.`,
      isError: true,
    };
  } else if (!startDateInitial) {
    return { ...errors, startDate: 'Start Date is required', isError: true };
  } else if (!endDateInitial) {
    return { ...errors, endDate: 'End Date is required', isError: true };
  } else if (!linkText && link) {
    return {
      ...errors,
      linkText: 'Link Text is required if Link is provided',
      isError: true,
    };
  } else if (!link && linkText) {
    return {
      ...errors,
      link: 'Link is required if Link Title is provided',
      isError: true,
    };
  } else if (link && !isValidURL(link)) {
    return { ...errors, link: 'Link is not a valid URL', isError: true };
  }

  let startDateISO: string;
  try {
    startDateISO = startDateInitial.toISOString();
  } catch (err) {
    return {
      ...errors,
      startDate: 'A valid Start Date is required',
      isError: true,
    };
  }

  let endDateISO: string;
  try {
    endDateISO = endDateInitial.toISOString();
  } catch (err) {
    return {
      ...errors,
      endDate: 'A valid End Date is required',
      isError: true,
    };
  }
  const liveAnnouncements = await axios.get(
    `/api/v1/internal/get-validation-announcements?endDate=${endDateISO}`,
  );

  const startDate = dayjs(startDateISO);
  const endDate = dayjs(endDateISO);

  if (liveAnnouncements && liveAnnouncements.data.length > 0) {
    let currentDate = dayjs().hour(12).minute(0).second(0).millisecond(0);
    const maxDateISO = liveAnnouncements.data.reduce((max, current) => {
      return dayjs(current.endDate).isAfter(dayjs(max.endDate)) ? current : max;
    }).endDate;

    const maxDate = dayjs(maxDateISO);

    while (currentDate.isBefore(maxDate) || currentDate.isSame(maxDate)) {
      let liveCount = 0;
      let found = false;
      for (const ann of liveAnnouncements.data) {
        if (
          currentDate.isAfter(dayjs(ann.startDate)) ||
          currentDate.isSame(dayjs(ann.startDate))
        ) {
          if (
            currentDate.isBefore(dayjs(ann.endDate)) ||
            currentDate.isSame(dayjs(ann.endDate))
          ) {
            liveCount++;
            if (ann.id === id) {
              found = true;
            }
          }
        }
      }
      // if the announcement is not in the group of live announcements for this date in the db, add it to the count if it is active
      if (!found) {
        const currentAnnouncementIsActive =
          (currentDate.isAfter(startDate) || currentDate.isSame(startDate)) &&
          (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) &&
          isActive;
        liveCount += currentAnnouncementIsActive ? 1 : 0;
      }

      if (liveCount > LIVE_ANNOUNCEMENTS_LIMIT) {
        errors.general = `There can only be ${LIVE_ANNOUNCEMENTS_LIMIT} live announcements.`;
        errors.isError = true;
        break;
      }
      currentDate = currentDate.add(1, 'day');
    }
  }

  return errors;
};

export const CreateAnnouncement = ({ setValue }) => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [linkText, setLinkText] = useState('');
  const [link, setLink] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(null)); // MUI DatePicker expects a Dayjs object
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(null)); // MUI DatePicker expects a Dayjs object
  const [targetUserGroup, setTargetUserGroup] = useState<string | null>(
    UserGroups.INSTALLER,
  );
  const [isActive, setIsActive] = useState(true);
  const [errors, setErrors] = useState(initialErrors());
  const [message, setMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [announcementCount, setAnnouncementCount] = useState('...');

  useEffect(() => {
    axios
      .get('/api/v1/internal/get-live-announcements')
      .then((res) => setAnnouncementCount(res.data.length));
  }, []);

  const createAnnouncement = async () => {
    await setErrors(initialErrors());

    const errs = await validateAnnouncement(
      null,
      body,
      startDate,
      endDate,
      linkText,
      link,
      isActive,
    );

    await setErrors(errs);

    if (errs.isError) {
      return;
    }

    try {
      setSaving(true);
      setMessage('Updating announcement...');

      const query = {
        body: body,
        title: title,
        linkText: linkText,
        link: link,
        targetUserGroup: targetUserGroup,
        isActive: isActive,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      };

      await axios.post('/api/v1/internal/create-announcement', query);

      setMessage('Announcement created successfully.');
      setValue(0);
    } catch (err: any) {
      setErrors({ ...errors, general: 'Create announcement failed.' });
      console.error(err);
    } finally {
      setTitle('');
      setBody('');
      setLinkText('');
      setLink('');
      setStartDate(null);
      setEndDate(null);
      setTargetUserGroup(UserGroups.INSTALLER);
      setIsActive(true);
      setSaving(false);
    }
  };

  return (
    <>
      {(message || errors.general) && (
        <Box className="content">
          {message && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ color: 'green' }}>{message}</Typography>
            </Box>
          )}
          {errors.general && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ color: 'red' }}>{errors.general}</Typography>
            </Box>
          )}
        </Box>
      )}
      <Box className="content" sx={{ padding: 0 }}>
        <Typography variant="h5">
          Create Announcement (Live: {announcementCount}/
          {LIVE_ANNOUNCEMENTS_LIMIT})
        </Typography>
        <Box>
          <Paper className="content" variant="outlined">
            <TextField
              variant="outlined"
              label="Title"
              value={title}
              type="string"
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              variant="outlined"
              label={`Body (required, length: ${body.length}/${ANNOUNCEMENT_MAX_BODY_LENGTH})`}
              value={body}
              type="string"
              onChange={(e) => {
                const newBody = e.target.value;
                if (newBody.length > ANNOUNCEMENT_MAX_BODY_LENGTH) {
                  errors.body = `Body must be ${ANNOUNCEMENT_MAX_BODY_LENGTH} characters or less.`;
                  setErrors(errors);
                } else {
                  if (errors.body) {
                    errors.body = '';
                    setErrors(errors);
                  }
                }
                setBody(newBody);
              }}
              error={errors.body !== ''}
              helperText={errors.body}
            />
            <TextField
              variant="outlined"
              label="Link Text"
              value={linkText}
              type="string"
              onChange={(e) => setLinkText(e.target.value)}
              error={errors.linkText !== ''}
              helperText={errors.linkText}
            />
            <TextField
              variant="outlined"
              label="Link"
              value={link}
              type="string"
              onChange={(e) => setLink(e.target.value)}
              error={errors.link !== ''}
              helperText={errors.link}
            />
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start date (required)"
                  value={startDate}
                  onChange={(newValue: Dayjs | null) => {
                    setStartDate(newValue);
                  }}
                  sx={{ width: '200px', mr: '20px' }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End date (required)"
                  value={endDate}
                  onChange={(newValue: Dayjs | null) => {
                    setEndDate(newValue);
                  }}
                  sx={{ width: '200px' }}
                />
              </LocalizationProvider>
            </Box>
            {errors.startDate && (
              <Typography sx={{ color: 'red' }}>{errors.startDate}</Typography>
            )}
            {errors.endDate && (
              <Typography sx={{ color: 'red' }}>{errors.endDate}</Typography>
            )}
            <InputLabel id="user-group-label">Target User Group</InputLabel>
            <Select
              labelId="user-group-label"
              id="user-group-select"
              value={targetUserGroup}
              label="Target User Group"
              onChange={(event) => setTargetUserGroup(event.target.value)}
              sx={{ display: 'flex', justifyContent: 'center', width: '300px' }}
            >
              {Object.values(UserGroups).map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  onChange={(event) => setIsActive(event.target.checked)}
                  name="activeCheckbox"
                  color="primary"
                />
              }
              label="Active"
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <Button
                variant="contained"
                onClick={createAnnouncement}
                disabled={saving}
              >
                Create Announcement
              </Button>
            </Box>
            <AnnoucementPreview
              title={title}
              body={body}
              link={link}
              linkText={linkText}
              startDate={startDate}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};
