import React from 'react';
import { Box, Stack, Skeleton, } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight} from 'react-syntax-highlighter/dist/esm/styles/prism';

interface CodeBlockProps {
  data?: string;
  isLoading?: boolean
}

export const CodeBlock = ({ data, isLoading }: CodeBlockProps) => {
  
  return (
    <>
    <Box
      sx={{
        marginTop: 3,    
      }}
    >

      <Box
        sx={{
          backgroundColor: '#f5f5f5',  
          padding: '1rem',             
          fontFamily: 'monospace',     
          whiteSpace: 'pre-wrap',      
          overflowX: 'auto',   
          borderRadius: '10px',

        }}
      >
        { !isLoading ? (
          <SyntaxHighlighter language="json"
            style={solarizedlight}
            customStyle={{
              backgroundColor: "transparent",
            }}
            showLineNumbers={true}
          >
            {data}
          </SyntaxHighlighter>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant="rounded" width={5} height={14} />
            <Skeleton variant="rounded" width={250} height={14} />  

            <Skeleton variant="rounded" width={310} height={14} /> 

            <Skeleton variant="rounded" width={290} height={14} />  

            <Skeleton variant="rounded" width={5} height={14} />
          </Stack>
        )
        
        }
      </Box>

    </Box>
    </>
  );
};
