import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export const Loading = ({ overlay = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: 'white',
        justifyContent: 'center',
        minHeight: '20vh',
        minWidth: '20rem',
        alignItems: 'center',
        opacity: overlay ? 0.5 : 1,
        position: overlay ? 'absolute' : 'relative',
      }}
    >
      <CircularProgress />
    </Box>
  );
};
