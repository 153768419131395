import { useState } from 'react';
import './uwStyle.css';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { xlAcceptExt } from '../../assets/const';
import React from 'react';

export const InstallerScorecardUpload = () => {
  const [dialogText, setDialogText] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Error');

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('', file, file.name);
    try {
      const result = await axios.post(
        '/api/v1/uw/upload/installer-scorecard',
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );

      const financialRecords = result.data.financialResult?.rowCount ?? 0;
      const valuesRowCount = result.data.valuesResult?.rowCount ?? 0;
      setDialogTitle('Succeeded');
      setDialogText(`${valuesRowCount} value rows, and ${financialRecords} financials
      records saved successfully`);
      setShowDialog(true);
    } catch (error: any) {
      setDialogTitle('Failed to upload or parse the file');
      setDialogText(error.response.data.message ?? 'Failed to parse the file');
      setShowDialog(true);
    }
  };

  const handleUpload = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = xlAcceptExt;

    input.click();
    input.onchange = (_) => {
      if (input.files) uploadFile(input.files[0]);
    };
  };
  return (
    <>
      <Box className={'uwTool'}>
        <Typography variant="h6">Installer scorecard uploader</Typography>
        <Button onClick={handleUpload} variant="contained">
          Upload
        </Button>
      </Box>
      {showDialog && (
        <Dialog open={showDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
