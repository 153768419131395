import { ScoreCardFormatters } from '@backend/constants';

export interface ValueHolder {
  value: number;
  type: string;
  stringValue: string | null;
  boolValue: boolean | null;
}

const bankcruptcyFormatter = (holder: ValueHolder) => {
  if (holder.value > 0) {
    return 'Has bankruptcies';
  }

  return 'No bankruptcies';
};

const lienFormatter = (holder: ValueHolder) => {
  if (holder.value > 0) {
    return 'Has liens';
  }

  return 'No liens';
};

const riskFormatter = (holder: ValueHolder) => {
  if (holder.boolValue) {
    return 'High Risk';
  }

  return 'Not High Risk';
};

const currencyFormatter = (item: ValueHolder) => {
  if (item.value === null) {
    return '--';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(item.value);
};

const defaultFormatter = (item: ValueHolder) => {
  if (item.value === null) {
    return '--';
  }

  return item.value.toFixed(2);
};
const integerFormatter = (item: ValueHolder) => {
  if (item.value === null) {
    return '--';
  }

  return item.value.toFixed(0);
};

const percentFormatter = (item: ValueHolder) => {
  if (item.value === null) {
    return '--';
  }

  return `${item.value.toFixed(0)} %`;
};

const percentOneDecimalFormatter = (item: ValueHolder) => {
  if (item.value === null) {
    return '--';
  }

  return `${item.value.toFixed(1)} %`;
};

const defaultStringFormatter = (item: ValueHolder) => {
  if (!item.stringValue) {
    return '--';
  }

  return item.stringValue;
};

const verificationBoolFormatter = (item: ValueHolder) => {
  if (item.boolValue) {
    return 'Verified';
  }

  return 'Not Verified';
};

export const getFormatter = (formatterType: string) => {
  switch (formatterType) {
    case ScoreCardFormatters.CURRENCY:
      return currencyFormatter;
    case ScoreCardFormatters.DEFAULT:
      return defaultFormatter;
    case ScoreCardFormatters.INTEGER:
      return integerFormatter;
    case ScoreCardFormatters.PERCENT:
      return percentFormatter;
    case ScoreCardFormatters.PERCENT_ONE_DECIMAL:
      return percentOneDecimalFormatter;
    case ScoreCardFormatters.DEFAULT_STRING:
      return defaultStringFormatter;
    case ScoreCardFormatters.VERIFICATION_BOOL:
      return verificationBoolFormatter;
    case ScoreCardFormatters.BANKRUPTCIES:
      return bankcruptcyFormatter;
    case ScoreCardFormatters.LIENS:
      return lienFormatter;
    case ScoreCardFormatters.RISK:
      return riskFormatter;
    default:
      throw new Error(`Unknown formatter type ${formatterType}`);
  }
};
