import {
  Box,
  Dialog,
  Paper,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
  Avatar,
} from '@mui/material';
import { Close, DataObject } from '@mui/icons-material';
import { ImageButton } from '../../components/ImageButton';
import paynetLogo from '../../assets/img/paynet.jpeg';
import lexisLogo from '../../assets/img/lexis-nexis.png';
import dnbLogo from '../../assets/img/dnb.png';
import equifaxLogo from '../../assets/img/equifax.png';
import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { PaynetSearch } from '../../components/Underwriting/PaynetSearch';
import { JSONTree } from 'react-json-tree';
import { CenteredText } from '../../components/CenteredText';
import { DnBSearch } from '../../components/Underwriting/DnBSearch';
import { extractReportSummary } from '../../helpers/uwReportsHelper';
import { DataKeyVal } from '../../components/DataKeyVal';
import React from 'react';

export const fetchApplicationReports = async ({ params }) => {
  try {
    const reports = await getApplicationReports(params.appId);
    const application = await axios.get(`/api/v1/loans/app/${params.appId}`);

    return { reports: reports.data, application: application.data };
  } catch (err: any) {
    return {
      error: {
        title: 'Error Fetching the Reports',
        message:
          err.response.data.message ??
          'An error occurred while trying to fetch the reports for this application',
        status: err.response.status,
      },
    };
  }
};

const logos = {
  paynet: paynetLogo,
  lexis: lexisLogo,
  dnb: dnbLogo,
};

const getApplicationReports = async (appId) => {
  return await axios.get(`/api/v1/uw/reports/${appId}`);
};

export const UwReport = () => {
  const data = useLoaderData() as any;
  const [openDialog, setOpenDialog] = useState(false);
  const [vendor, setVendor] = useState('');
  const [vendorLogo, setVendorLogo] = useState(paynetLogo);
  const [searchComponent, setSearchComponent] = useState(
    <PaynetSearch app={undefined} onSaveReport={undefined} />,
  );
  const [reports, setReports] = useState(data.reports);
  const [openJsonDialog, setOpenJsonDialog] = useState(false);
  const [jsonContent, setJsonContent] = useState({});
  const [dialogTitle, setDialogTitle] = useState('JSON Data');
  const app = data.application;

  const handleOnSaveReport = async () => {
    setOpenDialog(false);
    const reports = await getApplicationReports(app.Id);
    setReports(reports.data);
  };

  return (
    <Box className="content">
      <Typography variant="h5">Reports : {app.Name}</Typography>
      <Paper variant="outlined" className="content">
        <Typography variant="h6">{app.Borrower_Business_Name__c}</Typography>
        <Typography variant="body1">Pull reports from: </Typography>
        <Box sx={{ display: 'flex', gap: '2rem' }}>
          <ImageButton
            image={dnbLogo}
            text="Dun & Bradstreet"
            onClick={() => {
              setVendor('Dun & Bradstreet');
              setSearchComponent(
                <DnBSearch app={app} onSaveReport={handleOnSaveReport} />,
              );
              setVendorLogo(dnbLogo);
              setOpenDialog(true);
            }}
          />
          <ImageButton
            image={lexisLogo}
            text="Lexis Nexis"
            onClick={() => {
              setVendor('LexisNexis');
              setVendorLogo(lexisLogo);
              setSearchComponent(
                <PaynetSearch app={app} onSaveReport={() => {}} />,
              );
              setOpenDialog(true);
            }}
          />
          <ImageButton
            image={equifaxLogo}
            text="Equifax"
            onClick={() => {
              setVendor('Equifax');
              setSearchComponent(
                <PaynetSearch app={app} onSaveReport={() => {}} />,
              );
              setVendorLogo(equifaxLogo);
              setOpenDialog(true);
            }}
          />
          <ImageButton
            image={paynetLogo}
            text="PayNet"
            onClick={() => {
              setVendor('Paynet');
              setVendorLogo(paynetLogo);
              setSearchComponent(
                <PaynetSearch app={app} onSaveReport={handleOnSaveReport} />,
              );
              setOpenDialog(true);
            }}
          />
        </Box>
        <Box className="content">
          <Typography variant="h6">Reports</Typography>
          {reports.length === 0 && <CenteredText text="No reports yet" />}
          {reports.map((report) => {
            const reportSummary = extractReportSummary(
              report.report_type,
              report,
            );
            return (
              <Box className="reportSection" key={report.search_id}>
                <hr />
                <Box className="flex-row-space-between">
                  <Box className="flex-gap-1">
                    <Avatar src={logos[report.report_type]} />
                    <Typography variant="button">
                      {report.report_type}
                    </Typography>
                  </Box>
                  <Box className="flex-row-end">
                    <Typography variant="body1">
                      {new Date(report.generated_date).toDateString()}
                    </Typography>
                    <IconButton
                      href=""
                      onClick={() => {
                        setJsonContent(report.report);
                        setOpenJsonDialog(true);
                      }}
                    >
                      <DataObject />
                    </IconButton>
                  </Box>
                </Box>
                {reportSummary?.map((report) => (
                  <Box className="content">
                    <Typography>{report.section}</Typography>
                    <Box className="wrapped-data-boxes">
                      {report.data.map((data) => (
                        <DataKeyVal label={data.label} value={data.value} />
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      </Paper>

      <Dialog
        maxWidth={'xl'}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>
          <Box className="uw-search-title">
            <Box className="uw-search-logo">
              <img src={vendorLogo} />
              <Typography variant="h6">{vendor} Search</Typography>
            </Box>
            <IconButton onClick={() => setOpenDialog(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{searchComponent}</DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={openJsonDialog}
        onClose={() => setOpenJsonDialog(false)}
      >
        <DialogTitle>
          <Box className="flex-row-space-between">
            <Typography variant="h6">{dialogTitle}</Typography>
            <IconButton onClick={() => setOpenJsonDialog(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box p={'1rem'}>
            <JSONTree data={jsonContent} theme={'monokai'} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
