import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { toDate } from '../../helpers/converters';

export const FileAttachments = ({ loanData }) => {
  return (
    <Paper variant="outlined" className="content">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Modified Date</TableCell>
            <TableCell>Overwritten</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loanData.Attachments.map((attachment) => {
            return (
              <TableRow
                key={attachment.Id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>{attachment.FileName}</TableCell>
                <TableCell>{toDate(attachment.Date)}</TableCell>
                <TableCell>
                  {attachment.FileOverwritten ? 'YES' : 'NO'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};
