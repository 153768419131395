import { Box, Typography } from '@mui/material';
import React from 'react';

export const SummaryRow = ({
  label,
  value,
  level = 0,
  placeholder = false,
}) => {
  return (
    <Box className="summary-row" sx={{ pl: `${level + 1}rem` }}>
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body1" className={placeholder ? 'highlighted' : ''}>
        {value}
      </Typography>
    </Box>
  );
};

export const SummaryRow2 = ({
  label,
  values,
  level = 0,
  placeholder = false,
}) => {
  return (
    <Box className="summary-row" sx={{ pl: `${level + 1}rem`, fontSize: 7 }}>
      <Box className="flex-row-space-between">
        <Typography variant="body1">{label}</Typography>
        {values.map((value, i) => (
          <Typography
            variant="body1"
            key={i}
            className={placeholder ? 'highlighted' : ''}
          >
            {value}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
