import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const DocumentIssuesModal = ({ open, onClose, requiredDocuments }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        Required Documents with Issues
      </DialogTitle>
      <DialogContent sx={{ py: 2 }}>
        {/* <Grid container spacing={1.5}> */}
        <TableContainer component={Paper}>
          <Table aria-label="missing documents table">
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requiredDocuments.map((doc) => {
                if (
                  ['MISSING', 'ISSUES_IDENTIFIED'].includes(doc.approval_status)
                ) {
                  return (
                    <TableRow
                      key={doc.requirement_name}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {doc.requirement_name}
                      </TableCell>
                      <TableCell align="right">{doc.approval_status}</TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ mr: 2, mb: 2 }}>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentIssuesModal;
