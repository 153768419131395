export const xlAcceptExt = `application/vnd.ms-excel, application/vnd.ms-excel, 
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
  application/vnd.ms-excel.sheet.macroEnabled.12`;

export const wordpdftxtAcceptExt = `application/pdf,application/vnd.ms-excel, 
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, 
application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain`;

export const sfBase =
  process.env.NODE_ENV === 'development'
    ? 'https://sunstonecredit--qabox2.sandbox.lightning.force.com/lightning/r/Application__c/'
    : 'https://sunstonecredit.lightning.force.com/lightning/r/Application__c/';

export const stateCodes = {
  'ARMED FORCES AMERICA': 'AA',
  'ARMED FORCES EUROPE': 'AE',
  ALASKA: 'AK',
  ALABAMA: 'AL',
  'ARMED FORCES PACIFIC': 'AP',
  ARKANSAS: 'AR',
  'AMERICAN SAMOA': 'AS',
  ARIZONA: 'AZ',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  'DISTRICT OF COLUMBIA': 'DC',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  MICRONESIA: 'FM',
  GEORGIA: 'GA',
  GUAM: 'GU',
  HAWAII: 'HI',
  IOWA: 'IA',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MASSACHUSETTS: 'MA',
  MARYLAND: 'MD',
  MAINE: 'ME',
  'MARSHALL ISLANDS': 'MH',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSOURI: 'MO',
  'NORTHERN MARIANA ISLANDS': 'MP',
  MISSISSIPPI: 'MS',
  MONTANA: 'MT',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  NEBRASKA: 'NE',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  NEVADA: 'NV',
  'NEW YORK': 'NY',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  'PUERTO RICO': 'PR',
  PALAU: 'PW',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VIRGINIA: 'VA',
  'VIRGIN ISLANDS': 'VI',
  VERMONT: 'VT',
  WASHINGTON: 'WA',
  WISCONSIN: 'WI',
  'WEST VIRGINIA': 'WV',
  WYOMING: 'WY',
};

export enum InternalLoanStatus {
  APPLICATION_CREATED = 'Application Created',
  APPLICATION_PENDING = 'Application Pending',
  DOCUMENTS_PENDING = 'Documents Pending',
  PENDING_BORROWER = 'Pending Borrower',
  PENDING_INSTALLER = 'Pending Installer',
  APPLICATION_COMPLETE = 'Application Complete',
  UW_IN_PROGRESS = 'UW In-Progress',
  UW_COMPLETE = 'UW Complete',
  READY_FOR_BANK = 'Ready for Bank',
  COMPLIANCE_PENDING = 'Compliance Pending',
  COMPLIANCE_FAIL = 'Compliance Fail',
  COMPLIANCE_PASS = 'Compliance Pass',
  AGREEMENTS_PENDING = 'Agreements Pending',
  AGREEMENTS_EXECUTED = 'Agreements Executed',
  AGREEMENT_COMPLETE = 'Agreement Complete',
  READY_TO_FUND = 'Ready to Fund',
  LOAN_FUNDED = 'Loan Funded',
  UNDERWRITING_REFRESH_IN_PROGRESS = 'Underwriting Refresh in Progress',
  PENDING_CHANGE_REQUEST = 'Pending Change Request',
  CLOSED = 'Closed',
}

export const base16_themes = {
  bright: {
    scheme: 'bright',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#000000',
    base01: '#303030',
    base02: '#505050',
    base03: '#b0b0b0',
    base04: '#d0d0d0',
    base05: '#e0e0e0',
    base06: '#f5f5f5',
    base07: '#ffffff',
    base08: '#fb0120',
    base09: '#fc6d24',
    base0A: '#fda331',
    base0B: '#a1c659',
    base0C: '#76c7b7',
    base0D: '#6fb3d2',
    base0E: '#d381c3',
    base0F: '#be643c',
  },
};
