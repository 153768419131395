import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  List,
  ListItemButton,
  IconButton,
  ListItem,
  LinearProgress,
  CircularProgress,
  Snackbar,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { File } from '../components/FileBrowser/File';
import {
  FileUpload,
  Edit,
  CreateNewFolder,
  Delete,
  UploadFile,
} from '@mui/icons-material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Loading } from '../components/Loading';
import { Overlay } from '../components/FileBrowser/Overlay';
import React from 'react';

import { SFTPFile } from '@backend/types/FileType';

const API_PREFIX = '/api/v1/sftp';

export const FileBrowser = () => {
  const [files, setFiles] = useState([] as SFTPFile[]);
  const [sftpHost, setSftpHost] = useState('ga');
  const [path, setPath] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDialogText, setConfirmText] = useState('');
  const [confirmDialogTitle, setConfirmTitle] = useState('');
  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
  const [pathToDelete, setPathToDelete] = useState(
    {} as { path: string; type: string },
  );
  const [dialogAction, setDialogAction] = useState('');
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [dirName, setDirName] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [renameFileOpen, setRenameFileOpen] = useState(false);
  const [fileToRename, setFileToRename] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const listPath = () => {
    setLoading(true);
    axios
      .get(`${API_PREFIX}/${sftpHost}/list?path=${path.join('/')}`)
      .then((result) => {
        setFiles(result.data);
        setLoading(false);
      })
      .catch((err) => {
        setSnackMessage('Error occurred when listing path');
        setSnackOpen(true);
      });
  };

  useEffect(() => {
    listPath();
  }, [path]);

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const openDeleteDialog = (fileName, type) => {
    setConfirmTitle('Delete file or folder');
    const filePath = path.join('/') + `/${fileName}`;
    setPathToDelete({ path: filePath, type: type });
    setConfirmText(`Delete ${filePath}?`);
    setDialogAction('delete');
    setConfirmOpen(true);
  };

  const handleDialogAction = () => {
    switch (dialogAction) {
      case 'delete':
        deleteFile();
        break;
    }
    setDialogAction('');
    setConfirmOpen(false);
  };

  const deleteFile = () => {
    const uri =
      pathToDelete.type === 'd'
        ? `${API_PREFIX}/${sftpHost}/rmdir?path=${encodeURIComponent(
            pathToDelete.path,
          )}`
        : `${API_PREFIX}/${sftpHost}/?path=${encodeURIComponent(
            pathToDelete.path,
          )}`;

    axios
      .delete(uri)
      .then((result) => {
        setPathToDelete({ path: '', type: '' });
        listPath();
      })
      .catch((err) => {
        setSnackOpen(true);
        setSnackMessage(err.response.data);
      });
  };

  const handleNewFolderCreate = () => {
    if (dirName === '') return;
    const dir = path.join('/') + '/' + dirName;
    axios.post(`${API_PREFIX}/${sftpHost}/mkdir?path=${dir}`).then((result) => {
      setSnackMessage(`Folder ${dirName} created`);
      setSnackOpen(true);
      setNewFolderDialogOpen(false);
      listPath();
    });
  };

  const handleRename = () => {
    if (dirName === '' || fileToRename === '') return;
    const source = path.join('/') + '/' + fileToRename;
    const dest = path.join('/') + '/' + dirName;
    axios
      .put(`${API_PREFIX}/${sftpHost}/rename?path=${source}&dest=${dest}`)
      .then((result) => {
        setRenameFileOpen(false);
        listPath();
      })
      .catch((err) => {
        setSnackOpen(true);
        setSnackMessage(err.response.data);
      });
  };

  const handleFileClick = async (file) => {
    if (file.type === 'd') setPath(path.concat(file.name));
    else {
      try {
        const filePath = path.concat(file.name).join('/');
        setDownloading(true);
        const response = await axios({
          url: `${API_PREFIX}/${sftpHost}/download?path=${encodeURIComponent(
            filePath,
          )}`,
          method: 'GET',
          responseType: 'blob',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.data);
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloading(false);
      } catch (err) {
        setSnackMessage('Could not download the file');
        setSnackOpen(true);
        setDownloading(false);
      }
    }
  };

  const handleUpload = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;

    input.click();
    input.onchange = (_) => {
      setUploading(true);
      uploadFiles(input.files);
    };
  };

  const uploadFiles = (files) => {
    const requests: Promise<any>[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('', file, file.name);
      const filePath = path.join('/');
      requests.push(
        axios.post(
          `${API_PREFIX}/${sftpHost}/upload?path=${filePath}`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        ),
      );
    }

    axios
      .all(requests)
      .then((result) => {
        listPath();
        setUploading(false);
      })
      .catch((err) => {
        setSnackMessage('Failed to upload file(s)');
        setSnackOpen(true);
        setUploading(false);
      });
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return;
    setUploading(true);
    uploadFiles(e.dataTransfer.files);
    setDragActive(false);
  };

  if (!files) {
    return <Loading />;
  }

  return (
    <>
      {uploading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Box
        sx={{
          padding: '1rem',
          bgcolor: 'white',
          minHeight: '40vh',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          borderRadius: '5px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h6">
            Browse {sftpHost.toUpperCase()} SFTP
          </Typography>

          <Select
            onChange={async (e) => {
              setLoading(true);
              setSftpHost(e.target.value);
              setPath([]);
              setLoading(false);
            }}
            value={sftpHost}
          >
            <MenuItem value={'ga'}>Great America</MenuItem>
            <MenuItem value={'crb'}>Cross River Bank</MenuItem>
            <MenuItem value={'forbright'}>Forbright</MenuItem>
          </Select>
        </Box>
        <Typography variant="subtitle2" sx={{ color: 'darkred' }}>
          * Please be extra careful when deleting files. This action is not
          reversible.{' '}
        </Typography>
        <Box
          sx={{
            padding: '5px',
            border: '1px solid lightgrey',
            borderRadius: '5px',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Button onClick={() => setPath(path.slice(0, -1))}>{'<'}</Button>
              <Typography>/ {path.join(' / ')}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton onClick={() => setNewFolderDialogOpen(true)}>
                <CreateNewFolder />
              </IconButton>
              <IconButton onClick={handleUpload}>
                <FileUpload />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: '50vh',
            padding: '1rem',
            border: '1px solid lightblue',
            borderRadius: '5px',
            position: 'relative',
          }}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={(e) => setDragActive(false)}
          onDrop={handleDrop}
        >
          {dragActive && (
            <Overlay>
              <Typography variant="h4">Upload files</Typography>
              <UploadFile />
            </Overlay>
          )}

          {loading && (
            <Overlay>
              <CircularProgress />
            </Overlay>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'left',
              color: 'grey',
              borderBottom: '1px solid lightgrey',
              pl: '2rem',
              pr: '2rem',
            }}
          >
            <Typography variant="body2">Name</Typography>
            <Typography variant="body2">Date Modified</Typography>
          </Box>
          <List>
            {files.map((file, index) => {
              return (
                <ListItem key={index} sx={{ display: 'flex' }}>
                  <ListItemButton onClick={() => handleFileClick(file)}>
                    <File
                      type={file.type}
                      name={file.name}
                      time={file.modifyTime}
                      path={path.join('/')}
                    />
                  </ListItemButton>

                  <Box>
                    <IconButton
                      onClick={() => {
                        setFileToRename(file.name);
                        setRenameFileOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => openDeleteDialog(file.name, file.type)}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
        {downloading && (
          <Overlay text={`Downloading the file`}>
            <CircularProgress />
          </Overlay>
        )}
      </Box>

      {/* Confirmation Dialog Box */}
      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>Cancel</Button>
          <Button onClick={handleDialogAction} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={newFolderDialogOpen}
        onClose={() => setNewFolderDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Create a new folder'}
        </DialogTitle>
        <DialogContent>
          <DialogContent id="alert-dialog-description">
            <TextField
              label="Folder name"
              variant="outlined"
              onChange={(event) => setDirName(event.target.value)}
              autoFocus
            />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewFolderDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleNewFolderCreate} autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={renameFileOpen}
        onClose={() => setRenameFileOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Rename file or folder'}
        </DialogTitle>
        <DialogContent>
          <DialogContent id="alert-dialog-description">
            <TextField
              label="New name"
              variant="outlined"
              onChange={(event) => setDirName(event.target.value)}
              autoFocus
            />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFileOpen(false)}>Cancel</Button>
          <Button onClick={handleRename} autoFocus>
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackOpen}
        message={snackMessage}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
      />
    </>
  );
};
