import { Box, Typography } from '@mui/material';
import React from 'react';
export const ImageButton = (props) => {
  return (
    <Box onClick={props.onClick} className="image-button">
      <img src={props.image} />
      <Typography variant="button">{props.text}</Typography>
    </Box>
  );
};
