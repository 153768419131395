import { InsertDriveFile } from '@mui/icons-material';
import {
  Box,
  Chip,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { standardToShortDate } from '../../helpers/converters';

const API_GET_FILE = '/api/v1/loans/file';

// Format like "7/18/2023 10:40pm"
const dateFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

const formatDateTime = (date) => {
  return dateFormatter.format(date).split(',').join('');
};

export const OracleInsight = ({ oracleInsight: insight }) => {
  const [insightState, setInsightState] = useState(insight);

  const DatapointsTable = ({ oracleDataPoints: oracleDataPoints }) => (
    <Paper variant="outlined" sx={{ p: '1rem' }}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Page Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {oracleDataPoints?.map((sDataPoint, index) => (
              <TableRow key={index}>
                <TableCell>{sDataPoint.display_name}</TableCell>

                {sDataPoint.status === 'found' ? (
                  <TableCell>{sDataPoint.value} </TableCell>
                ) : (
                  <TableCell sx={{ color: 'lightcoral', fontStyle: 'italic' }}>
                    {sDataPoint.error_message}
                  </TableCell>
                )}

                <TableCell>
                  {sDataPoint.status === 'found' ? sDataPoint.page : '--'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <Paper variant="outlined" className="content">
      <Grid container spacing={1.75}>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant="h6"
              fontWeight={'bold'}
              color={'text.primary'}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {insightState.type}
            </Typography>
            {insightState.file_id ? (
              <Box display={'flex'} alignItems={'right'}>
                <InsertDriveFile
                  sx={{
                    fontSize: '17px',
                  }}
                />
                <Link
                  href={`${API_GET_FILE}/${insightState.file_id}`}
                  target={'_blank'}
                >
                  <Typography variant="body2" ml={1} color={'text.primary'}>
                    {insightState.file_name}
                  </Typography>
                </Link>
              </Box>
            ) : null}
          </Box>
          <Typography
            variant="body2"
            fontSize={12}
            color={'text.secondary'}
            mt={0.5}
          >
            Processed{' '}
            {insightState.processed_date ??
              standardToShortDate(new Date().toISOString())}
          </Typography>

          <Box display={'flex'} mt={2}>
            <Typography
              variant="body2"
              fontWeight={'bold'}
              color={'text.primary'}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Validations Run
            </Typography>
          </Box>
        </Grid>
        {insightState.output.validations?.map((validation, index) => {
          if (validation.result == 'error') {
            return (
              <>
                <Chip
                  key={index}
                  label={validation.message}
                  variant="filled"
                  sx={{
                    margin: '5px',
                    backgroundColor: '#e57373',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'darkRed',
                    },
                  }}
                />
              </>
            );
          } else if (validation.result == 'success') {
            return (
              <>
                <Chip
                  key={index}
                  label={validation.message}
                  variant="filled"
                  sx={{
                    margin: '5px',
                    backgroundColor: 'seaGreen',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'darkSeaGreen', // Darken the chip on hover for a visual effect
                    },
                  }}
                />
              </>
            );
          }
        })}
      </Grid>
      <Box display={'flex'} mt={2}>
        <Typography
          variant="body2"
          fontWeight={'bold'}
          color={'text.primary'}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Data Extracted
        </Typography>
      </Box>
      <DatapointsTable oracleDataPoints={insightState.output.output} />
    </Paper>
  );
};
