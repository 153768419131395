import { BankLoanActions } from '@backend/constants';
import {
  Add,
  CachedOutlined,
  Close,
  DeleteOutline,
  InsertDriveFile,
  ReplayOutlined,
  Send,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { JSONTree } from 'react-json-tree';
import ReactJsonViewCompare from 'react-json-view-compare';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { base16_themes } from '../../assets/const';
import { ActionMenu } from '../../components/BankLoan/ActionMenu';
import { BankLoanHistory } from '../../components/BankLoan/BankLoanHistory';
import { CRBLoanSummary } from '../../components/BankLoan/CRBLoanSummary';
import { Loading } from '../../components/Loading';
import { standardToShortDate, toSimpleDate } from '../../helpers/converters';
import { CombinedApp } from '../../layouts/LoanApplicationLayout';
import { ErrorPage } from '../ErrorPage';
import { LoanDetail } from './LoanDetail';

export const LoanApplication = () => {
  const app = useOutletContext() as CombinedApp;
  if (app.bank_error) {
    return (
      <ErrorPage
        title={app.bank_error.title}
        message={app.bank_error.message}
        status={app.bank_error.status ?? 400}
      />
    );
  }

  const CRB_ID_KEY = 'CRB_Application_ID__c';
  const ERROR_TITLE = 'Error ❌';
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState(<></>);
  const [dialogActions, setDialogActions] = useState(<></>);
  const [crbId, setCrbId] = useState(app[CRB_ID_KEY]);
  const [loading, setLoading] = useState(true);
  const [loanJSON, setLoanJSON] = useState(null);
  const [disableCreate, setDisableCreate] = useState(true);

  const [openConfirm, setOpenConfirm] = useState(false);

  const navigate = useNavigate();

  const reloadButton = (
    <Button
      onClick={() => {
        navigate('');
        setDialogOpen(false);
        setOpenConfirm(false);
      }}
      variant="dark"
    >
      Ok
    </Button>
  );

  const railwayHistory = app.railwayActions.map((record) => ({
    id: record.id,
    source: 'Railway',
    action: record.action,
    GUID: record.bank_loan_guid,
    date: standardToShortDate(record.date, true),
    data: record.loan_data,
  }));

  let crbStatusHistory: any[] = [];
  if (app.bankLoan?.StatusUpdates) {
    crbStatusHistory = app.bankLoan.StatusUpdates.map((update) => ({
      id: update.Id,
      source: 'CRB',
      action: update.Status,
      GUID: update.LoanId,
      date: toSimpleDate(update.TimeStamp, true),
      data: undefined,
    }));
  }

  if (app.childLoans?.length > 0) {
    for (const loc of app.childLoans) {
      for (const update of loc?.StatusUpdates) {
        crbStatusHistory.push({
          id: update.Id,
          source: 'CRB',
          action: update.Status,
          GUID: update.LoanId,
          date: toSimpleDate(update.TimeStamp, true),
          data: undefined,
        });
      }
    }
  }

  const cancelledLoans = app.railwayActions.filter(
    (record) => record.action === BankLoanActions.CANCELLED,
  );
  let cancelledStatuses: any[] = [];

  if (cancelledLoans.length > 0) {
    cancelledStatuses = cancelledLoans.flatMap((record) => {
      return record.loan_data.StatusUpdates.flatMap((update) => ({
        id: update.Id,
        source: 'CRB',
        action: update.Status,
        GUID: update.LoanId,
        date: toSimpleDate(update.TimeStamp, true),
        data: undefined,
      }));
    });
  }

  const bankLoanHistory = [
    ...railwayHistory,
    ...crbStatusHistory,
    ...cancelledStatuses,
  ];

  const openCreateLoanDialog = (onlyConfirm: boolean) => {
    setDialogTitle('Create CRB Bank Loan');
    if (onlyConfirm) {
      setDialogContent(<Typography>Send the loan to CRB?</Typography>);
    } else {
      setDialogContent(
        <Box className="json-wrapper">
          <JSONTree
            theme={base16_themes.bright}
            hideRoot={true}
            invertTheme={true}
            data={app}
          />
        </Box>,
      );
    }
    setDialogActions(
      <>
        <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
        <Button
          variant="contained"
          endIcon={<Send />}
          onClick={sendCreateLoanRequest}
        >
          Send
        </Button>
      </>,
    );
    setOpenConfirm(true);
  };

  const sendCreateLoanRequest = async () => {
    setDialogActions(<></>);
    setDialogTitle('Creating CRB Loan');
    setDialogContent(<Loading />);
    try {
      const crbLoan = await axios.post(`/api/v1/loans/create/${app.Id}`);
      setDialogTitle('CRB Loan Created');
      setDialogContent(
        <Box className="json-wrapper">
          <JSONTree
            theme={base16_themes.bright}
            hideRoot={true}
            invertTheme={true}
            data={crbLoan.data}
          />
        </Box>,
      );
      setCrbId(crbLoan.data.Id);
    } catch (err: any) {
      setDialogTitle(ERROR_TITLE);
      setDialogContent(
        <Typography>
          {err.response?.data?.message ??
            err.message ??
            'Failed to create the loan'}
        </Typography>,
      );
    }
    setDialogActions(reloadButton);
  };

  const sendAttachments = async () => {
    try {
      setDialogContent(<Loading />);
      await axios.post(`/api/v1/loans/attach-files/${app.Id}`);
      setDialogTitle('Success');
      setDialogContent(
        <Box className="content">
          <Typography variant="body1">
            {'Attachments added successfully ✅'}
          </Typography>
          <Box className="flex-row-end">{reloadButton}</Box>
        </Box>,
      );
    } catch (err: any) {
      setDialogContent(
        <>
          <Typography variant="body1">
            {err.response?.data?.message ?? 'Failed to attach files'}
          </Typography>
        </>,
      );
      setDialogTitle(ERROR_TITLE);
      setDialogOpen(true);
    }
  };

  const openUpdateLoanDialog = async () => {
    try {
      setDialogActions(<></>);
      setDialogTitle('Update Bank Loan');
      setDialogContent(<Loading />);
      setOpenConfirm(true);
      const crbLoan = await axios(`/api/v1/loans/${crbId}`);
      const originalLoan = crbLoan.data.OriginalLoanInfo;
      const updatedLoan = await axios(`/api/v1/loans/crb-loan/${app.Id}`);
      setDialogContent(
        <ReactJsonViewCompare
          oldData={originalLoan}
          newData={updatedLoan.data}
        />,
      );
      setDialogActions(
        <>
          <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
          <Button
            endIcon={<Send />}
            variant="outlined"
            onClick={sendLoanUpdate}
          >
            Send Update
          </Button>
        </>,
      );
    } catch (err: any) {
      setDialogTitle(ERROR_TITLE);
      setDialogContent(
        <Typography>
          {err.response?.data?.message ?? 'Failed to get loan info'}
        </Typography>,
      );
    }
  };

  const sendLoanUpdate = async () => {
    setDialogActions(<></>);
    setDialogTitle('Updating the loan');
    setDialogContent(<Loading />);
    try {
      const updatedLoan = await axios(`/api/v1/loans/crb-loan/${app.Id}`);
      await axios.put(
        `/api/v1/loans/update/${crbId}/${app.Id}`,
        updatedLoan.data,
      );
      setDialogTitle('Success');
      setDialogContent(<Typography>Loan updated successfully ✅</Typography>);
    } catch (err: any) {
      setDialogTitle(ERROR_TITLE);
      setDialogContent(
        <Typography>{err.message ?? 'Failed to send the update'}</Typography>,
      );
    }
    setDialogActions(reloadButton);
  };

  const handleAttachFiles = async () => {
    setDialogTitle('Attach CRB Files');
    setDialogContent(<Loading />);
    setDialogOpen(true);
    try {
      const docs = await axios.get(`/api/v1/loans/crb-files/${app.Id}`);
      setDialogContent(
        docs.data.length > 0 ? (
          <Box className="flex-column flex-gap-1">
            <Paper variant="outlined" sx={{ padding: '1em' }}>
              <ul>
                {docs.data.map((doc) => (
                  <li className="flex-row-start flex-gap-1" key={doc}>
                    <InsertDriveFile />
                    <Typography>{doc}</Typography>
                  </li>
                ))}
              </ul>
            </Paper>
            <Box className="flex-row-end">
              <Button
                variant="dark"
                endIcon={<InsertDriveFile />}
                onClick={sendAttachments}
              >
                Attach
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography>No CRB files found</Typography>
        ),
      );
    } catch (err: any) {
      setDialogTitle(ERROR_TITLE);
      setDialogContent(
        <Typography>{err.message ?? 'Failed to cancel the loan'}</Typography>,
      );
    }
  };

  const openCancelDialog = () => {
    setDialogTitle('Cancelling the Loan');
    setDialogContent(
      <Typography>Are you sure you want to cancel the loan?</Typography>,
    );
    setDialogActions(
      <>
        <Button color="error" onClick={sendLoanCancellation}>
          Yes
        </Button>
        <Button color="info" onClick={() => setOpenConfirm(false)}>
          No
        </Button>
      </>,
    );
    setOpenConfirm(true);
  };

  const openViewDetailDialog = (loanData, title) => {
    setDialogTitle(title);
    setDialogContent(
      <Box className="flex-column">
        <Box className="json-wrapper">
          <JSONTree
            theme={base16_themes.bright}
            hideRoot={true}
            invertTheme={true}
            data={loanData}
          />
        </Box>
      </Box>,
    );
    setDialogOpen(true);
  };

  const sendLoanCancellation = async () => {
    try {
      if (!crbId) return;
      setDialogActions(<></>);
      setDialogTitle('Cancelling the Loan');
      setDialogContent(<Loading />);
      await axios.put(`/api/v1/loans/cancel/${app.Id}/${crbId}`, app.bankLoan);
      setDialogTitle('Loan Canceled Successfully');
      setDialogContent(
        <Typography variant="body1">
          {`Loan ${crbId} was cancelled successfully`}
        </Typography>,
      );
      setCrbId(undefined);
    } catch (err: any) {
      setDialogTitle('Failed ❌');
      setDialogContent(
        <Typography>{err.message ?? 'Failed to cancel the loan'}</Typography>,
      );
    }
    setDialogActions(reloadButton);
  };

  const isActiveLoan = crbId;
  const isCancelledLoan = !crbId && app.railwayActions.length > 0;

  // Load the CRB loan in JSON
  useEffect(() => {
    axios(`/api/v1/loans/crb-loan/${app.Id}`)
      .then((result) => {
        setLoanJSON(result.data);
        setLoading(false);
        setDisableCreate(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoanJSON(err.response.data);
      });
  }, []);

  return (
    <Box className="flex-column">
      <Box className="flex-column">
        {isCancelledLoan && (
          <>
            {' '}
            <Box sx={{ p: '1rem' }}>
              <Alert
                severity="error"
                variant="outlined"
                sx={{ bgcolor: 'white' }}
              >
                This bank loan has been cancelled.
              </Alert>
            </Box>
            <Box className="flex-row-end" sx={{ p: '1rem' }}>
              {!crbId && (
                <ActionMenu
                  actions={[
                    {
                      function: () => openCreateLoanDialog(false),
                      label: 'Create loan',
                      icon: <ReplayOutlined />,
                    },
                  ]}
                />
              )}
            </Box>
            <Box className="content">
              <BankLoanHistory
                bankLoanHistory={bankLoanHistory}
                openViewDetailDialog={openViewDetailDialog}
                appId={app.Id}
                childLoans={app.childLoans}
              />
            </Box>
          </>
        )}

        {isActiveLoan && (
          <Box className="content">
            <Box className="flex-row-space-between">
              <Typography variant="h5">
                Current Bank Loan Information
              </Typography>
              <ActionMenu
                actions={[
                  {
                    function: handleAttachFiles,
                    icon: <InsertDriveFile />,
                    label: 'Attach CRB Files',
                  },
                  {
                    function: openUpdateLoanDialog,
                    icon: <CachedOutlined />,
                    label: 'Update Bank Loan',
                  },
                  {
                    function: openCancelDialog,
                    icon: <DeleteOutline />,
                    label: 'Cancel Loan',
                  },
                ]}
              />
            </Box>

            <CRBLoanSummary
              setDialogContent={setDialogContent}
              setDialogOpen={setDialogOpen}
              setDialogTitle={setDialogTitle}
              loanData={app.bankLoan}
            />

            <BankLoanHistory
              bankLoanHistory={bankLoanHistory}
              openViewDetailDialog={openViewDetailDialog}
              appId={app.Id}
              childLoans={app.childLoans}
            />

            <LoanDetail
              loanData={app.bankLoan}
              setDialogContent={setDialogContent}
              setDialogTitle={setDialogTitle}
              setDialogOpen={setDialogOpen}
            />
          </Box>
        )}

        {!crbId && app.railwayActions.length === 0 && (
          <Box className="content">
            {loading ? (
              <Loading />
            ) : (
              <>
                <Paper variant="outlined" className="content">
                  <Typography variant="h5">Create a CRB Bank Loan</Typography>
                  <Box
                    className="json-wrapper"
                    sx={{ maxHeight: '30rem', overflow: 'auto' }}
                  >
                    <JSONTree
                      theme={base16_themes.bright}
                      hideRoot={true}
                      invertTheme={true}
                      data={loanJSON}
                    />
                  </Box>
                </Paper>
                <Box className="flex-row-end">
                  <Button
                    disabled={disableCreate}
                    onClick={() => openCreateLoanDialog(true)}
                    variant="dark"
                    startIcon={<Add />}
                  >
                    Create Bank Loan
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>

      <Dialog
        open={dialogOpen}
        maxWidth={'md'}
        fullWidth={true}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>
          <Box className="flex-row-space-between">
            <Typography variant="h5">{dialogTitle}</Typography>
            <IconButton onClick={() => setDialogOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    </Box>
  );
};
