import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import React from 'react';

export const FileDisplay = () => {
  const { fileId } = useParams();
  return (
    <Box
      sx={{
        width: '100%',
        height: '99vh',
      }}
    >
      <embed
        src={`/api/v1/projects/doc-file/${fileId}`}
        width="100%"
        height="100%"
      ></embed>
    </Box>
  );
};
