import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { DataKeyVal } from './DataKeyVal';
import { SFLink } from './SFLink';

export const BankLoanSummary = ({ loanSummary, loanId }) => {
  const [expand, setExpand] = useState(true);
  return (
    <Box className="flex-column">
      <Collapse
        in={expand}
        collapsedSize={20}
        sx={{ borderBottom: '1px solid #D4D6D9' }}
      >
        <Box
          className="flex-row-space-around"
          sx={{ backgroundColor: '#F4F6F9' }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              minHeight: '5rem',
              justifyContent: 'start',
              pt: '1rem',
            }}
          >
            {loanSummary.map((data) => (
              <DataKeyVal
                key={data.key}
                label={data.label}
                value={data.value ?? '--'}
                button={data.label.toLowerCase().includes('status')}
              />
            ))}
          </Toolbar>
          <Box sx={{ p: '1rem' }}>
            <SFLink entityId={loanId} />
          </Box>
        </Box>
      </Collapse>
      <Box className="flex-row-space-around" sx={{ mt: '-0.8rem' }}>
        <IconButton
          sx={{
            bgcolor: 'white',
            p: '0',
            boxShadow: 'grey 0px 0px 4px',
          }}
          onClick={() => setExpand(!expand)}
        >
          {expand ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
    </Box>
  );
};
