import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react';
import { JSONTree } from 'react-json-tree';
import { base16_themes } from '../../assets/const';
import {
  extractBusinessName,
  toAgeInDays,
  toCurrency,
} from '../../helpers/converters';
import { DataKeyVal } from '../DataKeyVal';

export const CRBLoanSummary = ({
  loanData,
  setDialogContent,
  setDialogTitle,
  setDialogOpen,
}) => {
  const loanAge = toAgeInDays(loanData?.OriginalLoanInfo?.Date);
  let loanAgeColor = 'green';
  if (loanAge > 130 && loanAge < 160) loanAgeColor = 'orange';
  if (loanAge >= 160) loanAgeColor = 'red';

  // If there are loan updates, sort by Date in Descending order and grab the first one, otherwise OriginalLoanInfo
  const currentLoanInfo =
    loanData?.LoanInfoUpdates?.length > 0
      ? loanData?.LoanInfoUpdates?.sort((u1, u2) =>
          u1.UpdatedOn > u2.UpdatedOn
            ? -1
            : u1.UpdatedOn < u2.UpdatedOn
              ? 1
              : 0,
        )[0]
      : loanData?.OriginalLoanInfo;

  const handleJsonDialog = (jsonData: any, title: string) => {
    setDialogContent(
      <Box className="flex-column">
        <Box className="json-wrapper">
          <JSONTree
            theme={base16_themes.bright}
            hideRoot={true}
            invertTheme={true}
            data={jsonData}
          />
        </Box>
      </Box>,
    );
    setDialogTitle(title);
    setDialogOpen(true);
  };
  return (
    <Paper sx={{ p: '1rem' }} variant="outlined">
      <Typography variant="h6">Current Bank Loan Info (CRB)</Typography>
      <Box className="flex-row-space-between flex-gap-1">
        <DataKeyVal label="Loan Number" value={currentLoanInfo?.LoanNumber} />
        <DataKeyVal label="Bank Loan GUID" value={currentLoanInfo?.Id} />
        <DataKeyVal label="Bank Loan Type" value={currentLoanInfo?.LoanType} />
        <DataKeyVal
          label="Borrower"
          value={extractBusinessName(currentLoanInfo)}
        />
        <DataKeyVal
          label="Bank Loan Amount"
          value={toCurrency(currentLoanInfo?.LoanAmount)}
        />
        <DataKeyVal
          label="Net Funding"
          value={toCurrency(currentLoanInfo?.NetFunding)}
        />
        <DataKeyVal
          label="CRB Loan Age"
          value={`${loanAge} Days`}
          color={loanAgeColor}
        />
      </Box>
      <Box className="flex-row-end flex-gap-1">
        <Button
          variant="lightOutlined"
          color="warning"
          onClick={() =>
            handleJsonDialog(currentLoanInfo, 'Current Loan Detail')
          }
        >
          View Details
        </Button>
      </Box>
    </Paper>
  );
};
