import { toCurrency } from './converters';

const dnbReportSummary = (dnbReport) => {
  const dnbReportSummary =
    dnbReport?.report.organization.businessTrading[0].summary[0];

  return [
    {
      section: '📝 Summary',
      data: [
        {
          label: 'Paydex Score',
          value: dnbReportSummary?.paydexScore,
        },
        {
          label: 'Avg Owning Amount',
          value: toCurrency(dnbReportSummary?.averageOwingAmount),
        },
        {
          label: 'Max Owned Amount',
          value: toCurrency(dnbReportSummary?.maximumOwedAmount),
        },
      ],
    },
    {
      section: '⏳ Paydex History',
      data: dnbReportSummary?.paydexScoreHistory.map((paydex) => ({
        label: `As of ${paydex.asOfDate}`,
        value: paydex.paydexScore,
      })),
    },
  ];
};

const paynetReportSummary = (paynetReport) => {
  return [
    {
      section: '📝 Summary',
      data: [
        {
          label: 'Monthly Payment Amount',
          value: toCurrency(paynetReport?.monthly_payment_amt),
        },
      ],
    },
  ];
};

export const extractReportSummary = (reportType, report) => {
  switch (reportType) {
    case 'dnb':
      // const dnbReport = reports.find((report) => report.report_type === 'dnb');
      return dnbReportSummary(report);
    case 'paynet':
      return paynetReportSummary(report.report);

    default:
      return [];
  }
};

export const lastYear = () => String(new Date().getFullYear() - 1);
