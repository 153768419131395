import Accordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/system';
import React from 'react';

export const BetterAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters {...props} />
))(({ theme }) => ({
  '& .MuiCollapse-root': {
    transition: 'none !important',
  },
  '& .MuiAccordionSummary-root': {
    transition: 'none !important',
  },
  '& .MuiAccordionDetails-root': {
    transition: 'none !important',
  },
}));
