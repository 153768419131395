import {
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Typography,
  Link,
  Checkbox,
} from '@mui/material';
import {
  CheckCircleOutline,
  DeleteOutline,
  FileUpload,
  InsertDriveFile,
  RadioButtonUncheckedOutlined,
} from '@mui/icons-material';
import { defaultTheme } from '../../theme';
import { standardToShortDate } from '../../helpers/converters';
import { useCallback, useState } from 'react';
import React from 'react';
import { FileDeleteDialog } from '../FileDeleteDialog';

export const MilestoneFiles = ({
  milestoneId,
  document,
  handleUpload,
  deleteCallback,
  handleFileOpen,
  handleMakeFilePublic,
}) => {
  const ADDITIONAL_DOC = 'Additional Document';
  const handlePublicBoxClicked = useCallback(
    async (docId: string, fileId: number, isPublic: boolean) => {
      setFiles((oldFiles) => {
        const newFiles = oldFiles.map((file) => ({
          ...file,
          is_public:
            file.is_public && file.id === fileId ? false : file.id === fileId,
        }));
        return newFiles;
      });
      await handleMakeFilePublic(docId, fileId, isPublic);
    },
    [],
  );

  const [files, setFiles] = useState(document.files);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileData, setFileData] = useState({ docId: '', id: 0, name: '' });

  const handleFileDelete = (
    docId: string,
    fileId: number,
    fileName: string,
  ) => {
    setFileData({ docId, id: fileId, name: fileName });
    setDeleteDialogOpen(true);
  };

  return (
    <>
      <Box className="required-doc-box">
        <Box className="flex-row-start">
          <Typography variant="body2">{document.title}</Typography>
        </Box>
        <Box>
          {files.length === 0 && document.title !== ADDITIONAL_DOC ? (
            <Box className="no-doc-files">
              <Typography
                sx={{ pl: '2em', pb: '1em', pt: '1em' }}
                variant="body2"
                color="grey"
              >
                Choose a File
              </Typography>

              <Button
                color="neutral"
                variant="lightweight"
                size="small"
                startIcon={<FileUpload />}
                onClick={() => handleUpload(milestoneId, document.id)}
              >
                Upload
              </Button>
            </Box>
          ) : (
            files.map((file) => (
              <Box className="required-doc-file" key={file.id}>
                <Box className="flex-row-space-between">
                  <Box className="flex-row-start flex-gap-0_5">
                    <InsertDriveFile />
                    <Box sx={{ typography: 'body2' }}>
                      <Link
                        color="inherit"
                        component="button"
                        onClick={() => handleFileOpen(file.id)}
                      >
                        {file.name}
                      </Link>
                    </Box>
                  </Box>
                  <Box className="flex-row-end">
                    <Checkbox
                      color="success"
                      onChange={(e) =>
                        handlePublicBoxClicked(
                          document.id,
                          file.id,
                          e.target.checked,
                        )
                      }
                      icon={<RadioButtonUncheckedOutlined />}
                      checkedIcon={<CheckCircleOutline />}
                      checked={file.is_public}
                    />

                    <IconButton
                      onClick={() =>
                        handleFileDelete(document.id, file.id, file.name)
                      }
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                </Box>
                <Typography variant="caption" color="grey">
                  {standardToShortDate(file.created_at)}, {file.created_by}
                </Typography>
              </Box>
            ))
          )}
          {files.length > 0 && document.title !== ADDITIONAL_DOC && (
            <Button
              color="neutral"
              variant="lightweight"
              size="small"
              startIcon={<FileUpload />}
              onClick={() => handleUpload(milestoneId, document.id)}
            >
              Upload
            </Button>
          )}
        </Box>
      </Box>

      <FileDeleteDialog
        open={deleteDialogOpen}
        fileData={fileData}
        onClose={() => setDeleteDialogOpen(false)}
        callback={deleteCallback}
      />
    </>
  );
};
