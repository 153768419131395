import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';

interface UtilityEIA {
  utility_company: string;
  eia: number;
}
export const ACRCalculator = () => {
  const states = [
    'DE',
    'FL',
    'IL',
    'ME',
    'MD',
    'MN',
    'NJ',
    'NM',
    'PA',
    'VA',
    'DC',
    'WV',
    'WY',
  ];

  const [ntmProduction, setNtmProduction] = useState('');
  const [state, setState] = useState('');
  const [utilityEIAs, setUtilityEIAs] = useState([] as UtilityEIA[]);
  const [rateScheduleList, setRateScheduleList] = useState([] as any[]);
  const [EIACode, setEIACode] = useState('');
  const [rateSchedule, setRateSchedule] = useState('');

  const [ACRValue, setACRValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get('/api/v1/uw/acr-utility-eia')
      .then((result) => setUtilityEIAs(result.data))
      .catch((err) => console.error(err));
  }, []);

  const handleUtilitySelect = (e) => {
    setEIACode(e.target.value);
    axios
      .get(`/api/v1/uw/rate-schedule?eia=${e.target.value}`)
      .then((result) => {
        setRateScheduleList(() =>
          result.data.items
            .filter((item) => !item.enddate)
            .map((item) => item.name),
        );
      });
  };

  const callACRCalculator = () => {
    setLoading(true);
    axios
      .get(
        `/api/v1/uw/acr-value?ntmProd=${ntmProduction}&state=${state}&eia=${EIACode}&rateSchedule=${rateSchedule}`,
      )
      .then((result) => {
        setACRValue(`ACR: ${result.data.acr}`);
        setLoading(false);
      })
      .catch((err) => {
        setACRValue(err.message);
        setLoading(false);
      });
  };

  return (
    <Box className="content">
      <Typography variant="h5">One to One Express Solar Calculator</Typography>
      <Box
        sx={{
          background: loading ? '#fff' : 'inherit',
          pointerEvents: loading ? 'none' : 'inherit',
        }}
      >
        <Paper className="content" variant="outlined">
          <TextField
            variant="outlined"
            label="Expected Production (kWh)"
            value={ntmProduction}
            type="number"
            onChange={(e) => setNtmProduction(e.target.value)}
          />
          <FormControl>
            <InputLabel>State</InputLabel>
            <Select
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Utility Company</InputLabel>
            <Select
              label="Utility company"
              value={EIACode}
              onChange={handleUtilitySelect}
            >
              {utilityEIAs.map((utilityEIA) => (
                <MenuItem
                  key={utilityEIA.utility_company}
                  value={utilityEIA.eia}
                >
                  {utilityEIA.utility_company}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Rate Schedule</InputLabel>
            <Select
              label="Rate Schedule"
              value={rateSchedule}
              onChange={(e) => setRateSchedule(e.target.value)}
              disabled={rateScheduleList.length === 0}
            >
              {rateScheduleList.map((schedule) => (
                <MenuItem key={schedule} value={schedule}>
                  {schedule}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className="flex-row-end">
            <Button
              disabled={
                !ntmProduction || !state || !EIACode || !rateSchedule || loading
              }
              variant="contained"
              onClick={callACRCalculator}
            >
              Calculate ACR
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box>
        {loading ? (
          <Loading />
        ) : (
          ACRValue && (
            <Paper
              variant="outlined"
              className="content flex-row-space-between"
            >
              <Typography variant="h6">{ACRValue}</Typography>
            </Paper>
          )
        )}
      </Box>
    </Box>
  );
};
