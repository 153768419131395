import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { smallDateDiff } from '../../../helpers/helpers';
import { Dayjs } from 'dayjs';

export const AnnoucementPreview = ({
  title,
  body,
  link,
  linkText,
  startDate,
}: {
  title: string;
  body: string;
  link: string;
  linkText: string;
  startDate: Dayjs | null;
}) => {
  return (
    <>
      <Typography variant="h6">Announcement Preview</Typography>
      <Box
        sx={{ border: '1px solid black', padding: 1, width: 250 }}
        role="presentation"
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #DDD',
            padding: '10px',
          }}
        >
          {title && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: '700',
                  fontSize: '14px',
                  mt: 0.4,
                }}
              >
                {title}
              </Typography>
              {startDate && (
                <Typography
                  variant="body2"
                  sx={{
                    mt: 0.5,
                    fontFamily: 'Lato',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#999',
                    alignSelf: 'flex-end',
                  }}
                >
                  {smallDateDiff(startDate)}
                </Typography>
              )}
            </Box>
          )}
          <Typography variant="body2">{body}</Typography>
          {link && linkText && (
            <Link
              href={link}
              variant="body2"
              target="_blank"
              sx={{
                fontFamily: 'Lato',
                fontWeight: 700,
                fontSize: '14px',
                color: '#309c7c !important',
                mb: 2,
              }}
            >
              {linkText}
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
};
