import {
  Box,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { Suspense, useMemo, useState } from 'react';
import { Await, Link, defer, useLoaderData } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { ErrorPage } from './ErrorPage';
import { toCurrency } from '../helpers/converters';
import useDebouncedValue from '../hooks/useDebouncedValue';
import { Checklist, Search } from '@mui/icons-material';
import React from 'react';

export const fetchAllProjects = async () => {
  try {
    const projectList = await axios('/api/v1/projects/all');
    return defer({ data: projectList.data });
  } catch (err: any) {
    return {
      error: {
        title: 'Error Fetching Projects',
        message:
          err.response.data.message ??
          'An error occurred while trying to fetch all projects',
        status: err.response.status,
      },
    };
  }
};

export const FinOpsDashboard = () => {
  const projectList = useLoaderData() as any;

  if (projectList.error) {
    return (
      <ErrorPage
        title={projectList.error.title}
        message={projectList.error.message}
        status={projectList.error.status}
      />
    );
  }
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 500);

  const filteredProjects = useMemo(() => {
    if (!debouncedSearchQuery) {
      return projectList.data.records;
    }
    const query = debouncedSearchQuery.toLowerCase();
    return projectList.data.records.filter((project) => {
      return (
        project.Loan_App_Number__c?.toLowerCase()?.includes(query) ||
        project.Borrower_Business_Name__c?.toLowerCase()?.includes(query) ||
        project.Active_Milestone__c?.toLowerCase()?.includes(query) ||
        project.External_Status__c?.toLowerCase()?.includes(query)
      );
    });
  }, [debouncedSearchQuery, projectList.data?.records]);

  return (
    <div>
      <Box display="flex" justifyContent={'space-between'} mb={4}>
        <Typography
          variant="h2"
          sx={{
            fontSize: '1.5rem',
            fontFamily: 'Lato',
            fontWeight: 400,
          }}
        >
          Projects
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            bgcolor: 'white',
            minWidth: '40%',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          size="small"
        />
      </Box>

      <Suspense fallback={<Loading />}>
        <Await resolve={projectList.data} errorElement={<ErrorPage />}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: '5px',
            }}
            elevation={0}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Loan ID</TableCell>
                  <TableCell>Borrower Name</TableCell>
                  <TableCell>Net Funding</TableCell>
                  <TableCell>Received</TableCell>
                  <TableCell>Milestone in Progress</TableCell>
                  <TableCell>Project Status</TableCell>
                  <TableCell>Checklist</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProjects.map((project) => {
                  return (
                    <TableRow key={project.Id}>
                      <TableCell>{project.Loan_App_Number__c}</TableCell>
                      <TableCell>{project.Borrower_Business_Name__c}</TableCell>
                      <TableCell>
                        {toCurrency(project.Net_Funding__c)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(project.Paid_To_Date__c)}
                      </TableCell>
                      <TableCell>{project.Active_Milestone__c}</TableCell>
                      <TableCell>{project.External_Status__c}</TableCell>
                      <TableCell>
                        <Link
                          className="link"
                          to={'/project/' + project.Id + '/checklist'}
                        >
                          <Checklist />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Await>
      </Suspense>
    </div>
  );
};
