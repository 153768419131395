import { ExpandMoreOutlined } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

export const ActionMenu = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openActionMenu, setOpenActionMenu] = useState(false);

  return (
    <>
      <Button
        variant="dark"
        endIcon={<ExpandMoreOutlined />}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpenActionMenu(true);
        }}
        sx={{ minWidth: '15rem', justifyContent: 'space-between' }}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openActionMenu}
        className="wide-menu-1"
        onClose={() => setOpenActionMenu(false)}
      >
        {actions.map((action) => (
          <MenuItem onClick={action.function} key={action.label}>
            {action.icon} {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
