import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  UserGroup,
  isGroupsUser,
  isSftpUser,
  isUwUser,
} from '../../helpers/auth';
import { Tab } from './Tab';
import './navStyle.css';

export const NavBar = () => {
  const uri = useLocation();
  const path = uri.pathname;
  const homePath = '/';
  const loansPath = '/loans';
  const sftp = '/files';
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        color: '#555',
      }}
    >
      {isGroupsUser([
        UserGroup.LOAN_OPS,
        UserGroup.UW,
        UserGroup.LOAN_OPS_UW,
        UserGroup.BIZ_DEV,
      ]) && (
        <Tab name="Loans" dest={loansPath} active={path.includes('/loan')} />
      )}

      {isSftpUser() && (
        <Tab
          name="SFTP File Browser"
          dest={sftp}
          active={path.includes(sftp)}
        />
      )}

      {isGroupsUser([UserGroup.FIN_OPS, UserGroup.UW]) && (
        <Tab
          name="Funded Projects"
          dest="/funded-projects"
          active={path.includes('/funded-projects')}
        />
      )}
      {isUwUser() && (
        <Tab
          name="Data Uploaders"
          dest="/underwriting/data-tools"
          active={path.includes('/underwriting/data-tools')}
        />
      )}
      {isGroupsUser([
        UserGroup.UW,
        UserGroup.LOAN_OPS_UW,
        UserGroup.BIZ_DEV,
      ]) && (
        <Tab
          name="Tools"
          dest="/underwriting/tools/acr-calculator"
          active={path.includes('/underwriting/tools')}
        />
      )}
      {/* <MenuTab
        name="Underwriting"
        menuId="underwriting-menu"
        active={path.includes('/underwriting')}
        items={[
          {
            id: 1,
            dest: '/underwriting/data-tools',
            label: 'Data tools',
          },
          {
            id: 2,
            dest: '/underwriting/app-list',
            label: 'Reports',
          },
        ]}
      /> */}
    </Box>
  );
};
