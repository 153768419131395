import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  Button,
} from '@mui/material'
import { ENVS, ENDPOINTS, STATUS_CODES } from '../../../helpers/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { CodeBlock } from './CodeBlock';
import axios from 'axios';
import { UserType } from '../../../types/UserTypes';



interface Request {
  url: string;
  method: string;
  headers: Record<string, string>;
  body?: string;
}

interface Response {
  status?: number;
  headers?: Record<string, any>;
  data?: any;
  error?: string;
}

export const SunstoneAPITester = () => {

  const isLocalOrStaging = window.location.href.includes('localhost:1234') || 
                           window.location.href.includes('railway.ssc-internal-staging.com');

  const [env, setEnv] = useState(isLocalOrStaging ? ENVS[0].name : ENVS[1].name)
  const [secretId, setSecretId] = useState(isLocalOrStaging ? ENVS[0].secretId : ENVS[1].secretId)
  const [response, setResponse] = useState<Response | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [domain, setDomain] = useState(isLocalOrStaging ? ENVS[0].url : ENVS[1].url)
  const [endpoint, setEndpoint] = useState(ENDPOINTS.CALCULATE_PAYMENTS)
  const [method, setMethod] = useState(ENDPOINTS.CALCULATE_PAYMENTS.method)
  const [keyValuePairs, setKeyValuePairs] = useState([{ key: '', value: '' }])
  const [apiKey, setApiKey] = useState<string>('')
  const [tabValue, setTabValue] = useState('request')
  const [allUserTypes, setAllUserTypes] = useState<UserType[]>([]);
  const [selectedUserType, setSelectedUserType] = useState<string>('')
  const [request, setRequest] = useState<Request>({
    url: '',
    method: '',
    headers: {},
    body: undefined,
  });
  const [displayRequest, setDisplayRequest] = useState<Request>({
    url: '',
    method: '',
    headers: {},
    body: undefined,
  });

  const keyRefs = useRef<Array<HTMLInputElement | null>>([]); // This will manage making the newly added key-value pair input active automatically
  const [focusLastInput, setFocusLastInput] = useState(false);

  const handleEnvChange = (e) => {
    setEnv(e.target.value);
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleEndpointChange = (e) => {
    const selectedEndpoint = ENDPOINTS[e.target.value as keyof typeof ENDPOINTS];
    setEndpoint(selectedEndpoint);
    setMethod(selectedEndpoint.method)
  }

  const handleUserTypeChange = (e) => {
    const selectedUser = allUserTypes.find(user => user.name === e.target.value);
    setSelectedUserType(selectedUser?.name || '');
    setApiKey(selectedUser?.key || '');
  };

  const addKeyValuePair = () => {
    setKeyValuePairs([...keyValuePairs, { key: '', value: '' }])
    setFocusLastInput(true);
  }

  const removeKeyValuePair = (index) => {
    setKeyValuePairs(keyValuePairs.filter((_, i) => i !== index))
    keyRefs.current.splice(index, 1);
  }

  const updateKeyValuePair = (index, field, value) => {
    const updatedPairs = [...keyValuePairs]
    updatedPairs[index][field] = value
    setKeyValuePairs(updatedPairs)
  }

  const fetchUserTypes = async (secretId: string) => {
    try {
      const response = await axios.get(`/api/v1/external/get-secret-value?secretId=${secretId}`)
      const data = await response.data;
      setAllUserTypes(data);
      if (data.length > 0) {
        setSelectedUserType(data[0].name);
        setApiKey(data[0].key);
      }
    } catch (error) {
      console.error('Error fetching user types:', error);
    }
  };

  useEffect(() => {
    fetchUserTypes(secretId);
  }, [secretId]);

  const constructRequest = () => {
    const requestBody = keyValuePairs.reduce((acc, { key, value }) => {
      if (key) {
        const numberValue = parseFloat(value);
        if (!isNaN(numberValue) && isFinite(numberValue)) {
          acc[key] = numberValue;
        } else if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
          acc[key] = value.toLowerCase() === 'true';
        } else {
          acc[key] = value;
        }
      }
      return acc;
    }, {})

    const constructedRequest: Request = {
      url: `${domain}${endpoint.endpoint}`,
      method,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey ?? '',
        'X-User-Type': selectedUserType,
        'X-Environment': env,
      },
      body: method !== 'GET' ? JSON.stringify(requestBody) : undefined,
    };

    // If the request method is GET, we convert the key value pairs to query params and add them to the url
    if (method === 'GET' && Object.keys(requestBody).length > 0) {
      const queryParams = new URLSearchParams(requestBody).toString();
      constructedRequest.url += `?${queryParams}`;
    }

    return constructedRequest;
  };

  const constructDisplayRequest = (fullRequest) => ({
    ...fullRequest,
    headers: {
      ...fullRequest.headers,
      'x-api-key': apiKey ? `${'*'.repeat(apiKey.length - 4)}${apiKey.slice(-4)}` : '*******',
    },
    body: fullRequest.body ? JSON.parse(fullRequest.body) : undefined,
  });

  useEffect(() => {
    const newRequest = constructRequest()
    setRequest(newRequest)
    const newDisplayRequest = constructDisplayRequest(newRequest);
    setDisplayRequest(newDisplayRequest)
  }, [domain, endpoint, method, keyValuePairs, apiKey, selectedUserType, env]);

  const sendRequest = useCallback(async () => {

    setIsLoading(true)    
    setTabValue('response')
    try {
      const { url, method, headers, body } = request;
      const res = await axios({
        url,
        method,
        headers,
        data: body ? JSON.parse(body) : undefined,
      });
      setResponse({
        status: res.status,
        headers: res.headers,
        data: res.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setResponse({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
          error: error.message,
        });
      } else {
        setResponse({ error: (error as Error).message });
      }
    } finally {
      setIsLoading(false)
      
    }
  }, [request])
  

  useEffect(() => {
    const newEnv = ENVS.find(selectedEnv => selectedEnv.name === env)
    if (newEnv) {
      setDomain(newEnv.url);
      setSecretId(newEnv.secretId);
    }
  }, [env])

  useEffect(() => {
      setMethod(endpoint.method) 
  }, [endpoint])

  useEffect(() => {
    if (focusLastInput) {
      const lastIndex = keyValuePairs.length - 1;
      keyRefs.current[lastIndex]?.focus();
      setFocusLastInput(false);
    }
  }, [keyValuePairs, focusLastInput]);

  
  return (
    <>
      <Grid container
        height={.9}
        sx={{
          backgroundColor: '#fff',
          height: 1
        }}
      >
        <Grid
          item
          px={'1rem'}
          xs={12}
          maxHeight={1}
          height={1}
        >
          <Box
            sx={{ px: 4, py: 5, border: 'none', width: 1, height: 1}}
          >
            <Typography 
              variant="h5"

            >
              Sunstone API Tester
            </Typography>

            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                gap: 1
              }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: 'flex',
                  flexDirection: {xs: 'column', md: 'row'},
                  gap: 1
                }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    xs={12}
                  >
                    <Typography 
                      sx={{
                        fontWeight: 'bold',
                        color: '#5f6772',
                        fontSize: '1.2rem',
                        py: 2,
                      }}
                    >
                      Select Endpoint
                    </Typography>
                    <Select
                      labelId="endpoint-select-label"
                      id="endpoint-select"
                      value={Object.keys(ENDPOINTS).find(key => ENDPOINTS[key] === endpoint) || ''}
                      onChange={(e) => handleEndpointChange(e)}
                      placeholder='Select an endpoint'
                      sx={{
                        width: 1,
                        borderRadius: '10px',
                        backgroundColor: '#fff',
                      }}
                    >
                      {Object.keys(ENDPOINTS).map((key) => (
                        <MenuItem key={key} value={key}
                          sx={{
                          }}
                        >
                          {ENDPOINTS[key].name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  display: 'flex',
                  flexDirection: {xs: 'column', md: 'row'},
                  gap: 1
                }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    xs={12}
                    md={6}
                  >
                    <Typography 
                      sx={{
                        fontWeight: 'bold',
                        color: '#5f6772',
                        fontSize: '1.2rem',
                        py: 2,
                      }}
                    >
                      User Type
                    </Typography>
                    <Select
                      labelId="user-type-select-label"
                      id="user-type-select"
                      value={selectedUserType}
                      onChange={(e) => handleUserTypeChange(e)}
                      placeholder='Select a User Type'
                      sx={{
                        width: 1,
                        borderRadius: '10px',
                        backgroundColor: '#fff',
                      }}
                    >
                      {allUserTypes.map((user) => (
                        <MenuItem key={user.name} value={user.name}
                          sx={{
                          }}
                        >
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    xs={12}
                    md={6}
                  >
                    <Typography 
                      sx={{
                        fontWeight: 'bold',
                        color: '#5f6772',
                        fontSize: '1.2rem',
                        py: 2,
                      }}
                    >
                      Environment
                    </Typography>
                    <Select
                      labelId="environment-select-label"
                      id="environment-select"
                      value={env}
                      onChange={(e) => handleEnvChange(e)}
                      placeholder='Select the environment'
                      sx={{
                        width: 1,
                        borderRadius: '10px',
                        backgroundColor: '#fff',
                      }}
                    >
                      {ENVS.map((environ) => (
                        <MenuItem key={environ.name} value={environ.name}
                          sx={{
                          }}
                        >
                          {environ.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
              </Grid>
            </Grid>

            <Typography 
              sx={{
                fontWeight: 'bold',
                color: '#5f6772',
                fontSize: '1.2rem',
                py: 2,
              }}
            >
              {method !== 'GET' ? 'Request Body' : 'Query Params'}
            </Typography>
            <Box>
              {keyValuePairs.map((pair, index) => (
                <Grid item xs={12} key={index} display={'flex'} gap={1} marginBottom={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      inputRef={(el) => (keyRefs.current[index] = el)}
                      placeholder="Key"
                      value={pair.key}
                      onChange={(e) => updateKeyValuePair(index, 'key', e.target.value)}
                      sx={{
                        width: 1,
                        backgroundColor: '#fff',
                        '& label.Mui-focused': {
                          color: 'black',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          }
                        }
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      placeholder="Value"
                      value={pair.value}
                      onChange={(e) => updateKeyValuePair(index, 'value', e.target.value)}
                      sx={{
                        width: 1,
                        backgroundColor: '#fff',
                        '& label.Mui-focused': {
                          color: 'black',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          }
                        }
                      }}
                      />
                    </Grid>
                  <Button
                    variant="outlined"
                    color='error'
                    onClick={() => removeKeyValuePair(index)}
                    aria-label="Remove key-value pair"
                    sx={{
                      backgroundColor: '#fff',
                      color: 'black',
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      '&:focus': {
                        backgroundColor: 'rgba(210, 25, 36, 0.04)',
                        border: '1px solid #D21924',
                      },
                    }}
                  >
                    <DeleteIcon  />
                  </Button>
                </Grid>
              ))}
              <Button onClick={addKeyValuePair} variant="outlined" color='primary'
                sx={{
                  color: 'black',
                  borderColor: 'black',
                  width: 1,
                  height: '3.5rem',
                  marginTop: 1,
                  '&:focus': {
                    backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    border: '1px solid #1976d2',
                  },
                }}
                aria-label="Add key-value pair"
              >
                <AddIcon  /> Add Key-Value Pair
              </Button>
            </Box>

            <Button
              variant='contained'
              sx={{
                height: '3rem',
                marginTop: 3,
              }}
              color='dark'
              type='submit'
              onClick={sendRequest}
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Send Request'}
            </Button>
            <Box
              sx={{
                padding: '1rem .5rem .5rem .5rem',    
                backgroundColor: '#fff',  
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="Request" value={'request'} />
                  <Tab label="Response" value={'response'} />
                </Tabs>

                <Box
                  sx={{
                    display: 'flex',
                    alignItens: 'center',
                  }}
                >
                  <Typography
                    fontSize={'14px'}
                    
                  >
                    Status: 
                      <Typography
                        component='span'
                        fontWeight={700}
                        sx={{
                          color: response?.status === 200 ? '#00d974' : '#ff003c'
                        }}
                      > {response?.status
                        ? `${response.status} ${STATUS_CODES[response.status]}`
                        : '-'}
                      </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
            {tabValue === 'request' && (
                <CodeBlock 
                  data={displayRequest ? JSON.stringify(displayRequest, null, 2) : ''}
                />
              )}
            {tabValue === 'response' && (
              <CodeBlock 
                data={response ? JSON.stringify(response, null, 2) : ''}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

