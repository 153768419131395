import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import {
  ANNOUNCEMENT_MAX_BODY_LENGTH,
  LIVE_ANNOUNCEMENTS_LIMIT,
  UserGroups,
} from '../../../helpers/constants';
import { Announcement } from '../../../types/Announcement';
import { AnnoucementPreview } from './AnnouncementPreview';
import { initialErrors, validateAnnouncement } from './CreateAnnouncement';

interface EditAnnouncementProps {
  announcement: Announcement;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

export const EditAnnouncement: React.FC<EditAnnouncementProps> = ({
  announcement,
  setValue,
}) => {
  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [linkText, setLinkText] = useState('');
  const [link, setLink] = useState('');
  const [startDate, setStartDate] = useState<any | null>(null);
  const [endDate, setEndDate] = useState<any | null>(null);
  const [targetUserGroup, setTargetUserGroup] = useState<string | null>(
    UserGroups.INSTALLER,
  );
  const [isActive, setIsActive] = useState(false);
  const [clearHistory, setClearHistory] = useState(false);
  const [saving, setSaving] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [announcementCount, setAnnouncementCount] = useState('...');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState(initialErrors());

  useEffect(() => {
    setId(announcement.id);
    setTitle(announcement.title ?? '');
    setBody(announcement.body ?? '');
    setLinkText(announcement.linkText ?? '');
    setLink(announcement.link ?? '');
    setStartDate(dayjs(announcement.startDate));
    setEndDate(dayjs(announcement.endDate));
    setTargetUserGroup(announcement.targetUserGroup);
    setIsActive(announcement.isActive);
    setClearHistory(true);

    axios
      .get('/api/v1/internal/get-live-announcements')
      .then((res) => setAnnouncementCount(res.data.length));
  }, []);

  const updateAnnouncement = async () => {
    await setErrors(initialErrors());

    const errs = await validateAnnouncement(
      id,
      body,
      startDate,
      endDate,
      linkText,
      link,
      isActive,
    );

    await setErrors(errs);

    if (errs.isError) {
      return;
    }

    try {
      setSaving(true);
      setMessage('Updating announcement...');

      const query = {
        id: id,
        body: body,
        title: title,
        linkText: linkText,
        link: link,
        targetUserGroup: targetUserGroup,
        isActive: isActive,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        clearHistory: clearHistory,
      };

      await axios.put('/api/v1/internal/update-announcement', query);

      setMessage('Announcement updated successfully.');
    } catch (err: any) {
      setErrors({ ...errors, general: 'Update announcement failed.' });
    } finally {
      setSaving(false);
      setValue(0);
    }
  };

  const deleteAnnouncement = async () => {
    try {
      setSaving(true);
      setMessage('Deleting announcement...');
      setErrors(initialErrors());

      await axios.delete('/api/v1/internal/delete-announcement', {
        data: { id: id },
      });

      setMessage('Announcement deleted successfully.');
      setValue(0);
    } catch (err: any) {
      setErrors({ ...errors, general: 'Delete announcement failed.' });
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {(message || errors.general) && (
        <Box className="content">
          {message && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ color: 'green' }}>{message}</Typography>
            </Box>
          )}
          {errors.general && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ color: 'red' }}>{errors.general}</Typography>
            </Box>
          )}
        </Box>
      )}
      <Box className="content" sx={{ padding: 0 }}>
        <Typography variant="h5">
          Edit Announcement (Id: {id}) (Live: {announcementCount}/
          {LIVE_ANNOUNCEMENTS_LIMIT})
        </Typography>
        <Box>
          <Paper className="content" variant="outlined">
            <TextField
              variant="outlined"
              label="Title"
              value={title}
              type="string"
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              variant="outlined"
              label={`Body (required, length: ${body.length}/${ANNOUNCEMENT_MAX_BODY_LENGTH})`}
              value={body}
              type="string"
              onChange={(e) => {
                const newBody = e.target.value;
                if (newBody.length > ANNOUNCEMENT_MAX_BODY_LENGTH) {
                  errors.body = `Body must be ${ANNOUNCEMENT_MAX_BODY_LENGTH} characters or less.`;
                  setErrors(errors);
                } else {
                  if (errors.body) {
                    errors.body = '';
                    setErrors(errors);
                  }
                }
                setBody(newBody);
              }}
              error={errors.body !== ''}
              helperText={errors.body}
            />
            <TextField
              variant="outlined"
              label="Link Text"
              value={linkText}
              type="string"
              onChange={(e) => setLinkText(e.target.value)}
              error={errors.linkText !== ''}
              helperText={errors.linkText}
            />
            <TextField
              variant="outlined"
              label="Link"
              value={link}
              type="string"
              onChange={(e) => setLink(e.target.value)}
              error={errors.link !== ''}
              helperText={errors.link}
            />
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start date (required)"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  sx={{ width: '200px', mr: '20px' }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End date (required)"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  sx={{ width: '200px' }}
                />
              </LocalizationProvider>
            </Box>
            {errors.startDate && (
              <Typography sx={{ color: 'red' }}>{errors.startDate}</Typography>
            )}
            {errors.endDate && (
              <Typography sx={{ color: 'red' }}>{errors.endDate}</Typography>
            )}
            <InputLabel id="user-group-label">Target User Group</InputLabel>
            <Select
              labelId="user-group-label"
              id="user-group-select"
              value={targetUserGroup}
              label="Target User Group"
              onChange={(event) => setTargetUserGroup(event.target.value)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '300px',
              }}
            >
              {Object.values(UserGroups).map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                    name="activeCheckbox"
                    color="primary"
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clearHistory}
                    onChange={(event) => setClearHistory(event.target.checked)}
                    name="clearHistoryCheckbox"
                    color="primary"
                  />
                }
                label="Make announcement unviewed"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                onClick={updateAnnouncement}
                disabled={saving}
                sx={{ mr: '30px' }}
              >
                Update Announcement
              </Button>
              <Button
                variant="contained"
                onClick={() => setConfirmationOpen(true)}
                disabled={saving}
              >
                Delete Announcement
              </Button>
              <ConfirmationDialog
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={deleteAnnouncement}
                title={`Delete Announcement ${id}`}
                description="Do you really want to delete this announcement? This action cannot be undone."
              />
            </Box>
            <AnnoucementPreview
              title={title}
              body={body}
              link={link}
              linkText={linkText}
              startDate={startDate}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};
