import { SFLoanApplication } from '@backend/types/Loan';
import { Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ScoreDictionary } from '../../../../backend/src/services/scoring';
import { Loading } from '../../components/Loading';
import { AnalyzeLoan } from './pre-screen/AnalyzeLoan';
import { BlockingIssues } from './pre-screen/BlockingIssues';
import { LoanAnalysisItems } from './pre-screen/LoanAnalysisItems';

export const PreScreen = () => {
  const [blockingIssues, setBlockingIssues] = useState<Record<string, any>[]>(
    [],
  );
  const [loanAnalysis, setLoanAnalysis] = useState<Record<string, any>[]>([]);
  const [allGuarantors, setAllGuarantors] = useState<Record<string, any>[]>([]);
  const [scores, setScores] = useState({} as ScoreDictionary);
  const [lowQualityItems, setLowQualityItems] = useState<Record<string, any>[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [automationErrors, setAutomationErrors] = useState([]);

  const [guarantor, setGuarantor] = useState();
  const [annualDebtObligation, setAnnualDebtObligations] = useState(0);

  const app = useOutletContext() as SFLoanApplication;
  const appId = app.Id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getLoanAnalysis();
        setLoading(false);
      } catch (err: any) {
        setError('message' in err ? err.message : err);
        setLoading(false);
      }
    };

    setLoading(true);
    fetchData();
  }, []);

  const getLoanAnalysis = async () => {
    const loanAnalysisResponse = await axios.get(
      '/api/v1/uw/analyzed-items/' + appId,
    );

    if (loanAnalysisResponse.data.analysis === null) {
      const dataPointsResponse = await axios.get(
        '/api/v1/uw/blocking-issues/' + appId,
      );
      setBlockingIssues(dataPointsResponse.data);
      setLoading(false);
      return;
    }

    setLoanAnalysis(loanAnalysisResponse.data.analysis.items);
    setScores(loanAnalysisResponse.data.scores);
    setAllGuarantors(loanAnalysisResponse.data.allGuarantors);
    setLowQualityItems(loanAnalysisResponse.data.lowQualityItems);

    setGuarantor(loanAnalysisResponse.data.guarantor);
    if (loanAnalysisResponse.data.loanObligations.length > 0) {
      let totalAnnualObligation = 0;
      for (const loan of loanAnalysisResponse.data.loanObligations) {
        totalAnnualObligation += loan.Loan_Monthly_Payment__c * 12;
      }
      setAnnualDebtObligations(totalAnnualObligation);
    }

    if (loanAnalysisResponse.data.analysis.errors.length > 0) {
      console.error('Errors', loanAnalysisResponse.data.analysis.errors);
    }
    setAutomationErrors(loanAnalysisResponse.data.analysis.errors);
  };

  const getComponent = () => {
    let component;
    if (loading) {
      component = <Loading />;
    } else if (loanAnalysis && loanAnalysis.length > 0) {
      component = (
        <LoanAnalysisItems
          loanAnalysis={loanAnalysis}
          getLoanAnalysis={getLoanAnalysis}
          app={app}
          guarantor={guarantor}
          annualDebtObligation={annualDebtObligation}
          showScores={
            app.Is_Personal_Guarantor__c &&
            app.Is_Personal_Guarantor__c === 'Yes'
          }
          automationErrors={automationErrors}
          scores={scores}
          lowQualityItems={lowQualityItems}
          allGuarantors={allGuarantors}
        />
      );
    } else if (blockingIssues.length === 0) {
      component = (
        <AnalyzeLoan
          loading={loading}
          analyzeLoan={analyzeLoan}
          error={error}
          app={app}
        />
      );
    } else {
      component = <BlockingIssues blockingIssues={blockingIssues} />;
    }
    return component;
  };

  const analyzeLoan = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/v1/uw/analyze-loan/' + appId);
      setLoanAnalysis(response.data.analysis.items);

      if (response.data.errors.length > 0) {
        console.error('Errors', response.data.errors);
      }
      setAutomationErrors(response.data.errors);
      setLoading(false);
    } catch (err: any) {
      setError(err.response?.data?.message || err.message);
      setLoading(false);
    }
  };

  return (
    <Box className="flex-column">
      <Box className="flex-column">
        <Box className="flex-column flex-gap-1" sx={{ p: '3rem' }}>
          {getComponent()}
        </Box>
      </Box>
    </Box>
  );
};
