import {
  Box,
  Button,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slider,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { stateCodes } from '../../assets/const';
import { DownloadForOfflineRounded } from '@mui/icons-material';
import React from 'react';

export const PaynetSearch = ({ app, onSaveReport }) => {
  const searchPaynet = async () => {
    if (
      company_name === '' ||
      !company_name ||
      state_code === '' ||
      !state_code
    ) {
      setSnackMessage('Company name and state codes are required');
      setSnackOpen(true);
      return;
    }
    const query = {
      company_name,
      state_code,
      address,
      city,
      phone,
      tax_id,
      name_match_threshold: threshold,
    };
    try {
      const result = await axios.post('/api/v1/uw/search/paynet', query);
      return result.data;
    } catch (err: any) {
      setSnackMessage(err.message ?? 'Failed to search the company');
      setSnackOpen(true);
    }
  };

  const [company_name, setName] = useState(app.Borrower_Business_Name__c ?? '');
  const [address, setAddress] = useState(app.Business_Address__c ?? '');
  const [city, setCity] = useState(app.Business_Address_City__c ?? '');
  const [state_code, setStateCode] = useState(
    stateCodes[app.Business_Address_State__c?.toUpperCase()] ?? '',
  );
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('State code is required');
  const [phone, setPhone] = useState(app.Business_Phone_Number__c ?? '');
  const [tax_id, setTin] = useState(app.TIN__c ?? '');
  const [threshold, setThreshold] = useState(85);
  const [searchResult, setSearchResult] = useState([] as any[]);

  const [searching, setSearching] = useState(false);

  const handleSearch = async () => {
    setSearching(true);
    const result = await searchPaynet();
    setSearchResult(result);
    setSearching(false);
  };

  const saveReport = async (paynetId, callback) => {
    const report = await axios.post(
      `/api/v1/uw/report/${app.Id}/paynet/${paynetId}`,
    );
    await callback();
  };

  return (
    <>
      <Paper variant="outlined" className="content">
        <Typography variant="h5">
          {app.Name} : {company_name}
        </Typography>
        <Typography variant="h6">Business Detail</Typography>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business Name"
              value={company_name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Select
              value={state_code}
              onChange={(e) => setStateCode(e.target.value)}
            >
              {Object.keys(stateCodes).map((state) => {
                return (
                  <MenuItem key={stateCodes[state]} value={stateCodes[state]}>
                    {state}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              label="Business TIN"
              value={tax_id}
              onChange={(e) => setTin(e.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography>Name Match Threshold : {threshold}%</Typography>
            <Slider
              value={threshold}
              step={5}
              onChange={(event, newValue) => setThreshold(newValue as number)}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
        {searching && <LinearProgress />}
        {searchResult.length > 0 && (
          <>
            <Typography variant="h6">Matched Businesses</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>TIN</TableCell>
                  <TableCell>Street</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State Code</TableCell>
                  <TableCell>Postal Code</TableCell>
                  <TableCell>Match Score</TableCell>
                  <TableCell>Pull Report</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResult.map((company) => {
                  return (
                    <TableRow key={company.paynet_id}>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{company.tax_id}</TableCell>
                      <TableCell>{company.street}</TableCell>
                      <TableCell>{company.city}</TableCell>
                      <TableCell>{company.state_code}</TableCell>
                      <TableCell>{company.postal_code}</TableCell>
                      <TableCell>{company.match_score}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          startIcon={<DownloadForOfflineRounded />}
                          color="info"
                          onClick={() =>
                            saveReport(company.paynet_id, onSaveReport)
                          }
                        >
                          Pull
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </Paper>

      <Snackbar
        open={snackOpen}
        message={snackMessage}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
      />
    </>
  );
};
