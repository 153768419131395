import { ActionType, BankLoanActions } from '@backend/constants';
import {
  AttachFile,
  Cancel,
  ExpandMore,
  FileCopy,
  InsertDriveFile,
  Update,
} from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import ReactJsonViewCompare from 'react-json-view-compare';
import { useNavigate } from 'react-router-dom';
import { BetterAccordion } from '../BetterAccordion';
import ConfirmationModal from '../ConfirmationModal';
import { Loading } from '../Loading';
import { FileAttachments } from './FileAttachments';

export const BankLoanHistory = ({
  bankLoanHistory,
  openViewDetailDialog,
  appId,
  childLoans,
}) => {
  const cancelledLoans = new Set<string>(
    bankLoanHistory
      .filter((h) => h.action === 'LOC_CANCELLED')
      .map((h) => h.GUID),
  );

  const childLoansMap = {};
  for (const loc of childLoans ?? []) {
    childLoansMap[loc.OriginalLoanInfo.Id] = loc;
  }

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(<></>);
  const [confirmAction, setConfirmAction] = useState(Function);
  const [confirmReady, setConfirmReady] = useState(false);
  const navigate = useNavigate();
  const LOANS_API_PREFIX = '/api/v1/loans';

  const handleCancel = (locGUID: string, locNumber: string) => {
    setModalTitle('Canceling the LOC');
    setModalContent(
      <Typography variant="body1">This will cancel {locGUID}</Typography>,
    );
    setConfirmAction(() => () => cancelLOC(locGUID, locNumber));
    setShowModal(true);
  };

  const handleUpdate = async (locGUID: string) => {
    try {
      setModalTitle('Update LOC');
      setModalContent(<Loading />);
      setShowModal(true);
      const crbLoan = await axios(`${LOANS_API_PREFIX}/loc/${locGUID}`);
      const originalLoan = crbLoan.data.OriginalLoanInfo;
      const updatedLoan = await axios(
        `${LOANS_API_PREFIX}/child-loan-data/${appId}/${locGUID}?byGuid=true&forUpdate=true`,
      );
      setConfirmAction(
        () => () => handleUpdateLOC(locGUID, appId, updatedLoan.data),
      );
      setModalContent(
        <ReactJsonViewCompare
          oldData={originalLoan}
          newData={updatedLoan.data}
          fullWidth
        />,
      );
      setConfirmReady(true);
    } catch (err: any) {
      setModalTitle('Error happened while sending update');
      setModalContent(
        <Typography>
          {err.response?.data?.message ?? 'Failed to get loan info'}
        </Typography>,
      );
    }
  };

  const closeModal = () => {
    setModalContent(<></>);
    setConfirmAction(() => {});
    setModalTitle('');
    setShowModal(false);
    navigate('');
    setConfirmReady(false);
  };

  const cancelLOC = async (locGUID: string, locNumber: string) => {
    setModalContent(<Loading />);
    await axios.delete(`${LOANS_API_PREFIX}/child-loan`, {
      data: {
        locGUID,
        locNumber,
        appId,
      },
    });
    closeModal();
  };

  const handleUpdateLOC = async (crbId: string, appId: string, data: any) => {
    setModalContent(<Loading />);
    setConfirmReady(false);
    await axios.put(
      `${LOANS_API_PREFIX}/update/${crbId}/${appId}?isLOC=true`,
      data,
    );
    closeModal();
  };

  const sendAttachments = async (guid: string) => {
    try {
      setModalContent(<Loading />);
      setConfirmReady(false);
      await axios.post(
        `${LOANS_API_PREFIX}/attach-files/${appId}?GUID=${guid}&isLOC=true`,
      );
      setModalTitle('Success');
      setModalContent(
        <Box className="content">
          <Typography variant="body1">
            {'Attachments added successfully ✅'}
          </Typography>
        </Box>,
      );
    } catch (err: any) {
      setModalContent(
        <>
          <Typography variant="body1">
            {err.response?.data?.message ?? 'Failed to attach files'}
          </Typography>
        </>,
      );
      setModalTitle(`Error occurred while sending files to CRB`);
      setShowModal(true);
    }
  };

  const handleAttachFiles = async (guid: string) => {
    setModalTitle('Attach Files to LOC');
    setModalContent(<Loading />);
    setConfirmReady(false);
    setShowModal(true);
    try {
      const result = await axios(`${LOANS_API_PREFIX}/crb-files/${appId}`);
      const hasFiles = result.data.length > 0;
      setConfirmReady(hasFiles);
      setConfirmAction(() => () => sendAttachments(guid));
      setModalContent(
        hasFiles ? (
          <Box className="flex-column flex-gap-1">
            <Paper variant="outlined" sx={{ padding: '1em' }}>
              <ul>
                {result.data.map((doc) => (
                  <li className="flex-row-start flex-gap-1" key={doc}>
                    <InsertDriveFile />
                    <Typography>{doc}</Typography>
                  </li>
                ))}
              </ul>
            </Paper>
          </Box>
        ) : (
          <Typography>No CRB files found</Typography>
        ),
      );
    } catch (err) {}
  };

  const handleViewAttachments = (guid: string) => {
    setModalContent(<FileAttachments loanData={childLoansMap[guid] ?? []} />);
    setModalTitle('List of LOC attachments');
    setShowModal(true);
    setConfirmReady(false);
  };

  return (
    <>
      <BetterAccordion variant="outlined" defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Bank Loan History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper variant="outlined" className="content">
            <Box sx={{ p: '1rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>GUID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="center">JSON Data</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankLoanHistory
                    .sort(
                      (l1, l2) =>
                        new Date(l2.date).getTime() -
                        new Date(l1.date).getTime(),
                    )
                    .map((history) => (
                      <TableRow
                        key={history.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{history.source}</TableCell>
                        <TableCell>
                          <Chip
                            label={history.action}
                            color={
                              history.action === BankLoanActions.CANCELLED
                                ? 'error'
                                : history.action === ActionType.LOC_CANCELLED
                                  ? 'warning'
                                  : 'default'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {history.data?.LoanNumber ?? '---'}
                        </TableCell>
                        <TableCell>{history.GUID}</TableCell>
                        <TableCell>{history.date}</TableCell>
                        <TableCell align="right">
                          {history.data && (
                            <Button
                              variant="lightOutlined"
                              onClick={() =>
                                openViewDetailDialog(history.data, 'JSON Data')
                              }
                            >
                              View Details
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {history.action === 'LOC_SENT' &&
                            !cancelledLoans.has(history.GUID) && (
                              <Box className="flex-row-space-around">
                                <Tooltip title="Cancel the LOC">
                                  <IconButton
                                    sx={{ color: 'crimson' }}
                                    onClick={() => {
                                      setConfirmReady(true);
                                      handleCancel(
                                        history.GUID,
                                        history.data?.LoanNumber,
                                      );
                                    }}
                                  >
                                    <Cancel />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Update the LOC">
                                  <IconButton
                                    onClick={() => {
                                      handleUpdate(history.GUID);
                                    }}
                                  >
                                    <Update />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Attach files">
                                  <IconButton
                                    onClick={() => {
                                      handleAttachFiles(history.GUID);
                                    }}
                                  >
                                    <AttachFile />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          {history.action === 'LOC_FILES_ATTACHED' && (
                            <Tooltip title="View files">
                              <IconButton
                                onClick={() => {
                                  handleViewAttachments(history.GUID);
                                }}
                              >
                                <FileCopy />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Paper>
        </AccordionDetails>
      </BetterAccordion>
      {showModal && (
        <ConfirmationModal
          open={() => setShowModal(true)}
          onClose={() => closeModal()}
          onConfirm={confirmAction}
          title={modalTitle}
          content={modalContent}
          size="md"
          ready={confirmReady}
        />
      )}
    </>
  );
};
