import { Box, Typography } from '@mui/material';
import React from 'react';

export const Overlay = (props) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        right: '0',
        bottom: '0',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'white',
        opacity: '0.8',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        zIndex: '2',
      }}
    >
      <Typography variant="h4">{props.text}</Typography>
      {props.children}
    </Box>
  );
};
