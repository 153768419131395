import { Paper, Box, Typography } from '@mui/material';
import React from 'react';

export const ErrorPage = ({
  status = 404,
  title = 'Error',
  message = 'Some error happened',
}) => {
  const bg = status === 403 ? 'crimson' : 'gold';
  const color = status === 403 ? 'pink' : 'darkorange';
  return (
    <Paper>
      <Box
        sx={{
          bgcolor: bg,
          color,
          padding: '1rem',
          textTransform: 'uppercase',
        }}
      >
        <Typography variant="h5" align="center">
          {title ?? 'Not Found'}
        </Typography>
      </Box>
      <Box sx={{ padding: '1rem' }}>
        <Typography variant="body2">{message ?? 'Page Not Found'}</Typography>
      </Box>
    </Paper>
  );
};
