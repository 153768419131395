import {
  Edit,
  ErrorOutline,
  CheckOutlined,
  Close,
  Settings,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { tsToDate } from '../../helpers/converters';
import { NoteModal } from './NoteModal';
import { styled } from '@mui/material/styles';
import { SettingsModal } from './SettingsModal';
import {
  ChecklistItemRule,
  ChecklistItemType,
  ChecklistItemVerificationStatus,
} from './constants';

const StyledTooltip = styled(({ className, ...props }) => {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '-7px 6px 39px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const UpdateStatusValues = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const getVerificationStatusOptions = (check) => {
  const documentRequirementOptions = [
    {
      label: 'Not Verified',
      value: ChecklistItemVerificationStatus.NOT_VERIFIED,
    },
    {
      label: 'Verified',
      value: ChecklistItemVerificationStatus.VERIFIED,
      color: 'success',
    },
    {
      label: 'Issues Identified',
      value: ChecklistItemVerificationStatus.ISSUES_IDENTIFIED,
      color: 'error',
    },
    {
      label: 'Missing',
      value: ChecklistItemVerificationStatus.MISSING,
      color: 'warning',
    },
  ];

  const callOptions = [
    {
      label: 'Called - Spoke To',
      value: ChecklistItemVerificationStatus.CALLED_SPOKE_TO,
      color: 'success',
    },
    {
      label: 'Called - Voicemail',
      value: ChecklistItemVerificationStatus.CALLED_VOICEMAIL,
      color: 'success',
    },
    {
      label: 'Issues Identified',
      value: ChecklistItemVerificationStatus.ISSUES_IDENTIFIED,
      color: 'error',
    },
    {
      label: 'Required',
      value: ChecklistItemVerificationStatus.REQUIRED,
      color: 'error',
    },
    {
      label: 'Not Needed',
      value: ChecklistItemVerificationStatus.NOT_NEEDED,
    },
    {
      label: 'Not Called',
      value: ChecklistItemVerificationStatus.NOT_CALLED,
    },
  ];

  const docusignOptions = [
    {
      label: 'Signed',
      value: ChecklistItemVerificationStatus.SIGNED,
      color: 'success',
    },
    {
      label: 'Issues Identified',
      value: ChecklistItemVerificationStatus.ISSUES_IDENTIFIED,
      color: 'error',
    },
    {
      label: 'Sent',
      value: ChecklistItemVerificationStatus.SENT,
    },
    {
      label: 'Not Sent',
      value: ChecklistItemVerificationStatus.NOT_SENT,
    },
  ];

  if (check.type === ChecklistItemType.DOCUMENT_REQUIREMENT) {
    return documentRequirementOptions;
  }
  if (check.type === ChecklistItemType.CALL) {
    return callOptions;
  }
  if (check.type === ChecklistItemType.DOCUSIGN) {
    return docusignOptions;
  }
};

const getChecked = (check) => {
  if (check.type === ChecklistItemType.DOCUMENT_REQUIREMENT) {
    return (
      check.verificationStatus === ChecklistItemVerificationStatus.VERIFIED
    );
  }
  if (check.type === ChecklistItemType.CALL) {
    return (
      check.verificationStatus ===
        ChecklistItemVerificationStatus.CALLED_SPOKE_TO ||
      check.verificationStatus ===
        ChecklistItemVerificationStatus.CALLED_VOICEMAIL
    );
  }
  if (check.type === ChecklistItemType.DOCUSIGN) {
    return check.verificationStatus === ChecklistItemVerificationStatus.SIGNED;
  }
};

export const DocumentCheck = ({
  check,
  parentCheckValue = undefined,
  updateCheck,
  deleteCheck,
  readOnly = false,
  showColumnTitles = false,
}) => {
  const [checkState, setCheckState] = useState(check);

  const [updateStatus, setUpdateStatus] = useState(UpdateStatusValues.SUCCESS);

  const lastSavedValue = useRef(check.inputValue);
  const unsavedChanges = lastSavedValue.current !== checkState.inputValue;

  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const isSystemMountingHardwareCheck =
    checkState.title === 'System Mounting Hardware';

  const updateCheckState = useCallback(
    async (updates) => {
      setUpdateStatus(UpdateStatusValues.LOADING);
      const updatedCheck = await updateCheck(checkState.id, updates);
      if (updatedCheck.error) {
        console.error(updatedCheck.error);
        setUpdateStatus(UpdateStatusValues.ERROR);
      } else {
        lastSavedValue.current = updatedCheck.inputValue;
        setCheckState(updatedCheck);
        setUpdateStatus(UpdateStatusValues.SUCCESS);
      }
    },
    [checkState.id],
  );

  const clearNotes = useCallback(async () => {
    await updateCheckState({ notes: '' });
  }, [updateCheckState]);

  const isDependentCheck = checkState.rule === ChecklistItemRule.EQUAL;
  const isYesNoCheck = checkState.rule === ChecklistItemRule.YES_NO;

  const isDisabled =
    updateStatus === UpdateStatusValues.LOADING ||
    isDependentCheck ||
    isYesNoCheck ||
    checkState.verificationStatus === ChecklistItemVerificationStatus.VERIFIED;

  return (
    <Grid container columnSpacing={2.5} rowSpacing={0.75}>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'end'}>
        <TextField
          label={checkState.title}
          variant="outlined"
          size="small"
          fullWidth
          select={isSystemMountingHardwareCheck}
          required={check.isRequired}
          disabled={isDisabled}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
              bgcolor: '#EFF1F4',
              color: 'text.primary',
            },
            // Also darken the label a bit
            '& .MuiFormLabel-root.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.87)',
            },
            // Also darke the notched outline a bit
            '& .MuiOutlinedInput-notchedOutline.Mui-disabled': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
          }}
          aria-readonly={isDependentCheck}
          value={
            (isDependentCheck ? parentCheckValue : checkState.inputValue) ?? ''
          }
          onBlur={async () =>
            !isDependentCheck &&
            unsavedChanges &&
            !readOnly &&
            updateCheckState({
              inputValue: checkState.inputValue,
            })
          }
          onChange={async (e) => {
            if (isDependentCheck || readOnly) return;
            const inputValue = e.target.value;
            setCheckState((prevState) => {
              return {
                ...prevState,
                inputValue,
              };
            });
          }}
          inputProps={{
            tabIndex: 1,
          }}
          InputProps={
            unsavedChanges
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {unsavedChanges &&
                        (updateStatus === UpdateStatusValues.SUCCESS ? (
                          <CheckOutlined
                            sx={{ color: 'success.main', fontSize: 16 }}
                          />
                        ) : updateStatus === UpdateStatusValues.ERROR ? (
                          <Tooltip title="Error Saving">
                            <ErrorOutline
                              sx={{ color: 'error.main', fontSize: 16 }}
                            />
                          </Tooltip>
                        ) : null)}
                      <Button
                        disabled={updateStatus === UpdateStatusValues.LOADING}
                        size="small"
                        onClick={() =>
                          updateCheckState({
                            inputValue: checkState.inputValue,
                          })
                        }
                      >
                        {updateStatus === UpdateStatusValues.LOADING ? (
                          <CircularProgress size={16} />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        >
          {isSystemMountingHardwareCheck
            ? [
                'Roof Mount',
                'Carport',
                'Ground Mount',
                'Roof Mount and Ground Mount',
                'Roof Mount and Carport',
                'Other',
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))
            : null}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4}>
        {showColumnTitles && (
          <Typography
            variant="body1"
            fontWeight={'bold'}
            fontSize={14}
            sx={{ mb: 1 }}
          >
            Verification
          </Typography>
        )}
        <TextField
          select
          size="small"
          variant="outlined"
          fullWidth
          value={checkState.verificationStatus}
          disabled={readOnly}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Checkbox
                  inputProps={{
                    'aria-label': `Verify "${checkState.title}" check`,
                  }}
                  disabled={
                    readOnly || checkState.type === ChecklistItemType.CALL
                  }
                  color="success"
                  checked={getChecked(checkState)}
                  onChange={async (e) => {
                    if (readOnly) return;
                    if (checkState.type === ChecklistItemType.DOCUSIGN) {
                      updateCheckState({
                        verificationStatus: e.target.checked
                          ? ChecklistItemVerificationStatus.SIGNED
                          : ChecklistItemVerificationStatus.NOT_SENT,
                      });
                      return;
                    }
                    if (
                      checkState.type === ChecklistItemType.DOCUMENT_REQUIREMENT
                    ) {
                      updateCheckState({
                        verificationStatus: e.target.checked
                          ? ChecklistItemVerificationStatus.VERIFIED
                          : ChecklistItemVerificationStatus.NOT_VERIFIED,
                      });
                      return;
                    }
                  }}
                />
              </InputAdornment>
            ),
          }}
          onChange={async (e) => {
            updateCheckState({
              verificationStatus: e.target.value,
            });
          }}
        >
          {getVerificationStatusOptions(check).map((status) => {
            return (
              <MenuItem key={status.value} value={status.value}>
                <Typography variant="body2" component={'div'}>
                  <Chip
                    color={status.color}
                    label={status.label}
                    size="small"
                    sx={{
                      opacity: 0.8,
                    }}
                  />
                </Typography>
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        display={'flex'}
        alignItems={'start'}
        justifyContent={'space-between'}
        flexDirection={'column'}
      >
        {showColumnTitles && (
          <Typography
            variant="body1"
            fontWeight={'bold'}
            fontSize={14}
            sx={{ mb: 1 }}
          >
            Modified
          </Typography>
        )}
        <Box
          display="flex"
          justifyContent="start"
          alignItems={'center'}
          sx={{ height: '100%', width: '100%' }}
        >
          <IconButton
            size="small"
            disabled={readOnly}
            onClick={() => setNoteModalOpen(true)}
            sx={{
              mr: 1.25,
            }}
            title="Add Note"
          >
            <Edit />
          </IconButton>
          {noteModalOpen && (
            <NoteModal
              note={checkState.notes}
              open={noteModalOpen}
              onClose={() => setNoteModalOpen(false)}
              onSubmit={async (notes) => {
                await updateCheckState({
                  notes,
                });
                setNoteModalOpen(false);
              }}
            />
          )}
          {settingsModalOpen && (
            <SettingsModal
              settings={checkState}
              open={settingsModalOpen}
              onClose={() => setSettingsModalOpen(false)}
              onDelete={async () => {
                await deleteCheck(checkState.id);
              }}
              onSubmit={async (updates) => {
                await updateCheckState(updates);
                setSettingsModalOpen(false);
              }}
            />
          )}
          <StyledTooltip
            title={
              checkState.lastModifiedByUserName && (
                <Box p={1}>
                  <Typography variant="body2" textAlign={'center'}>
                    {checkState.lastModifiedByUserName}
                  </Typography>
                  <Typography variant={'body2'} color={'#868795'}>
                    {tsToDate(checkState.updatedAt)}
                  </Typography>
                </Box>
              )
            }
            arrow
          >
            <Avatar
              alt={checkState.lastModifiedByUserName}
              sx={{ width: 24, height: 24 }}
            >
              {checkState.lastModifiedByUserName?.[0]?.toUpperCase()}
            </Avatar>
          </StyledTooltip>
          <IconButton
            size="small"
            disabled={readOnly}
            onClick={() => setSettingsModalOpen(true)}
            sx={{
              ml: 1.25,
            }}
            title="Edit Item"
          >
            <Settings />
          </IconButton>
        </Box>
      </Grid>
      {checkState.notes && (
        <>
          <Grid item xs />
          <Grid item xs={12} sm={6}>
            <Box borderRadius={'3px'} bgcolor={'grey.200'} px={0.75} py={0.375}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: 'pre-line',
                  position: 'relative',
                }}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    p: 0.5,
                  }}
                  onClick={clearNotes}
                >
                  <Close
                    sx={{
                      fontSize: 12,
                    }}
                  />
                </IconButton>
                {checkState.notes}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};
