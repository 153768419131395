import {
  QualityIndicators,
  ScoreCardEditComponents,
  ScoreCardFormatters,
  ScorecardItemKeys,
} from '../constants';

export interface ValueHolder {
  value: number;
  type: string;
  stringValue: string | null;
  boolValue: boolean | null;
}

interface ItemConfig {
  [key: string]: any;
}

export const itemConfig: ItemConfig = {
  [ScorecardItemKeys.LOAN_AMOUNT]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value >= 1000000) {
        return QualityIndicators.OK;
      }
      return QualityIndicators.GOOD;
    },
    formatter: ScoreCardFormatters.CURRENCY,
    label: 'Loan Amount',
    source: 'Loan Application: Loan amount',
  },
  [ScorecardItemKeys.LONGEVITY_OF_BUSINESS]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value <= 1) {
        return QualityIndicators.BAD;
      }

      if (holder.value >= 5) {
        return QualityIndicators.GOOD;
      }

      return QualityIndicators.OK;
    },
    formatter: ScoreCardFormatters.INTEGER,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Longevity of Business',
    source: 'Loan Application: Date Business Established',
    scoring: {
      type: 'smooth-scale',
      maxPoints: 8,
      ceiling: 10,
      floor: 2,
    },
  },
  [ScorecardItemKeys.OWNER_EXPERIENCE]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value <= 1) {
        return QualityIndicators.BAD;
      }

      if (holder.value >= 5) {
        return QualityIndicators.GOOD;
      }

      return QualityIndicators.OK;
    },
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Management Years of Industry Experience',
    source: 'Loan Application: Management Years of Industry Experience',
    scoring: {
      type: 'smooth-scale',
      maxPoints: 2,
      ceiling: 10,
      floor: 2,
    },
  },
  [ScorecardItemKeys.CHECKING_ACCOUNT_VERIFICATION]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.boolValue) {
        return QualityIndicators.GOOD;
      }

      if (holder.boolValue === false) {
        return QualityIndicators.BAD;
      }

      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.VERIFICATION_BOOL,
    editComponent: ScoreCardEditComponents.EDIT_BOOL,
    label: 'Checking Account Verification',
    source: 'Manual',
  },
  [ScorecardItemKeys.PAYDEX_SCORE]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Commercial Credit Score (Paydex/Equifax)',
    source: 'D&B',
    scoring: {
      type: 'smooth-scale-with-below-floor-value',
      belowFloor: -2,
      maxPoints: 5,
      ceiling: 80,
      floor: 50,
    },
  },
  [ScorecardItemKeys.PAYMENTS_TO_SOLAR_DEBT_SERVICE_RATIO]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Recurring Incentive Payments To Solar Debt Service Ratio',
    source: 'Manual',
    scoring: {
      type: 'smooth-scale',
      maxPoints: 3,
      ceiling: 100,
      floor: 0,
    },
  },
  [ScorecardItemKeys.LIQUIDITY_TO_SOLAR_AMOUNT]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Net Personal Liquidity to Solar Loan Amount',
    source: 'Equifax',
    scoring: {
      type: 'smooth-scale',
      maxPoints: 15,
      ceiling: 2,
      floor: 0.75,
    },
  },
  [ScorecardItemKeys.SYSTEM_BENEFIT_TO_EXPENSE_RATIO]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'All-in 20 year System Benefit to Expense Ratio',
    source: 'Manual',
    scoring: {
      type: 'smooth-scale',
      maxPoints: 5,
      ceiling: 2.5,
      floor: 1.1,
    },
  },
  /**
   * The FICO score logscale values used below for the formula of format y = mlog(x) + b was determined
   * by the curve derived from real data of historical loan defaults in correlation with
   * user FICO scores and loan sizes.
   * The m (multiplier) and b (intercept) values used here are derived from the best fit curve found on those trends
   * The "normalization" of the value happens because a minimum FICO score of 670 is used (x = score - 670)
   * The "ScoreFloor" (y' = mlog(FloorFicoScore-670)+b) and "ScoreCeiling" (y'' = mlog(CeilingScore-670)+b) determine
   * the FICO score that we want to scale the Score value within. The bands differ depending on the loanAmount (tiers).
   * y = (mlog(x-670)-y')/(y''-y') is the full formula.
   * The values will change if the trends change. The values were derived from trends provided by the Underwriting team.
   **/
  [ScorecardItemKeys.FICO_SCORE]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'FICO Score',
    source: 'Equifax',
    scoring: {
      type: 'tiered-logscale',
      valueNormalizer: 670,
      multiplier: 1.19972,
      intercept: 3.311018394,
      maxPoints: 35,
      tiers: [
        // 0 - 150k
        {
          loanThreshold: 0,
          scoreFloor: 7.391505666,
          scoreCeiling: 8.950282498,
        },
        // 150k - 250k
        {
          loanThreshold: 150000,
          scoreFloor: 8.004353796,
          scoreCeiling: 9.150700759,
        },
      ],
    },
  },
  [ScorecardItemKeys.DEBT_TO_INCOME]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.PERCENT_ONE_DECIMAL,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Debt to Income',
    source: 'Equifax',
    // TODO this likely changing to custom
    scoring: {
      type: 'smooth-scale-inverted',
      maxPoints: 7,
      ceiling: 40,
      floor: 20,
    },
  },

  // Note: not in use, keeping for future scorecards
  // and table-based scoring example
  [ScorecardItemKeys.NAICS_ATTRACTIVENESS]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.INTEGER,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Industry Attractiveness (NAICs Code)',
    source: 'Manual',
    scoring: {
      type: 'table-based',
      preparation: (holder: ValueHolder) => {
        const firstTwoDigits = parseFloat(
          holder.value.toString().substring(0, 2),
        );
        return firstTwoDigits;
      },
      tableName: 'naics-scores',
      maxPoints: 3,
    },
  },
  [ScorecardItemKeys.EMPLOYMENT]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.DEFAULT_STRING,
    label: 'Employment/Occupation',
    source: 'PG Application',
    scoring: {
      type: 'table-based',
      preparation: (holder: ValueHolder) => {
        return holder.stringValue;
      },
      tableName: 'employment-scores',
      maxPoints: 3,
    },
  },
  [ScorecardItemKeys.PROPERTY_TO_LOAN_VALUE]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.PERCENT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Property Loan to Value (“LTV”)',
    source: 'Loan Obligations',
    scoring: {
      type: 'smooth-scale-inverted',
      maxPoints: 20,
      ceiling: 80,
      floor: 30,
    },
  },
  [ScorecardItemKeys.BUSINESS_DSCR]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    label: 'Business DSCR (Solar pro forma)',
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source: 'Solar Economics / Application / Manual',
    scoring: {
      type: 'smooth-scale',
      maxPoints: 30,
      ceiling: 2.0,
      floor: 1.2,
    },
  },
  [ScorecardItemKeys.CASH_COVERAGE_OF_PAYMENTS]: {
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    formatter: ScoreCardFormatters.INTEGER,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Business Cash Coverage of Annual Solar Loan Payments',
    source: 'Solar Economics / Application',
    scoring: {
      type: 'smooth-scale',
      maxPoints: 7,
      ceiling: 4,
      floor: 2,
    },
  },
  [ScorecardItemKeys.PROPERTY_VALUE]: {
    formatter: ScoreCardFormatters.CURRENCY,
    qualityCheck: (holder: ValueHolder) => {
      return QualityIndicators.NA;
    },
    label: 'Property Value',
    source: 'Loan Application: System Property Appraised Value',
  },
  [ScorecardItemKeys.DSCR_MIN]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value >= 0.6) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    label: 'Min DSCR',
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source: 'Loan Application: DSCR',
  },
  [ScorecardItemKeys.DSCR_AVG]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value >= 0.8) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    label: 'Average Solar 20-yr DSCR (excluding ITC and D&A)',
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source: 'Loan Application: DSCR',
    scoring: {
      type: 'smooth-scale-modified-zero',
      maxPoints: 5,
      ceiling: 1.5,
      floor: 0.7,
      modifiedZero: -3,
    },
  },
  [ScorecardItemKeys.PROPERTY_SYSTEM_RATIO]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value > 2.5) {
        return QualityIndicators.GOOD;
      }

      return QualityIndicators.BAD;
    },
    label: 'Property Value to Solar System Value Ratio (Solar Only)',
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source:
      'Loan Application: (Property Value + Total System Cost) / Total System Cost',
    scoring: {
      type: 'smooth-scale-inverted',
      maxPoints: 5,
      ceiling: 12,
      floor: 2.5,
    },
  },
  [ScorecardItemKeys.EBITDA]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value > 0) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    label: 'EBITDA',
    formatter: ScoreCardFormatters.CURRENCY,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source: 'Flashspread: EBITDA',
  },
  [ScorecardItemKeys.EBITDA_TREND]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value > 0) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    label: 'EBITDA Trend',
    formatter: ScoreCardFormatters.CURRENCY,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source: 'Flashspread: EBITDA',
  },
  [ScorecardItemKeys.FCCR]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value > 0) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    label: '[Current year - 1] FCCR',
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source: 'Loan Obligations & EBITDA',
  },
  [ScorecardItemKeys.FCCR_AVERAGE]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value > 0) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    label: 'Average FCCR',
    formatter: ScoreCardFormatters.DEFAULT,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    source: 'Loan Obligations & EBITDA',
  },
  [ScorecardItemKeys.BANKRUPTCIES]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value > 0) {
        return QualityIndicators.BAD;
      }
      return QualityIndicators.GOOD;
    },
    formatter: ScoreCardFormatters.BANKRUPTCIES,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Bankruptcies',
    source: 'Lexis Nexis',
  },
  [ScorecardItemKeys.LIENS]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.value > 0) {
        return QualityIndicators.BAD;
      }
      return QualityIndicators.GOOD;
    },
    formatter: ScoreCardFormatters.LIENS,
    editComponent: ScoreCardEditComponents.EDIT_NUMERIC,
    label: 'Liens',
    source: 'Lexis Nexis',
  },
  [ScorecardItemKeys.BUSINESS_VERIFICATION]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.boolValue) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    formatter: ScoreCardFormatters.VERIFICATION_BOOL,
    label: 'Business Verification',
    source: 'Lexis Nexis',
  },
  [ScorecardItemKeys.BUSINESS_VERIFICATION_SOS]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.boolValue) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    formatter: ScoreCardFormatters.VERIFICATION_BOOL,
    label: 'Business Verification (SOS)',
    source: 'Lexis Nexis',
  },
  [ScorecardItemKeys.PROPERTY_VERIFICATION]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.boolValue) {
        return QualityIndicators.GOOD;
      }
      return QualityIndicators.BAD;
    },
    formatter: ScoreCardFormatters.VERIFICATION_BOOL,
    label: 'Property Verification',
    source: 'Lexis Nexis',
  },
  [ScorecardItemKeys.HIGH_RISK_BUSINESS]: {
    qualityCheck: (holder: ValueHolder) => {
      if (holder.boolValue) {
        return QualityIndicators.BAD;
      }

      return QualityIndicators.GOOD;
    },
    formatter: ScoreCardFormatters.RISK,
    label: 'Business Type Risk',
    source: 'Loan Application: Is High-Risk Business',
  },
};

export const tableBasedScores = {
  'naics-scores': [
    { value: 11, points: 3 },
    { value: 21, points: 1.5 },
    { value: 22, points: 0 },
    { value: 23, points: 1.5 },
    { value: 31, points: 3 },
    { value: 32, points: 3 },
    { value: 33, points: 3 },
    { value: 42, points: 0 },
    { value: 44, points: 0 },
    { value: 45, points: 0 },
    { value: 48, points: 0 },
    { value: 49, points: 0 },
    { value: 51, points: 0 },
    { value: 52, points: 3 },
    { value: 53, points: 1.5 },
    { value: 54, points: 1.5 },
    { value: 55, points: 0 },
    { value: 56, points: 0 },
    { value: 61, points: 1.5 },
    { value: 62, points: 3 },
    { value: 71, points: 1.5 },
    { value: 72, points: 3 },
    { value: 811, points: 0 },
    { value: 813, points: 3 },
    { value: 92, points: 0 },
  ],
  'employment-scores': [
    { value: 'Accountant', points: 3 },
    { value: 'Administrative Support', points: 0 },
    { value: 'Architect', points: 3 },
    { value: 'Business Owner', points: 0 },
    { value: 'Chef', points: 0 },
    { value: 'Construction Worker', points: 0 },
    { value: 'Counselor', points: 1.5 },
    { value: 'Dentist', points: 3 },
    { value: 'Doctor', points: 3 },
    { value: 'Driver', points: 0 },
    { value: 'Engineer', points: 3 },
    { value: 'Farmer', points: 0 },
    { value: 'Financial Professional', points: 3 },
    { value: 'Firefighter', points: 1.5 },
    { value: 'Food Server', points: 0 },
    { value: 'Janitorial Worker', points: 0 },
    { value: 'Law Enforcement', points: 1.5 },
    { value: 'Lawyer', points: 3 },
    { value: 'Nurse', points: 1.5 },
    { value: 'Personal Care and Service Worker', points: 0 },
    { value: 'Pharmacist', points: 3 },
    { value: 'Real Estate Agent', points: 0 },
    { value: 'Religious Worker', points: 0 },
    { value: 'Sales Representative', points: 1.5 },
    { value: 'Software Developer', points: 3 },
    { value: 'Teacher', points: 1.5 },
    { value: 'Technician', points: 1.5 },
    { value: 'Therapist', points: 0 },
    { value: 'Veterinarian', points: 3 },
    { value: 'Other', points: 0 },
  ],
};
