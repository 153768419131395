import Cookie from 'js-cookie';
import React from 'react';
import { isAdmin } from '../../helpers/auth';
import { ErrorPage } from '../../pages/ErrorPage';

export const AdminGuard = ({ children }) => {
  const user = Cookie.get('user');
  if (!isAdmin()) {
    // user is not authenticated
    return (
      <ErrorPage
        status={401}
        title="Not Authorized"
        message="You are not authorized to view this page"
      />
    );
  }
  return children;
};
