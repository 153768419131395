import { OracleInsightType } from '@backend/constants';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Loading } from '../../components/Loading';

export const OracleDocUploadDialog = ({
  applicationId,
  open,
  onClose,
  onSuccess,
}) => {
  interface DocType {
    key: string;
    value: string;
  }
  const supportedDocuments: DocType[] = [
    { key: OracleInsightType.SolarProposal, value: 'Solar Proposal' },
    { key: OracleInsightType.PowerPurchaseAgreement, value: 'PPA Agreement' },
    { key: OracleInsightType.LeaseAgreement, value: 'Lease Agreements' },
    // Add more options as support is added
  ];

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedDocTypeValue, setSelectedDocType] = useState<string>(
    supportedDocuments[0].value,
  );
  const [selectedDocTypeKey, setSelectedDocTypeKey] = useState<string>(
    supportedDocuments[0].key,
  );
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [oracleProgressText, setOracleProgressText] = useState(
    'Select a PDF file to get started',
  );

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle dropdown change
  const handleDocTypeChange = (event, key) => {
    const itemKey = key.key.slice(2); //Removes the .$ from the key.
    setSelectedDocType(event.target.value);
    setSelectedDocTypeKey(itemKey);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        setIsLoading(true);
        setShowUploadButton(false);
        setOracleProgressText(`Uploading and analyzing...`);

        const formData = new FormData();
        // Have to send docType params before appending the file
        formData.append('docType', selectedDocTypeKey);
        formData.append('applicationId', applicationId);
        formData.append('', selectedFile, selectedFile.name);

        axios
          .post('/api/v1/oracle/analysis', formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.status == 200) {
              setOracleProgressText(
                `Analysis Complete. ${response.data?.message}`,
              );
              setIsLoading(false);
              onSuccess();
            } else {
              setOracleProgressText(
                `Failed to analyze! ${response.status}: ${response.data.message}`,
              );
              setIsLoading(false);
              setShowUploadButton(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setOracleProgressText(
              `Failed to analyze! ${error.response.status}: ${error.response.data.message}`,
            );
            setIsLoading(false);
            setShowUploadButton(true);
          });
      } catch (error) {
        console.error('Error uploading file and option:', error);
        setIsLoading(false);
        setShowUploadButton(true);
        setOracleProgressText(`Error encountered while analyzing. ${error}`);
      }
    }
  };
  return (
    <Dialog open>
      <DialogTitle>Upload PDF Document for Analysis</DialogTitle>
      <DialogContent>
        <Select
          labelId="doc-type-select-label"
          id="doc-type-select"
          value={selectedDocTypeValue}
          label="Document Type"
          onChange={handleDocTypeChange}
        >
          {supportedDocuments.map((docType) => (
            <MenuItem value={docType.value} key={docType.key}>
              {docType.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          margin="dense"
          id="file"
          label="Upload File"
          type="file"
          fullWidth
          variant="standard"
          InputLabelProps={{ shrink: true }}
          onChange={handleFileChange}
          inputProps={{ accept: "application/pdf" }}
        />
      </DialogContent>

      {isLoading && <Loading />}
      <Typography variant="body2" margin={5} fontSize={18} align="center">
        {oracleProgressText}
      </Typography>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {showUploadButton && <Button onClick={handleUpload}>Upload</Button>}
      </DialogActions>
    </Dialog>
  );
};
