import { Folder, InsertDriveFile } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { tsToDate } from '../../helpers/converters';
import React from 'react';

export const File = (props) => {
  const dim = '112px';
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {props.type === 'd' ? <Folder /> : <InsertDriveFile />}
        <Typography variant="body2">{props.name}</Typography>
      </Box>
      <Typography variant="body2">{tsToDate(props.time)}</Typography>
    </Box>
  );
};
