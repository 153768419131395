import { Check, Clear } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

export const EditNumeric = (props) => {
  const [inputValue, setInputValue] = useState(props.inputValue);
  const [error, setError] = useState('');

  const item = props.item;
  const applicationId = props.applicationId;
  const setLoading = props.setLoading;
  const setIsEditing = props.setIsEditing;
  const getLoanAnalysis = props.getLoanAnalysis;

  const handleInputChange = (event) => {
    event.target.value = event.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1');
    setInputValue(event.target.value);
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const handleSave = async (e) => {
    if (!inputValue) {
      setError('Please enter a value...');
      return;
    }

    setLoading(true);

    await axios.post('/api/v1/uw/value-overrides/' + applicationId, {
      type: item.type,
      value: Number(inputValue),
    });

    await getLoanAnalysis();
    setIsEditing(false);
    setLoading(false);
  };
  return (
    <Box display="flex" justifyContent="space-between" gap="10px">
      <Box>
        <input
          type="text"
          style={{
            width: '53px',
            height: '100%',
            border: '1px solid #DCE1ED',
            borderRadius: '2px',
          }}
          value={inputValue}
          onChange={handleInputChange}
        />
      </Box>
      <Box
        onClick={handleSave}
        style={
          {
            backgroundColor: '#F4F6F9',
            radius: '7px',
            padding: '6px',
          } as React.CSSProperties
        }
      >
        <Check />
      </Box>
      <Box
        onClick={cancelEdit}
        style={
          {
            backgroundColor: '#DCE1ED',
            radius: '7px',
            padding: '6px',
          } as React.CSSProperties
        }
      >
        <Clear />
      </Box>
      <Typography variant="body2">{error}</Typography>
    </Box>
  );
};
