import { useEffect, useState } from 'react';

/**
 * Debounce hook - returns a debounced value
 * @param {T} value - value to debounce
 * @param {number} delay - in milliseconds
 * @returns {T} debounced value
 */
const useDebouncedValue = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      // Clear timeout if value changes or if component unmounts
      clearTimeout(timeoutId);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebouncedValue;
