import axios from 'axios';
import { useEffect, useState } from 'react';
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import React from 'react';

export const SSOAuth = () => {
  const [searchParams, _] = useSearchParams();
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post('/api/auth/sso', { code: searchParams.get('code') })
      .then((result) => {
        if (result.status === 200) setLoggedIn(true);
        else {
          alert('Failed to log in');
          navigate('/login');
        }
      })
      .catch((err) => {
        alert(err.message);
        navigate('/login');
      });
  }, []);

  if (document.cookie.indexOf('user=') >= 0 || loggedIn) {
    return <Navigate to={'/'} />;
  }
};
