import { ScoreCardEditComponents } from '@backend/constants';
import { Delete, Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { EditBool } from '../../../components/EditBool';
import { EditNumeric } from '../../../components/EditNumeric';
import { Loading } from '../../../components/Loading';
import {
  getScoreString,
  hasManualOverride,
} from '../../../helpers/scorecardHelper';
import { ValueHolder, getFormatter } from './Formatters';
import { FormulaInputs } from './FormulaInputs';

export const LoanAnalysisItem = ({
  item,
  getLoanAnalysis,
  appId,
  showScores,
  scores,
  automationError,
}) => {
  const SHOW_DETAILED_ERRORS = true;
  const [isEditing, setIsEditing] = useState(false);
  const [showFormula, setShowFormula] = useState(true);

  // Show overrides by default if they exist, value or formula input
  const [showOverride, setShowOverride] = useState(hasManualOverride(item));
  const [isFormulaEditing, setIsFormulaEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowOverride(hasManualOverride(item));
  }, [item]);

  const handleReset = async () => {
    setLoading(true);

    const overrideTypes: string[] = [];
    overrideTypes.push(item.type);

    if (item.formula) {
      item.formula.inputs.forEach((input) => {
        overrideTypes.push(input.name);
      });
    }
    await axios.post(`/api/v1/uw/reset-overrides/${appId}`, {
      overrideTypes,
    });

    await getLoanAnalysis();
    setIsEditing(false);
    setLoading(false);
  };

  const handleFormulaToggle = () => {
    setShowFormula(!showFormula);
  };

  const getItemStyles = () => {
    const borderColor = automationError ? '#EB5757' : '#DCE1ED';
    return {
      border: `1px solid ${borderColor}`,
      backgroundColor: '#FFFFFF',
      borderRadius: '9px',
      padding: '9px 13px 9px 13px',
      marginTop: '9px',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '110px',
      justifyContent: 'space-between',
    } as React.CSSProperties;
  };

  const getValueHolder = () => {
    const vh: ValueHolder = {} as ValueHolder;
    if (showOverride && !!item.valueOverride) {
      vh.value = item.valueOverride.value;
      vh.boolValue = item.valueOverride.bool_value;
      vh.type = item.type;
    } else if (
      showOverride &&
      item.formula &&
      item.formula.overriddenFormulaOutput
    ) {
      vh.value = item.formula.overriddenFormulaOutput;
      vh.type = item.type;
    } else {
      vh.value = item.value;
      vh.type = item.type;
      vh.stringValue = item.stringValue;
      vh.boolValue = item.boolValue;
    }

    return vh;
  };

  const renderValue = () => {
    const vh = getValueHolder();

    // If we have a formatter for this item type, use it
    if (item.itemConfig) {
      if ('formatter' in item.itemConfig) {
        return getFormatter(item.itemConfig.formatter)(vh);
      }
    }

    return vh.value;
  };

  const getItemSource = () => {
    if (!!item.valueOverride && showOverride) {
      return `Entered by ${item.valueOverride.user.username}`;
    } else {
      if (automationError) {
        return SHOW_DETAILED_ERRORS
          ? automationError.errorMessage
          : 'Error occurred';
      }

      if (item.reportDate) {
        const date = new Date(item.reportDate);
        return `${item.itemConfig['source']} as of ${date
          .toISOString()
          .slice(0, 10)}`;
      }

      return `${item.itemConfig['source']}`;
    }
  };

  const getLabel = () => {
    return item.itemConfig['label'];
  };

  const showValueEditButton = (item) => {
    return item.canOverride && item.formula === null;
  };

  const getEditComponent = () => {
    if (item.itemConfig.editComponent === ScoreCardEditComponents.EDIT_BOOL) {
      return (
        <EditBool
          inputValue={
            !!item.valueOverride ? item.valueOverride.bool_value : null
          }
          getLoanAnalysis={getLoanAnalysis}
          item={item}
          applicationId={appId}
          setLoading={setLoading}
          setIsEditing={setIsEditing}
        />
      );
    } else if (
      item.itemConfig.editComponent === ScoreCardEditComponents.EDIT_NUMERIC
    ) {
      return (
        <EditNumeric
          inputValue={
            !!item.valueOverride ? item.valueOverride.value : item.value
          }
          getLoanAnalysis={getLoanAnalysis}
          item={item}
          applicationId={appId}
          setLoading={setLoading}
          setIsEditing={setIsEditing}
        />
      );
    } else {
      throw new Error('Unknown edit component');
    }
  };

  const formatInputValue = (value, isCurrency = false) => {
    if (typeof value === 'number') {
      if (isCurrency) {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      } else {
        return value.toFixed(1);
      }
    }

    return value;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box style={getItemStyles()}>
      <Box
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="body1">{getLabel()}</Typography>
          <Box display="flex" flexDirection="row" gap="5px">
            {!isEditing && (
              <>
                <Typography variant="subtitle2">{renderValue()}</Typography>
                {showValueEditButton(item) && (
                  <>
                    <Edit
                      sx={{ fontSize: '16px', marginTop: '11px' }}
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    />
                    {hasManualOverride(item) && showOverride && (
                      <Delete
                        onClick={() => {
                          handleReset();
                        }}
                        sx={{ fontSize: '16px', marginTop: '11px' }}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {isEditing && getEditComponent()}
          </Box>
          <Typography
            variant="body2"
            style={{ color: '#868795', paddingTop: '5px' }}
          >
            {getItemSource()}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="10px">
          <Box display="flex" flexDirection="row">
            <Box
              onClick={() => {
                setShowOverride(false);
              }}
              style={{
                backgroundColor: showOverride ? '#DCE1ED' : '#AACCAA',
                borderRadius: '4px',
                padding: '3px 8px 3px 8px',
                border: '1px solid #DCE1ED',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                cursor: 'pointer',
              }}
            >
              <Typography variant="body2">Automatic</Typography>
            </Box>
            {hasManualOverride(item) && (
              <Box
                onClick={() => {
                  setShowOverride(true);
                }}
                style={{
                  borderRadius: '4px',
                  padding: '3px 8px 3px 8px',
                  border: '1px solid #DCE1ED',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  backgroundColor: showOverride ? '#DDAAAA' : '#DCE1ED',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body2">Override</Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent={'flex-end'}>
            {showScores && (
              <Box style={{ fontWeight: 'bold', color: '#2E9C7F' }}>
                <Typography variant="body1">
                  {getScoreString(item, scores)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {item.formula && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '.3em',
            paddingTop: '.3em',
            borderTop: '1px solid #DCE1ED',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box display="flex" flexDirection="row">
              <Typography variant="body1">How it's Calculated</Typography>
              {showFormula && (
                <ExpandLess
                  onClick={() => handleFormulaToggle()}
                  fontSize="medium"
                  style={{
                    transition: 'transform 0.2s',
                  }}
                />
              )}
              {!showFormula && (
                <ExpandMore
                  onClick={() => handleFormulaToggle()}
                  fontSize="medium"
                  style={{
                    transition: 'transform 0.2s',
                  }}
                />
              )}
            </Box>
            <Box>
              {hasManualOverride(item) && showOverride && (
                <Delete
                  sx={{ fontSize: '18px' }}
                  onClick={() => {
                    handleReset();
                  }}
                />
              )}
            </Box>
          </Box>
          <Collapse in={showFormula}>
            <Box display="flex" flexDirection="column" gap="10px">
              <Box
                style={
                  {
                    backgroundColor: '#DCE1ED',
                    borderRadius: '.3em',
                    padding: '.6em',
                    gap: '.3em',
                    display: 'flex',
                    flexDirection: 'column',
                  } as React.CSSProperties
                }
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">
                    <b>Inputs:</b>
                  </Typography>
                  <Edit
                    onClick={() => setIsFormulaEditing(true)}
                    sx={{ fontSize: '16px' }}
                  />
                </Box>
                {isFormulaEditing && item.formula && (
                  <FormulaInputs
                    formula={item.formula}
                    appId={appId}
                    getLoanAnalysis={getLoanAnalysis}
                    setLoading={setLoading}
                    setIsFormulaEditing={setIsFormulaEditing}
                  />
                )}

                {!isFormulaEditing &&
                  item.formula.inputs.map((input) => {
                    let displayValue = input.value;
                    let displaySource = input.source;
                    let isCurrency = input.isCurrency;

                    if (input.inputOverride && showOverride) {
                      displayValue = input.inputOverride.value;
                      displaySource = `Edited by ${input.inputOverride.user.name}`;
                    }

                    return (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box>
                            <Typography variant="body2">
                              {input.display_name}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ color: '#868795', paddingTop: '5px' }}
                            >
                              {displaySource}
                            </Typography>
                          </Box>
                          <Box style={{ paddingTop: '20px' }}>
                            <Typography variant="body2">
                              {formatInputValue(displayValue, isCurrency)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              {item.formula.formula && (
                <Box
                  style={
                    {
                      backgroundColor: '#DCE1ED',
                      borderRadius: '5px',
                      padding: '10px',

                      display: 'flex',
                      flexDirection: 'column',
                    } as React.CSSProperties
                  }
                >
                  <Typography variant="body1" style={{ paddingBottom: '5px' }}>
                    <b>Formula:</b>
                  </Typography>
                  <Typography variant="body2">
                    {item.formula.formula}
                  </Typography>
                </Box>
              )}
            </Box>
          </Collapse>
        </Box>
      )}
    </Box>
  );
};
