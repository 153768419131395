import React, { useMemo } from 'react';
import { ChecklistDocument } from './ChecklistDocument';
import { Paper } from '@mui/material';
import {
  ChecklistItemRule,
  ChecklistItemType,
  ChecklistItemVerificationStatus,
} from './constants';

export const PreviousChecklist = ({ checks }: { checks: Array<any> }) => {
  const parentChecksByTitle = useMemo(() => {
    const parentChecksByTitle = {};
    checks.forEach((check) => {
      if (check.rule === ChecklistItemRule.EQUAL) {
        parentChecksByTitle[check.title] = check;
      }
    });
    return parentChecksByTitle;
  }, [checks]);

  const checksByDocumentName = useMemo(() => {
    const checksByDocumentName = {};
    checks.forEach((check) => {
      if (!checksByDocumentName[check.sunstoneDocumentName]) {
        checksByDocumentName[check.sunstoneDocumentName] = [];
      }
      checksByDocumentName[check.sunstoneDocumentName].push(check);
    });
    return checksByDocumentName;
  }, [checks]);

  return (
    <Paper
      sx={{
        px: 3,
        py: 1.5,
        mt: 2,
        borderRadius: 4,
        '&>*': {
          my: 2,
          '&:first-of-type': { mt: 0 },
          '&:last-of-type': { mb: 0 },
        },
      }}
      elevation={0}
    >
      {Object.entries(checksByDocumentName).map(
        ([documentName, documentChecks]: [string, any]) => {
          const document = {
            Id: documentName,
            Display_Name__c: documentName,
          };
          const status = documentChecks.some(
            (check) =>
              check.verificationStatus ===
              ChecklistItemVerificationStatus.ISSUES_IDENTIFIED,
          )
            ? ChecklistItemVerificationStatus.ISSUES_IDENTIFIED
            : documentChecks.every(
                (check) =>
                  check.verificationStatus ===
                    ChecklistItemVerificationStatus.VERIFIED ||
                  !check.isRequired ||
                  (check.type === ChecklistItemType.CALL &&
                    (check.verificationStatus ===
                      ChecklistItemVerificationStatus.CALLED_SPOKE_TO ||
                      check.verificationStatus ===
                        ChecklistItemVerificationStatus.CALLED_VOICEMAIL)) ||
                  (check.type === ChecklistItemType.DOCUSIGN &&
                    check.verificationStatus ===
                      ChecklistItemVerificationStatus.SIGNED),
              )
            ? ChecklistItemVerificationStatus.VERIFIED
            : ChecklistItemVerificationStatus.NOT_VERIFIED;
          return (
            <ChecklistDocument
              key={documentName}
              document={document}
              status={status}
              readOnly
              documentChecks={documentChecks}
              parentChecksByTitle={parentChecksByTitle}
            />
          );
        },
      )}
    </Paper>
  );
};
