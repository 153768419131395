import { Error } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  MONTHS,
  RequiredDocumentApprovalStatus,
  RequiredDocumentSubType,
  RequiredDocumentUserGroups,
  RequiredFor,
} from '../helpers/constants';
import { getUserIdFromCookie } from '../helpers/helpers';
import { Loading } from './Loading';

const userId = getUserIdFromCookie();

const defaultDocumentState = {
  approval_status: RequiredDocumentApprovalStatus.AWAITING_UPLOAD,
  document_approval_source: 'UPLOAD',
  can_upload: RequiredDocumentUserGroups.ALL,
  can_view: RequiredDocumentUserGroups.ALL,
  is_required: false,
  owner_user_id: userId,
};

const RequiredDocumentModal = ({
  open,
  onClose,
  onSubmit,
  initialDocument = null,
  editMode = false,
  documentTypes,
  users,
}) => {
  const [requiredDocument, setRequiredDocument] = useState<Record<string, any>>(
    initialDocument ? initialDocument : defaultDocumentState,
  );
  const [error, setError] = useState(null);
  const [isInactiveDocumentType, setIsInactiveDocumentType] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setIsInactiveDocumentType(
      !editMode && requiredDocument?.document_type?.includes('Inactive'),
    );
  }, [requiredDocument.document_type]);

  const handleDocumentTypeChange = (event) => {
    const docType = documentTypes[event.target.value];
    // auto-fill requirement name the doc is new
    const requirementName = editMode
      ? requiredDocument.requirement_name
      : docType.name;
    setRequiredDocument((prev) => ({
      ...prev,
      document_type_id: event.target.value,
      is_required: docType.isRequired,
      requirement_name: requirementName,
    }));
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.name === 'month' || event.target.name === 'year') {
      value = parseInt(value);
    }
    setRequiredDocument((prev) => ({
      ...prev,
      [event.target.name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setRequiredDocument((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmit = () => {
    setIsSaving(true);
    let {
      approved_by_user_username,
      created_by_user_id,
      owner_user_id,
      document_type_id,
      ...rest
    } = requiredDocument;
    let payload = {
      ...rest,
    };

    if (!editMode) {
      created_by_user_id = userId;
    }

    // so we can send this to Salesforce without making a db call
    payload['document_type_name'] = documentTypes[document_type_id].name;

    // handle relations
    if (created_by_user_id) {
      payload['created_by_user'] = {
        connect: { id: created_by_user_id },
      };
    } else {
      if (editMode) {
        payload['created_by_user'] = {
          disconnect: true,
        };
      } else {
        delete payload.created_by_user_id;
      }
    }

    if (owner_user_id) {
      payload['owner'] = {
        connect: { id: owner_user_id },
      };
    } else {
      if (editMode) {
        payload['owner'] = {
          disconnect: true,
        };
      } else {
        delete payload.owner_user_id;
      }
    }

    if (document_type_id) {
      payload['document_type'] = {
        connect: { id: document_type_id },
      };
    } else {
      if (editMode) {
        payload['document_type'] = {
          disconnect: true,
        };
      } else {
        delete payload.document_type_id;
      }
    }

    onSubmit(payload)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsSaving(false);
        if (!editMode) {
          setRequiredDocument(defaultDocumentState);
        }
      });
  };

  useEffect(() => {
    if (initialDocument) {
      for (const key in documentTypes) {
        if (documentTypes[key].id == initialDocument['document_type_id']) {
          setRequiredDocument((prev) => {
            // add document_type
            return {
              ...prev,
              document_type: key,
            };
          });
          break;
        }
      }
    }
  }, [initialDocument]);

  useEffect(() => {
    setCanSave(
      !isInactiveDocumentType &&
        requiredDocument.document_type_id &&
        requiredDocument.requirement_name &&
        requiredDocument.approval_status &&
        requiredDocument.document_approval_source &&
        requiredDocument.required_for_step &&
        requiredDocument.can_upload &&
        requiredDocument.can_view,
    );
  }, [isInactiveDocumentType, requiredDocument]);

  if (!requiredDocument) {
    return <div>Loading...</div>;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!editMode) {
          setRequiredDocument(defaultDocumentState);
        }
        onClose();
      }}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {editMode ? 'Edit Requirement' : 'Create Requirement'}
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {editMode && !requiredDocument ? (
          <DialogContent>
            <Loading />{' '}
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid container spacing={1.5}>
              {error ? (
                <Grid item md={12}>
                  <Box className="flex-row-end flex-gap-0_5">
                    <Error color="error" />
                    <Typography variant="subtitle1">{error}</Typography>
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Document Type"
                  name="document_type"
                  onChange={handleDocumentTypeChange}
                  select
                  required
                  value={requiredDocument.document_type_id || ''}
                  variant="outlined"
                >
                  {Object.keys(documentTypes).map((key) => (
                    <MenuItem value={key} key={key}>
                      {documentTypes[key].name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {isInactiveDocumentType && (
                <Grid item xs={12} md={12}>
                  <Typography variant="caption" sx={{ color: 'red' }}>
                    You cannot create new requirements for inactive document
                    types.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Month"
                  name="month"
                  onChange={handleChange}
                  select
                  value={requiredDocument?.month || ''}
                  variant="outlined"
                >
                  {MONTHS.map((value, index) => (
                    <MenuItem value={index} key={index}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Year"
                  name="year"
                  onChange={handleChange}
                  type="number"
                  value={requiredDocument?.year || ''}
                  variant="outlined"
                  inputProps={{
                    min: 2020,
                    max: new Date().getFullYear(),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Sub Type"
                  name="sub_type"
                  onChange={handleChange}
                  select
                  value={requiredDocument?.sub_type || ''}
                  variant="outlined"
                >
                  {Object.values(RequiredDocumentSubType).map((value) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6} />
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Requirement Name"
                  name="requirement_name"
                  onChange={handleChange}
                  required
                  value={requiredDocument.requirement_name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Requirement Description"
                  name="requirement_description"
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  rows={3}
                  defaultValue={
                    requiredDocument?.requirement_description?.length > 0
                      ? requiredDocument.requirement_description
                      : null
                  }
                  required={requiredDocument.document_type === 'Other'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Status"
                  name="approval_status"
                  onChange={handleChange}
                  required
                  select
                  value={
                    requiredDocument?.approval_status ??
                    RequiredDocumentApprovalStatus.AWAITING_UPLOAD
                  }
                  variant="outlined"
                >
                  {Object.values(RequiredDocumentApprovalStatus).map(
                    (value) => (
                      <MenuItem value={value} key={value}>
                        {value}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Source"
                  name="document_approval_source"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.document_approval_source || ''}
                  variant="outlined"
                >
                  <MenuItem value="DOCUSIGN">DOCUSIGN</MenuItem>
                  <MenuItem value="UPLOAD">WEB APP UPLOAD</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Required For Step"
                  name="required_for_step"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.required_for_step || ''}
                  variant="outlined"
                >
                  {Object.values(RequiredFor).map((value) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={requiredDocument?.is_required || false}
                      onChange={handleCheckboxChange}
                      name="is_required"
                    />
                  }
                  label="Is Required"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Users Who Can Upload"
                  name="can_upload"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.can_upload || ''}
                  variant="outlined"
                >
                  {Object.values(RequiredDocumentUserGroups).map((value) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Users Who Can View"
                  name="can_view"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.can_view || ''}
                  variant="outlined"
                >
                  {Object.values(RequiredDocumentUserGroups).map((value) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Owner"
                  name="owner_user_id"
                  onChange={handleChange}
                  select
                  value={requiredDocument?.owner_user_id || ''}
                  variant="outlined"
                >
                  {Object.keys(users).map((key) => (
                    <MenuItem value={users[key].id} key={key}>
                      {key}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              if (!editMode) {
                setRequiredDocument(defaultDocumentState);
              }
              onClose();
            }}
            variant="outlined"
            color="inherit"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            color="primary"
            disabled={!canSave || isSaving}
          >
            {isSaving
              ? 'Saving...'
              : editMode
                ? 'Save Changes'
                : 'Create Requirement'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RequiredDocumentModal;
