import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';

export const NoteModal = ({ open, onClose, onSubmit, note: initialNote }) => {
  const [note, setNote] = useState(initialNote);
  const hasInitialNote = Boolean(initialNote);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" sx={{ p: 2 }}>
      <DialogTitle>
        {hasInitialNote ? 'Edit Note' : 'Add Note'}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(note);
        }}
      >
        <DialogContent>
          <TextField
            label="Note"
            variant="outlined"
            autoFocus
            size="small"
            fullWidth
            multiline
            rows={4}
            value={note}
            onFocus={(e) =>
              e.target.setSelectionRange(
                e.target.value.length,
                e.target.value.length,
              )
            }
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
