import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { JSONTree } from 'react-json-tree';
import { base16_themes } from '../../assets/const';
import { FileAttachments } from '../../components/BankLoan/FileAttachments';
import { BetterAccordion } from '../../components/BetterAccordion';
import { Loading } from '../../components/Loading';
import { toCurrency, toDate } from '../../helpers/converters';
import { ErrorPage } from '../ErrorPage';

export const fetchLoanData = async ({ params }) => {
  try {
    const { data } = await axios(
      `/api/v1/loans/${params.loanId}?fields=LoanInfoUpdates,StatusUpdates,FundingAttempts,FundingInfoUpdates,ProcessedRails,Attachments`,
    );
    return data;
  } catch (err: any) {
    return {
      error: {
        title: err.message ?? 'Error Fetching Loan info',
        message:
          err.response?.data?.message ??
          'An error occurred while trying to fetch load information.',
        status: err.response.status ?? 404,
      },
    };
  }
};

export const LoanDetail = ({
  loanData,
  setDialogTitle,
  setDialogContent,
  setDialogOpen,
}) => {
  if (!loanData) return <Loading />;
  if (loanData?.error) {
    return (
      <ErrorPage
        title={loanData.error.title}
        message={loanData.error.message}
        status={loanData.error.status}
      />
    );
  }

  const handleJsonDialog = (jsonData: any, title: string) => {
    setDialogContent(
      <Box className="flex-column">
        <Box className="json-wrapper">
          <JSONTree
            theme={base16_themes.bright}
            hideRoot={true}
            invertTheme={true}
            data={jsonData}
          />
        </Box>
      </Box>,
    );
    setDialogTitle(title);
    setDialogOpen(true);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
      {loanData.StatusUpdates?.length > 0 && (
        <BetterAccordion variant="outlined" defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              Current Bank Loan Statuses (CRB)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper variant="outlined" className="content">
              <Box className="flex-column flex-gap-1">
                {loanData.StatusUpdates.map((update) => {
                  return (
                    <Box
                      className="flex-row-space-between"
                      sx={{ maxWidth: '40rem' }}
                      key={update.Id}
                    >
                      <Chip label={update.Status} variant="filled" />
                      <Typography variant="body1">
                        {toDate(update.TimeStamp)}
                      </Typography>
                      <Button
                        variant="lightOutlined"
                        color="warning"
                        onClick={() =>
                          handleJsonDialog(update, 'Current Loan Status')
                        }
                      >
                        JSON
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Paper>
          </AccordionDetails>
        </BetterAccordion>
      )}
      {loanData.LoanInfoUpdates?.length > 0 && (
        <BetterAccordion variant="outlined" defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Loan Information Updates (CRB)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper variant="outlined" className="content">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={'40%'}>Application</TableCell>
                      <TableCell width={'20%'}>Date Updated</TableCell>
                      <TableCell width={'20%'} align="right">
                        Loan Update in JSON
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loanData.LoanInfoUpdates.sort((u1: any, u2: any) =>
                      u1.UpdatedOn > u2.UpdatedOn ? -1 : 1,
                    ).map((update) => {
                      return (
                        <TableRow
                          key={update.LoanInfoUpdateId}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell width={'40%'}>
                            {update.LoanNumber}
                          </TableCell>
                          <TableCell width={'20%'}>
                            {toDate(update.UpdatedOn)}
                          </TableCell>
                          <TableCell width={'20%'} align="right">
                            <Button
                              color="success"
                              variant="lightOutlined"
                              onClick={() =>
                                handleJsonDialog(update, 'JSON Data')
                              }
                            >
                              JSON
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </BetterAccordion>
      )}
      {loanData.FundingInfoUpdates?.length > 0 && (
        <BetterAccordion
          variant="outlined"
          defaultExpanded
          disableGutters={true}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Funding Info Updates (CRB)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              {loanData.FundingInfoUpdates.map((update) => {
                return (
                  <Paper
                    variant="outlined"
                    sx={{ padding: '1rem' }}
                    key={update.Id}
                  >
                    <Typography align="center" variant="body1">
                      {toDate(update.UpdatedOn)}
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Rail Type</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Bank Account Number</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {update.Rails.map((rail) => {
                            return (
                              <TableRow
                                key={rail.Id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  {rail.ACHFields
                                    ? rail.ACHFields.Description
                                    : rail.XPayFields.Description}
                                </TableCell>
                                <TableCell>{rail.RailType}</TableCell>
                                <TableCell>{toCurrency(rail.Amount)}</TableCell>
                                <TableCell>
                                  {rail.ACHFields
                                    ? rail.ACHFields.ToAccountNumber
                                    : rail.XPayFields.ToAccountNumber}
                                </TableCell>
                                <TableCell>
                                  {rail.ACHFields &&
                                    (rail.ACHFields.IsFailed
                                      ? 'FAILED'
                                      : 'SENT')}
                                  {rail.XPayFields &&
                                    (rail.XPayFields.IsFailed
                                      ? 'FAILED'
                                      : 'SENT')}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    color="success"
                                    variant="lightOutlined"
                                    onClick={() =>
                                      handleJsonDialog(rail, 'JSON Data')
                                    }
                                  >
                                    JSON
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box className="flex-row-end" sx={{ mt: '1rem' }}>
                      <Button
                        variant="lightOutlined"
                        onClick={() => {
                          setDialogContent(
                            <Box className="json-wrapper">
                              <JSONTree
                                theme={base16_themes.bright}
                                hideRoot={true}
                                invertTheme={true}
                                data={loanData.FundingInfoUpdates}
                              />
                            </Box>,
                          );
                          setDialogTitle('Funding Info Updates');
                          setDialogOpen(true);
                        }}
                      >
                        View Details
                      </Button>
                    </Box>
                  </Paper>
                );
              })}
            </Box>
          </AccordionDetails>
        </BetterAccordion>
      )}
      {loanData.FundingAttempts?.length > 0 && (
        <BetterAccordion variant="outlined" defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Funding Attempts (CRB)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper variant="outlined" className="content">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Amount Funded</TableCell>
                      <TableCell>Funding Result</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loanData.FundingAttempts?.map((attempt) => {
                      return (
                        <TableRow
                          key={attempt.Id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>
                            {toCurrency(attempt.AmountFunded)}
                          </TableCell>
                          <TableCell>{attempt.FundingResult}</TableCell>
                          <TableCell>{toDate(attempt.Time)}</TableCell>
                          <TableCell>
                            <Button
                              color="success"
                              variant="lightOutlined"
                              onClick={() =>
                                handleJsonDialog(attempt, 'JSON Data')
                              }
                            >
                              JSON
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </BetterAccordion>
      )}
      {loanData.ProcessedRails?.length > 0 && (
        <BetterAccordion variant="outlined" defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Processed Rails (CRB)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper variant="outlined" className="content">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Rail Id</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Amount Funded</TableCell>
                      <TableCell>Fund Result</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Processed At</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loanData.ProcessedRails?.map((rail) => {
                      return (
                        <TableRow
                          key={rail.Id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>{rail.RailId}</TableCell>
                          <TableCell>{toCurrency(rail.RailAmount)}</TableCell>
                          <TableCell>{toCurrency(rail.AmountFunded)}</TableCell>
                          <TableCell>{rail.FundResult}</TableCell>
                          <TableCell>{rail.Message}</TableCell>
                          <TableCell>{toDate(rail.ProcessedAt)}</TableCell>
                          <TableCell>
                            <Button
                              color="success"
                              variant="lightOutlined"
                              onClick={() =>
                                handleJsonDialog(rail, 'JSON Data')
                              }
                            >
                              JSON
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </BetterAccordion>
      )}
      {loanData.Attachments.length > 0 && (
        <BetterAccordion variant="outlined" defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Loan Attachments (CRB)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FileAttachments loanData={loanData} />
          </AccordionDetails>
        </BetterAccordion>
      )}
    </Box>
  );
};
