import { USER_ID_COOKIE_KEY } from './constants';
import dayjs, { Dayjs, isDayjs } from 'dayjs';

export function isValidURL(url: string): boolean {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );

  return !!urlPattern.test(url);
}

// Calculates the difference between date2 and date1
// Coverts that difference into a string with the smallest unit of time
// e.g. 10m, 2h, 3d, >30d
export const smallDateDiff = (date1: Date | Dayjs, date2: Date | Dayjs = new Date()) => {

  const date1AsDate = isDayjs(date1) ? date1.toDate() : date1;
  const date2AsDate = isDayjs(date2) ? date2.toDate() : date2;

  let result = '';

  
  const diffTime = (date2AsDate.getTime() - date1AsDate.getTime()) / 60000;

  // negative diffTime
  if (diffTime < 0) {
    result = '--';
    // 60 minutes
  } else if (diffTime < 60) {
    result = `${Math.floor(diffTime)}m`;
    // 24 hours
  } else if (diffTime < 1440) {
    result = `${Math.floor(diffTime / 60)}h`;
    // 120 days
  } else if (diffTime <= 172800) {
    result = `${Math.floor(diffTime / 1440)}d`;
  } else {
    result = `>120d`;
  }

  return result;
};

// Formats a date, e.g. 2024-10-15T19:50:30.320Z -> Oct 15, 2024, 7:50 PM
export const niceDate = (date: Date) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  // Format the date
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getCookieValue = (name: string) => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((c) => c.startsWith(`${name}=`));
  if (cookie) {
    return cookie.split('=')[1];
  }
  return null;
};

export const getUserIdFromCookie = () => {
  let raw = getCookieValue(USER_ID_COOKIE_KEY);
  if (raw) {
    const userId = raw.split('.')[0];
    return parseInt(userId);
  }
  return null;
};
