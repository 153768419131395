import axios from 'axios';
import {
  ChecklistItemRule,
  ChecklistItemType,
} from '../components/ProjectChecklist/constants';
import {
  ChecklistItemTemplate,
  ChecklistTemplate,
} from '../types/ChecklistItemTemplate';

export const getChecklistTemplates = async (
  activeOnly: boolean = true,
): Promise<ChecklistTemplate> => {
  let templates: ChecklistTemplate = {};
  axios
    .get('/api/v1/internal/get-checklist-template-facts')
    .then((response) => {
      for (const template of response.data) {
        if (activeOnly && !template.isActive) {
          continue;
        }
        templates[template.name] = {
          id: template.id,
          group: template.group,
          items: template.items,
          createdDate: template.createdDate,
        };
      }
    })
    .catch((error) =>
      console.error('Error fetching checklist templates', error),
    );
  return templates;
};

export const getChecklistItemTemplates = async (
  activeOnly: boolean = true,
): Promise<ChecklistItemTemplate> => {
  let templates: ChecklistItemTemplate = {};
  axios
    .get('/api/v1/internal/get-checklist-item-menu-facts')
    .then((response) => {
      for (const template of response.data) {
        if (activeOnly && !template.isActive) {
          continue;
        }
        templates[template.name] = {
          checkRule: ChecklistItemRule[template.checkRule],
          checkType: ChecklistItemType[template.checkType],
          isRequired: template.isRequired,
        };
      }
    })
    .catch((error) =>
      console.error('Error fetching checklist item templates', error),
    );
  return templates;
};
