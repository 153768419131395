import { Box, Typography } from '@mui/material';
import React from 'react';

export const DataKeyVal = ({
  label,
  value,
  button = false,
  color = 'black',
  valueAlign = 'left',
}) => {
  const valueAlignStyle = {
    textAlign: valueAlign,
  };

  return (
    <Box className="data-pair">
      <Box className="data-pair-key">
        <Typography variant="caption">{label}</Typography>
      </Box>
      <Box className={`data-pair-value${button ? ' data-pair-chip' : ''}`}>
        <Typography variant="body2" color={color} sx={valueAlignStyle}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
