/**
 * This component uses the funding info record to create a "child loan"
 * and send it to CRB via ARIX. The loan will include the single Rail that is
 * associated with the milestone for the funding info
 */

import { Send } from '@mui/icons-material';
import { Box, Button, Paper, Snackbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { JSONTree } from 'react-json-tree';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { base16_themes } from '../../assets/const';
import ConfirmationModal from '../../components/ConfirmationModal';
import { Loading } from '../../components/Loading';
import { SFLink } from '../../components/SFLink';

export const fetchChildLoanData = async ({ params }) => {
  try {
    const response = await axios.get(
      `/api/v1/loans/child-loan-data/${params.appId}/${params.fundingInfoId}`,
    );
    return response.data;
  } catch (err: any) {
    return {
      error: {
        title: 'Error fetching child loan data',
        message:
          err.response.data.message ??
          'An error occurred while trying to fetch rails for this funding info. Please try again.',
        status: err.response.status,
      },
    };
  }
};

export const ChildLoan = () => {
  const loanData = useLoaderData() as any;
  const params = useParams();
  const [jsonData, setJsonData] = useState(loanData);
  const [loading, setLoading] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [appId, setAppId] = useState('');

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const handleSend = () => {
    setLoading(true);
    setShowSendModal(false);
    axios
      .post(`/api/v1/loans/create-child-loan/${params.fundingInfoId}`, loanData)
      .then((result) => {
        setLoading(false);
        setJsonData(result.data);
        setAppId(result.data.sfApplication);
        setShowRedirectModal(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setSnackMessage('Failed to create the child loan');
        setSnackOpen(true);
      });
  };

  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Paper className="content">
            <Box className="flex-row-space-between">
              <Typography variant="h4">
                Child Loan {loanData.LoanNumber}
              </Typography>
              <SFLink entityId={params.fundingInfoId} />
            </Box>
            <Typography variant="body1">
              Construct a child loan and send it to CRB. This page displays the
              loan detail and the Rail associated with the milestone.
            </Typography>
            <Paper variant="outlined" className="json-wrapper">
              <JSONTree
                theme={base16_themes.bright}
                hideRoot={true}
                invertTheme={true}
                data={jsonData}
              />
            </Paper>
            <Box className="flex-row-end">
              <Button
                endIcon={<Send />}
                variant="outlined"
                onClick={() => setShowSendModal(true)}
                disabled={loanData.error}
              >
                Send
              </Button>
            </Box>
          </Paper>
          <ConfirmationModal
            open={showSendModal}
            content={
              <Typography variant="body1">
                This will send the bank loan request to CRB
              </Typography>
            }
            onClose={() => setShowSendModal(false)}
            cancelText="Cancel"
            onConfirm={handleSend}
            title="Send the loan request to CRB"
          />
          <ConfirmationModal
            open={showRedirectModal}
            content={
              <Typography variant="body1">
                The child loan was successfully created!
              </Typography>
            }
            onClose={() => setShowSendModal(false)}
            cancelText="Cancel"
            onConfirm={() => {
              setShowRedirectModal(false);
              setLoading(true);
              navigate(`/loans/application/${appId}`);
            }}
            title="Child loan created"
          />
          <Snackbar
            open={snackOpen}
            message={snackMessage}
            autoHideDuration={3000}
            onClose={() => setSnackOpen(false)}
          />
        </>
      )}
    </>
  );
};
