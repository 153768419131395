import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

export const FormulaInputs = ({
  formula,
  appId,
  getLoanAnalysis,
  setLoading,
  setIsFormulaEditing,
}) => {
  // Initialize the form fields state with the default values
  const [formulaInputs, setFormulaInputs] = useState(formula.inputs);
  const [inputErrors, setInputErrors] = useState(
    formula.inputs.map(() => null),
  );

  const handleChange = (index, event, dataType) => {
    const newFormulaInputs = JSON.parse(JSON.stringify(formulaInputs));
    const newErrors = [...inputErrors];

    if (event.target.value.trim() === '') {
      newErrors[index] = 'Please enter a value';
    } else {
      newErrors[index] = null;
    }

    // keep numbers clean
    if (dataType === 'number') {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
    }

    if (formulaInputs[index].inputOverride) {
      newFormulaInputs[index].inputOverride.value = parseFloat(
        event.target.value,
      );
    } else {
      const newOverride = {
        name: formulaInputs[index].name,
        system_name: formulaInputs[index].system_name,
        value: parseFloat(event.target.value),
        bool_value: null,
      };

      newFormulaInputs[index].inputOverride = newOverride;
    }

    setFormulaInputs(newFormulaInputs);
    setInputErrors(newErrors);
  };

  const getFormulaInputsOrOverrides = () => {
    const inputs = formulaInputs.map((input) => {
      if (input.inputOverride) {
        input.inputOverride.display_name = input.display_name;
        return input.inputOverride;
      } else {
        return input;
      }
    });

    return inputs;
  };

  const isReadOnly = (formulaInputName) => {
    const input = formulaInputs.find(
      (input) => input.name === formulaInputName,
    );
    return input.canOverride === false;
  };

  const handleCancel = () => {
    setFormulaInputs(formula.inputs);
    setIsFormulaEditing(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const anyErrors = inputErrors.some((error) => error !== null);
    if (anyErrors) {
      alert('Please fix the errors before submitting');
      return;
    }

    setLoading(true);

    const formulaInputOverrides = formulaInputs
      .filter((input) => input.inputOverride !== null)
      .map((input) => input.inputOverride);

    const response = await axios.post(`/api/v1/uw/formula-overrides/${appId}`, {
      formulaInputOverrides,
    });

    await getLoanAnalysis();
    setIsFormulaEditing(false);
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        style={
          {
            backgroundColor: '#DCE1ED',
            borderRadius: '5px',
            gap: '20px',
            display: 'flex',
            flexDirection: 'column',
          } as React.CSSProperties
        }
      >
        {getFormulaInputsOrOverrides().map((formulaInputOrOverride, index) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography style={{ maxWidth: '150px' }} variant="body2">
              {formulaInputOrOverride.display_name}
            </Typography>
            <div>
              <input
                style={{
                  width: '100px',
                  height: '26px',
                  border: '1px solid #8C8D98',
                  fontFamily: 'Helvetica',
                  textAlign:
                    typeof formulaInputOrOverride.value === 'number'
                      ? 'right'
                      : 'left',
                  color: isReadOnly(formulaInputOrOverride.name)
                    ? '#999'
                    : 'black',
                }}
                key={index}
                readOnly={isReadOnly(formulaInputOrOverride.name)}
                disabled={isReadOnly(formulaInputOrOverride.name)}
                type="text"
                defaultValue={formulaInputOrOverride.value}
                onChange={(event) =>
                  handleChange(
                    index,
                    event,
                    typeof formulaInputOrOverride.value,
                  )
                }
              />
              {inputErrors[index] && (
                <div
                  style={{
                    color: 'red',
                    width: '90px',
                    fontFamily: 'Helvetica',
                    fontSize: 12,
                  }}
                >
                  {inputErrors[index]}
                </div>
              )}
            </div>
          </Box>
        ))}
        <Box
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}
        >
          <Button variant="lightOutlined" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button type="submit" variant="dark">
            Save
          </Button>
        </Box>
      </Box>
    </form>
  );
};
