import { Box, Typography } from '@mui/material';
import React from 'react';

export const ScoringLegend = () => {
  return (
    <Box width="400px">
      <Typography variant="body1">
        <table width="100%">
          <tr>
            <th style={{ textAlign: 'left' }}>Score</th>
            <th style={{ textAlign: 'left' }}>Rating</th>
          </tr>
          <tr>
            <td>115-150</td>
            <td>Superior</td>
          </tr>
          <tr>
            <td>95-115</td>
            <td>Excellent</td>
          </tr>
          <tr>
            <td>75-95</td>
            <td>Good</td>
          </tr>
          <tr>
            <td>55-75</td>
            <td>Fair</td>
          </tr>
          <tr>
            <td>0-55</td>
            <td>Unsatisfactory</td>
          </tr>
        </table>
      </Typography>
    </Box>
  );
};
