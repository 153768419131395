import { CloudQueueOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const SFLink = ({ entityId }) => {
  const url = window.location.href;
  const SF_URL =
    url.includes('localhost') || url.includes('staging')
      ? 'https://sunstonecredit--qabox2.sandbox.lightning.force.com/'
      : 'https://sunstonecredit.lightning.force.com/';
  return (
    <Tooltip title="Open in Salesforce">
      <IconButton>
        <Link to={`${SF_URL}/${entityId}`} target="_blank">
          <CloudQueueOutlined />
        </Link>
      </IconButton>
    </Tooltip>
  );
};
