import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const Tab = ({ dest, active, name }) => {
  return (
    <Link to={dest} className={`navTab${active ? ' active' : ''}`}>
      <Typography variant="body2">{name}</Typography>
    </Link>
  );
};
