import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

export const AddCheckSetModal = ({
  open,
  onClose,
  onSubmit,
  checklistTemplates,
}) => {
  const [items, setItems] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [saving, setSaving] = useState(false);

  const [error, setError] = useState({ message: '' });

  const handleClose = () => {
    onClose();
    setError({ message: '' });
    setItems([]);
  };

  const handleInputChange = (value) => {
    const template = checklistTemplates[value];
    if (template) {
      setItems(template.items);
      setCanSave(true);
    } else {
      setItems([]);
      setCanSave(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      sx={{ p: 2 }}
    >
      <DialogTitle>
        Add Checklist Items
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setSaving(true);
          const result = await onSubmit(items);
          if (result.error) {
            setError(result.error);
          } else {
            handleClose();
          }
          setSaving(false);
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={Object.keys(checklistTemplates)}
                renderInput={(params) => (
                  <TextField {...params} label="Checklist Template" />
                )}
                onChange={(event, value) => {
                  handleInputChange(value);
                }}
                onInputChange={(event, value) => {
                  handleInputChange(value);
                }}
              />
            </Grid>
            {items.length > 0 && (
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Rule</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Required</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items.map((item: any) => (
                        <TableRow key={item.name}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.checkRule}</TableCell>
                          <TableCell>{item.checkType}</TableCell>
                          <TableCell>
                            {item.isRequired ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
          {error && (
            <Typography color="error">
              {error?.message ?? 'Something went wrong'}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={items.length === 0 || saving}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
