import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { CreditMemoDownload } from '../../components/Underwriting/CreditMemoDownload';
import { useOutletContext } from 'react-router-dom';
import { CombinedApp } from '../../layouts/LoanApplicationLayout';

export const UWTool = () => {
  const app = useOutletContext() as CombinedApp;

  return (
    <Box className="content">
      <Paper
        variant="outlined"
        className="content flex-column flex-gap-2"
        sx={{ minHeight: '60vh' }}
      >
        <Typography variant="h5">Credit Memo Generation</Typography>
        <Typography variant="h6">
          Upload the UW tool and generate the credit memo
        </Typography>

        <Box>
          <CreditMemoDownload />
        </Box>
      </Paper>
    </Box>
  );
};
