import { useState } from 'react';
import './uwStyle.css';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { xlAcceptExt } from '../../assets/const';
import React from 'react';
import { Loading } from '../Loading';

export const UnderwritingTool = () => {
  const okButton = <Button onClick={() => setShowDialog(false)}>Ok</Button>;
  const [dialogContent, setDialogContent] = useState(<></>);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Error');
  const [dialogActions, setDialogActions] = useState(okButton);

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('', file, file.name);
    axios
      .post('/api/v1/uw/upload/tool', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((result) => {
        setDialogTitle('Succeeded');
        setDialogContent(
          <DialogContentText>
            {result.data?.message ?? 'Record created successfully'}
          </DialogContentText>,
        );
        setDialogActions(okButton);
        setShowDialog(true);
      })
      .catch((error) => {
        setDialogTitle('Failed to upload or parse the file');
        setDialogContent(
          <Typography variant="body1">
            {error.response.data.message ?? 'Failed to parse the file'}
          </Typography>,
        );
        setDialogActions(okButton);
        setShowDialog(true);
      });
  };

  const handleUpload = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = xlAcceptExt;

    input.click();
    input.onchange = (_) => {
      setDialogTitle('🚨 Head up! 🚨');
      setDialogContent(
        <Typography variant="body1">
          If there is existing loan data, it will be overwritten. Please ensure
          your file contains the loan's most accurate underwriting information
          for reporting and for sending to bank.
        </Typography>,
      );
      setDialogActions(
        <>
          <Button onClick={() => setShowDialog(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDialogActions(<></>);
              setDialogTitle('Uploading');
              setDialogContent(<Loading />);
              if (input.files) uploadFile(input.files[0]);
            }}
            variant="contained"
            color="error"
          >
            Proceed
          </Button>
        </>,
      );
      setShowDialog(true);
    };
  };
  return (
    <>
      <Box className={'uwTool'}>
        <Typography variant="h6">Underwriting tool uploader</Typography>
        <Button onClick={handleUpload} variant="contained">
          Upload
        </Button>
      </Box>
      {showDialog && (
        <Dialog open={showDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>{dialogContent}</DialogContent>
          <DialogActions>{dialogActions}</DialogActions>
        </Dialog>
      )}
    </>
  );
};
