import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import sunRotateImg from '../../../assets/img/sun-rotate.png';
import { Loading } from '../../../components/Loading';

export const AnalyzeLoan = (props) => {
  const analyzeLoan = props.analyzeLoan;
  const loading = props.loading;
  const errorMessage = props.error;
  const app = props.app;

  const getTitle = () => {
    if (
      app.Is_Personal_Guarantor__c &&
      app.Is_Personal_Guarantor__c === 'Yes'
    ) {
      return 'Ready to Analyze (PG)';
    }
    return 'Ready to Analyze';
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          style={{
            minWidth: '50%',
            minHeight: '200px',
            padding: '20px',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-evenly"
            minHeight="400px"
          >
            <img src={sunRotateImg} alt="sun rotate" />
            <Typography variant="h4">{getTitle()}</Typography>
            <Box textAlign="center" width="70%">
              <Typography variant="body1">
                Good news! This loan application has all the data needed to
                start the underwriting process. In order to analyze, we'll
                gather all reports to need for the application
              </Typography>
            </Box>
            {loading && <Loading />}
            {!loading && (
              <Button
                // style={startAnalysisButtonStyle}
                variant="dark"
                onClick={analyzeLoan}
              >
                Let's Go!
              </Button>
            )}

            {errorMessage && (
              <Typography variant="body1" color="error">
                An error occurred: {errorMessage}
              </Typography>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
