import { UwToolCreditMemoFields } from '@backend/types/Underwriting';
import { FileDownload, FileUpload } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { xlAcceptExt } from '../../assets/const';
import { CombinedApp } from '../../layouts/LoanApplicationLayout';
import { Loading } from '../Loading';

export const CreditMemoDownload = () => {
  const okButton = (
    <Button
      onClick={() => {
        setShowDialog(false);
        setDisabled(false);
      }}
      variant="dark"
    >
      Ok
    </Button>
  );
  const app = useOutletContext() as CombinedApp;
  const [dialogContent, setDialogContent] = useState(<></>);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Error');
  const [dialogActions, setDialogActions] = useState(okButton);
  const [canDownloadMemo, setCanDownloadMemo] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [uwToolData, setUwToolData] = useState({} as UwToolCreditMemoFields);

  const handleUpload = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = xlAcceptExt;

    input.click();
    input.onchange = (_) => {
      if (input.files) uploadFile(input.files[0]);
    };
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    setDisabled(true);
    formData.append('', file, file.name);
    axios
      .post(`/api/v1/uw/credit-memo/validate/${app.Name}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((result) => {
        setDialogTitle(
          `Succeeded${result.data.softMismatch.length > 0 ? ' with warnings' : ''}`,
        );
        setCanDownloadMemo(true);
        setDialogContent(
          <DialogContent>
            {result.data?.softMismatch?.length === 0 ? (
              <Typography variant="body1">
                {result.data?.message ?? 'Validation succeeded'}
              </Typography>
            ) : (
              <>
                <Typography variant="body1">
                  Warning: The following values did not match with the records
                  in Salesforce
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>UID</TableCell>
                      <TableCell>Value in UW tool</TableCell>
                      <TableCell>Value in SF</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.data?.softMismatch.map((m) => (
                      <TableRow key={m.field}>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          {m.field}
                        </TableCell>
                        <TableCell sx={{ bgcolor: 'lightyellow' }}>
                          {m.uwToolValue}
                        </TableCell>
                        <TableCell sx={{ bgcolor: 'lightgreen' }}>
                          {m.sfValue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </DialogContent>,
        );
        setDialogActions(okButton);
        setShowDialog(true);
        setUwToolData(result.data.uwToolData);
      })
      .catch((error) => {
        setDialogTitle('Failed to upload or parse the file');
        setDialogContent(
          <Typography variant="body1">
            {error.response.data.message ?? 'Failed to parse the file'}
            {error.response.data.mismatches && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>UID</TableCell>
                    <TableCell>Value in UW tool</TableCell>
                    <TableCell>Value in SF</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {error.response.data.mismatches.map((m) => (
                    <TableRow key={m.field}>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        {m.field}
                      </TableCell>
                      <TableCell sx={{ bgcolor: 'lightcoral' }}>
                        {m.uwToolValue}
                      </TableCell>
                      <TableCell sx={{ bgcolor: 'lightgreen' }}>
                        {m.sfValue}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Typography>,
        );
        setDialogActions(okButton);
        setShowDialog(true);
      });
  };

  const handleDownload = async () => {
    setDisabled(true);
    const response = await axios({
      url: `/api/v1/uw/credit-memo/${app.Id}`,
      method: 'POST',
      data: uwToolData,
      responseType: 'blob',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = `${app.Name}_Credit Memo.docx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
    setDisabled(false);
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: '5px',
          border: '1px solid lightgrey',
          p: '1em',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          gap: '1em',
          m: 'auto',
        }}
      >
        {canDownloadMemo ? (
          <>
            {disabled && <Loading overlay={true} />}
            <Typography variant="h6">Download Credit Memo</Typography>
            <Button
              variant={'lightOutlined'}
              onClick={handleDownload}
              startIcon={<FileDownload />}
              disabled={disabled}
            >
              Download
            </Button>
          </>
        ) : (
          <>
            {disabled && <Loading overlay={true} />}
            <Typography variant="h6">Generate Credit Memo</Typography>
            <Button
              variant={'lightOutlined'}
              onClick={handleUpload}
              startIcon={<FileUpload />}
              disabled={disabled}
            >
              Upload
            </Button>
          </>
        )}
      </Box>
      {showDialog && (
        <Dialog open={showDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>{dialogContent}</DialogContent>
          <DialogActions>{dialogActions}</DialogActions>
        </Dialog>
      )}
    </>
  );
};
