import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { ChecklistItemRule, ChecklistItemType } from './constants';
import React from 'react';

export const SettingsModal = ({
  open,
  onClose,
  onSubmit,
  onDelete,
  settings: { milestoneId, ...initialSettings },
}) => {
  const [settings, setSettings] = useState(initialSettings);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [error, setError] = useState(null);

  const handleChange = useCallback((e) => {
    setSettings((settings) => ({
      ...settings,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleCheckboxChange = useCallback((e) => {
    setSettings((settings) => ({
      ...settings,
      [e.target.name]: e.target.checked,
    }));
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" sx={{ p: 2 }}>
      <DialogTitle>
        Edit Item
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(settings);
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                size="small"
                fullWidth
                value={settings.title}
                name="title"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Check Rule"
                variant="outlined"
                size="small"
                select
                fullWidth
                value={settings.rule}
                name="rule"
                onChange={handleChange}
              >
                <MenuItem value={ChecklistItemRule.EXIST}>
                  Exist (Check if the requirement is present and fill the
                  present value)
                </MenuItem>
                <MenuItem value={ChecklistItemRule.EQUAL}>
                  Equal (Check if the requirement is equal to the value in a
                  previous document)
                </MenuItem>
                <MenuItem value={ChecklistItemRule.YES_NO}>
                  Yes/No (Check if the requirement is satisfied)
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Check Type"
                variant="outlined"
                size="small"
                select
                fullWidth
                value={settings.type}
                name="type"
                onChange={handleChange}
              >
                <MenuItem value={ChecklistItemType.DOCUMENT_REQUIREMENT}>
                  Document Requirement
                </MenuItem>
                <MenuItem value={ChecklistItemType.CALL}>Call</MenuItem>
                <MenuItem value={ChecklistItemType.DOCUSIGN}>Docusign</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                label={'Required'}
                control={
                  <Checkbox
                    checked={settings.isRequired}
                    name="isRequired"
                    onChange={handleCheckboxChange}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmOpen(true)}
            variant="outlined"
            color="error"
            sx={{
              mr: 'auto',
            }}
          >
            Delete Item
          </Button>
          {deleteConfirmOpen && (
            <Dialog
              open={deleteConfirmOpen}
              onClose={() => setDeleteConfirmOpen(false)}
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle>Delete Item {initialSettings.title}</DialogTitle>
              <DialogContent>
                <Typography sx={{ mb: 2 }}>
                  Are you sure you want to delete "{initialSettings.title}" item
                  for "{initialSettings.sunstoneDocumentName}"?
                </Typography>
                {error && (
                  <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setDeleteConfirmOpen(false)}
                  variant="outlined"
                  sx={{
                    mr: 'auto',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    try {
                      await onDelete();
                      setDeleteConfirmOpen(false);
                    } catch (error: any) {
                      setError(
                        error.message ??
                          `Something went wrong: ${JSON.stringify(error)}`,
                      );
                    }
                  }}
                  variant="contained"
                  color="error"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
