import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { isAdmin } from '../helpers/auth';
import React from 'react';

export const FileDeleteDialog = ({ open, fileData, onClose, callback }) => {
  const [hardDelete, setHardDelete] = useState(false);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `/api/v1/projects/file/${fileData.docId}/${fileData.id}?hard=${hardDelete}`,
      );
      onClose();
      callback(
        hardDelete
          ? '✅ File permanently deleted successfully'
          : '✅ File deleted successfully',
      );
      setHardDelete(false);
    } catch (err) {
      onClose();
      callback('❌ Failed to delete the file');
      setHardDelete(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" sx={{ p: 2 }}>
      <DialogTitle>
        Deleting file
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="flex-row-space-between">
          <Typography variant="body1">Delete file {fileData.name}?</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {isAdmin() && (
          <Box className="flex-row-end" sx={{ color: 'crimson' }}>
            <Typography variant="body2">Permanent delete</Typography>
            <Checkbox
              onChange={(event) => {
                setHardDelete(event.target.checked);
              }}
              sx={{
                color: 'crimson',
                '&.Mui-checked': {
                  color: 'crimson',
                },
              }}
            />
          </Box>
        )}
        <Button onClick={handleDelete} variant="text" color="error">
          Yes
        </Button>
        <Button type="submit" variant="text" color="info" onClick={onClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
