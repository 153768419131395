import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Announcement } from '../../../types/Announcement';
import { CreateAnnouncement } from './CreateAnnouncement';
import { EditAnnouncement } from './EditAnnouncement';
import { ViewAnnouncements } from './ViewAnnouncements';

export const Announcements = () => {
  const [value, setValue] = useState(0);
  const [announcement, setAnnouncement] = useState<Announcement | null>(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value !== 2) {
      setAnnouncement(null);
    }
  }, [value]);

  function TabPanel({ children, value, index }) {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="All" />
          <Tab label="Create" />
          <Tab label="Edit" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ViewAnnouncements
            setAnnouncement={setAnnouncement}
            setValue={setValue}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CreateAnnouncement setValue={setValue} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {announcement && (
            <EditAnnouncement announcement={announcement} setValue={setValue} />
          )}
        </TabPanel>
      </Box>
    </>
  );
};
