import { Error } from '@mui/icons-material';
import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

export const BlockingIssues = (props) => {
  const blockingIssues = props.blockingIssues;
  const lineItemStyles = {
    border: '1px solid #DCE1ED',
    width: '100%',
    borderRadius: '5px',
    p: '0.8em',
    mt: '1em',
  };

  const renderPanel = (title, lineItems) => {
    return (
      <Box>
        <Box className="flex-row-start">
          <Box className="flex-row-end flex-gap-0_5">
            <Error color="error" />
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
        </Box>
        {lineItems}
      </Box>
    );
  };

  const renderBlockingIssues = () => {
    const lineItems = blockingIssues.map((point) => (
      <Typography key={point.label} variant="body1" sx={lineItemStyles}>
        {point.label}
      </Typography>
    ));
    return renderPanel('Blocking Issues/Missing Data For Analysis', lineItems);
  };

  return (
    <>
      <Typography variant="h5">Action Needed</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper
            elevation={3}
            style={{
              padding: '20px',
            }}
          >
            {renderBlockingIssues()}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
