import { ValueHolder } from '../pages/loanOps/pre-screen/Formatters';

export const getScoreString = (item, scores) => {
  if (item.type in scores) {
    const score = scores[item.type].points;
    const max = scores[item.type].max;
    return `${score.toFixed(1)}/${max.toFixed(1)}`;
  }

  return '0/0';
};

export const getValueHolder = (item) => {
  const vh: ValueHolder = {} as ValueHolder;

  if (!!item.valueOverride) {
    vh.value = item.valueOverride.value;
    vh.boolValue = item.valueOverride.bool_value;
    vh.type = item.type;
  } else if (item.formula && item.formula.overriddenFormulaOutput) {
    vh.value = item.formula.overriddenFormulaOutput;
    vh.type = item.type;
  } else {
    vh.value = item.value;
    vh.type = item.type;
    vh.stringValue = item.stringValue;
    vh.boolValue = item.boolValue;
  }

  return vh;
};

export const getSectionScoreString = (
  loanAnalysis: [{ [key: string]: any }],
  scores,
  grouping: string,
) => {
  const items = loanAnalysis.filter((item) => item.grouping === grouping);
  let scoreTotal = 0;
  let maxTotal = 0;

  for (const item of items) {
    if (item.type in scores) {
      scoreTotal += scores[item.type].points;
      maxTotal += scores[item.type].max;
    }
  }

  return `${scoreTotal.toFixed(1)}/${maxTotal.toFixed(1)}`;
};

export const hasManualOverride = (item) => {
  if (!!item.valueOverride) {
    return true;
  }

  if (item.formula && item.formula.inputs) {
    for (const input of item.formula.inputs) {
      if (!!input.inputOverride) {
        return true;
      }
    }
  }

  return false;
};

export const getItems = (loanAnalysis, grouping) =>
  loanAnalysis.filter((item) => item.grouping === grouping);
